






import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import { Context } from '../../../../Context_holder';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function WebsiteStory_add() {

    const{token,notify }=useContext(Context)
    



     

    const submit_handler = (e)=>{

        e.preventDefault();
        
        
    
    
   
     
     
     const views = e.target.views.value 
     const video_url=e.target.url.value 
     const thumbnail=e.target.thumbnail.files[0] 


const formData=new FormData()

formData.append("views",views)
formData.append("video_url",video_url)
formData.append("thumbnail",thumbnail)


    
    
    
      axios.post(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_WEBSITE_STORIES_URL+"add",formData,{
        headers: {
          Authorization: token}
      })
      .then((success) => {
        
        notify(success.data.msg,success.data.status)
    
        if (success.data.status === 1) {
          e.target.reset();
        }
      })
    
      .catch((error) => {
        console.error('Error:', error);
      }); 
    
    }

    return (
      <div className='min-h-screen py-10 bg-gray-300'>
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
        <div className="text-center text-4xl font-bold mb-3"> Website Story Add</div>
        <form onSubmit={submit_handler} enctype="multipart/form-data">
        
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
           


            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="heading">
              URL
              </label>
              <input
                id="url"
                name="url"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Youtube URL"
              />
            </div>


            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="thumbnail">
              Thumbnail
              </label>
              <input
                id="thumbnail"
                name="thumbnail"
                type="file"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                
              />
            </div>
           
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="views">
              Views
              </label>
              <input
                id="views"
                name="views"
                type="text"

                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                
              />
            </div>
           
          </div>


         

         

          

            <div className="flex justify-end mb-4 mt-2">
            <button
              type="submit"
              className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
          
        </form>
      </div>
    

      
     
    </div>
    

        )
}



