import React from "react";

export function FeesTable({ collegeDetails }) {
  return (
    <div className="w-full  flex flex-col items-center  rounded-lg">
      <p className=" text-start font-bold text-[20px]">Course-Specific Fee Updates for 2024</p>
     
        <div className="container mx-auto  ">
          <div className="overflow-x-auto">

            <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
              <thead className="bg-gradient-to-r from-teal-400 to-green-500 text-white">
                <tr>
                  <th className="py-3 px-6 text-left font-medium">
                    Course Name
                  </th>
                  <th className="py-3 px-6 text-left font-medium">Fees</th>
                  <th className="py-3 px-6 text-left font-medium">Duration</th>
                </tr>
              </thead>
              <tbody>
                {collegeDetails?.courses?.map((course, index) => (
                  <tr
                    key={index}
                    className={`border-b border-gray-200 hover:bg-gray-50 ${
                      index % 2 === 0 ? "bg-gray-50" : ""
                    }`}
                  >
                    <td className="py-4 px-6 text-gray-800 font-medium capitalize">
                      {course.courseName}
                    </td>
                    <td className="py-4 px-6 text-gray-700 font-semibold">
                      ₹ {course.fees}
                    </td>
                    <td className="py-4 px-6 text-gray-600">
                      {course.duration} Years
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            
          </div>
        </div>

    </div>
  );
}
