


import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { Context } from '../../../../Context_holder';
import { Link } from 'react-router-dom';

export default function WebsiteStory_view() {
  const { websitestory_fetch, websitestory,token,notify } = useContext(Context);

  useEffect(() => {
    websitestory_fetch();
  }, []);

  const delete_handler = (id,thumbnail) => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_WEBSITE_STORIES_URL}delete/${id}/${thumbnail}`,{
        headers: {
          Authorization: token}
      })
      .then((success) => {

        notify(success.data.msg,success.data.status)

        if (success.data.status === 1) {

          websitestory_fetch();
          
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  if (websitestory?.length !== 0) {
    return (
      <div className="min-h-screen bg-gray-100 py-6 px-6 flex flex-col justify-center sm:py-3">
        <div className="relative py-3 ">
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-10">
            <div className="max-w-full mx-auto">
              <h1 className="text-3xl font-bold text-center mb-6">Website Stories List</h1>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                    
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Thumbnail</th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Views</th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Link</th>
                      
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {websitestory?.map((data, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                        <img src={process.env.REACT_APP_API_IMAGE_URL+"story_thumbnail/"+data?.thumbnail} alt=" Logo" className="w-16 h-16 rounded-full" />

                        </td>


                        <td className="px-6 py-4 whitespace-nowrap">{data?.views } </td>

                            <td className="px-6 py-4 whitespace-nowrap">

                       <Link to={data?.video_url} className='py-1 px-3 border rounded-md shadow-md'>Click</Link>

                     
                        </td>


                        <td className="px-6 py-8 items-center whitespace-nowrap flex gap-2 ">
                          <button className="px-3 py-1 font-semibold rounded border shadow-md hover:text-white hover:bg-red-500" onClick={() => delete_handler(data._id,data?.thumbnail)}>
                            Delete
                          </button>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button className="px-3 py-1 font-semibold rounded border hover:shadow-lg">View</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Please add website stories</div>;
  }
}
