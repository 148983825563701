import React, { useContext, useEffect, useState } from "react";

import { AiFillHome, AiFillProfile } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { FaUniversity } from "react-icons/fa";
import apple from "./mobicon/app-store-logo.png";
import play from "./mobicon/play.webp";
import logo from "./mobicon/aapd-logo.png"
// Assuming you're using heroicons
import { Link, useNavigate } from "react-router-dom";
import backbutton from "./mobicon/back-button.png";
import colors from "../../../utils/colour";
import HoverUniversities from "../hoverUniveristies/page";
import { FaBars } from "react-icons/fa";
import signupIcon from "./mobicon/add-friend.png";
import { Context } from "../../../../../Context_holder";
import { FaUserCircle } from "react-icons/fa";

const Navbar = ({ setsearchbar , backgroundColor }) => {


  

  const {
    stream_fetch,
    stream,
    setuserLogin_popup,
    user,
    userlogout_handler,
    mobilnav,
    setMobilnav,
    top10course_fetch,
    college_fetch_by_ratings,
    top10College,
    topCourses,
    stream_with_colleges,
    stream_with_colleges_fetch,
    setcollege_state,
  } = useContext(Context);

  const navigater = useNavigate();

  const [open, setopen] = useState(false);

  const [isSliderOpen, setSliderOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [slider_content, setslider_content] = useState([]);

  const best_college_handler = (state) => {
    setcollege_state({ value: state });
    setSliderOpen(false);

    navigater("/allUniversity");
  };

  useEffect(() => {
    top10course_fetch();
    college_fetch_by_ratings();
    stream_with_colleges_fetch();
  }, []);

  const logout_handler = () => {
    userlogout_handler();
    setMobilnav(false);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const toggleSlider = (colleges) => {
    setslider_content(colleges);

    setSliderOpen(!isSliderOpen);
    setMobilnav(!mobilnav); // Close mobile menu when opening slider
  };

  return (
    <>
      {/* //desktop navbar */}

      <div
      style={{
        color: backgroundColor === '#18092f' ? '#ffffff' : '#000000', // Change text color based on background color
        backgroundColor: backgroundColor || '#f8f9fa' // Default background color if none is provided
      }}
   
        className="w-full  shadow-lg py-[5px] bg-[#F1F3F4]   z-[20]  "
      >
        <div
          className="mx-auto lg:w-[90%] px-3 lg:px-0  flex gap-9 xl:gap-2 items-center 
        py-2 md:py-4 xl:justify-between "
        >
            
          <div className="flex items-center gap-12  w-[50%] ">
            <Link to="/" className="flex items-center">
      
              <h1
                className="   hover:scale-110 transition-transform 
              duration-300 font-bold text-[15px] sm:text-[18px] "
              >
                AAOPADHE
              </h1>
             
            </Link>

            <div
              className=" cursor-pointer border-2 justify-between gap-4  lg:flex  hidden  rounded-md 
              items-center py-2 px-4 text-gray-500 transition-colors hover:border-gray-400 hover:text-gray-600 md:w-[90%]"
              onClick={() => setsearchbar(true)}
            >
              <AiOutlineSearch className="text-gray-500  text-xl" />

              <div className=" text-sm sm:text-base  pe-5 ">Search</div>
            </div>
          </div>

          <ul
            className="hidden lg:flex md:block lg:w-[50%] text-[14px] xl:text-[15px] 
            justify-end gap-2 sm:gap-3 xl:gap-6 relative"
          >
            <Link
              className={`shadow-lg px-3 py-2 group  bg-[${colors.textBlack}] 
              hover:hover:bg-[${colors.theme}] font-bold 
              hover:text-[#fdc800] duration-300 cursor-pointer  rounded-xl  md:block hidden`}
              to="/"
            >
              Home
            </Link>
            <Link
              className={`shadow-lg px-3 py-2 group bg-[${colors.textBlack}] 
              hover:hover:bg-[${colors.theme}] font-bold 
              hover:text-[#fdc800] duration-300 cursor-pointer  rounded-xl md:block hidden`}
              to="/about"
            >
              About
            </Link>
            <li
              className={` shadow-lg px-3  py-2 group bg-[${colors.textBlack}]
               hover:hover:bg-[${colors.theme}] font-bold 
              hover:text-[#fdc800] duration-300 cursor-pointer  rounded-xl  md:block hidden`}
            >
              Top Universities
              {/* Hover Efffect Create */}
              <div
                className={`w-full z-50 absolute  left-0 top-[40px] shadow-md bg-[${colors.theme}] 
                  hidden group-hover:block transition-all duration-300 ease-in-out `}
              >
                <HoverUniversities
                  top10College={top10College}
                  top10Courses={topCourses}
                />
              </div>
              {/* EnD Hover Effect  */}
            </li>
            <Link
              className="shadow-lg cursor-pointer max-md:hidden px-3 py-2 group 
               hover:hover:bg-[#002147] font-bold 
              hover:text-[#fdc800] duration-300 rounded-xl md:block hidden"
              to="/contact"
            >
              Contact
            </Link>

            <Link
              to={"/student_profile"}
              className={`flex items-center ${
                user ? "block" : "hidden"
              } cursor-pointer`}
            >
              <FaUserCircle className="text-3xl text-blue-500" />

              <div className="ml-3">
                <h2 className=" font-bold capitalize bg-[blue] text-white rounded-xl py-1 px-1">
                  {user?.name}
                </h2>
              </div>
            </Link>

            <div className={` ${user ? "hidden" : "block"}`}>
              <Link
                className={`shadow-lg px-3 sm:block max-md:hidden py-2 cursor-pointer
                   bg-white hover:hover:bg-[#002147]  text-black
                font-bold  duration-300 hover:text-[#fdc800] rounded-xl`}
                onClick={() => setuserLogin_popup(true)}
              >
                Sign in
              </Link>
            </div>
          </ul>
          <FaBars
            className="text-[20px] absolute right-4 lg:hidden block text-gradient-to-b from-transparent to-purple-900"
            onClick={() => setMobilnav(true)}
          />
        </div>
      </div>

      {/* **************************************************************************************** */}

      {/* Mobile Menu */}

      <div
        className={`overflow-y-auto  h-full fixed block lg:hidden px-2 pb-[12rem] 
          top-0 right-0 bg-white z-20     w-full duration-700 ${
            mobilnav === true ? "left-[0%]" : "left-[-100%]"
          }`}
      >
        <div className="flex justify-between py-2">
          <Link
            to={"/student_profile"}
            className={`flex items-center ${
              user ? "flex" : "hidden"
            } cursor-pointer `}
          >
            <FaUserCircle className="text-3xl text-blue-500" />

            <div className="ml-3 ">
              <h2 className=" font-bold capitalize text-gray-800">
                {user?.name}
              </h2>
            </div>
          </Link>
          <li
            onClick={() => setMobilnav(false)}
            className="text-black flex cursor-pointer gap-2"
          >
            <img src={backbutton} className="w-6 h-6 mt-1"></img>
          </li>
        </div>

        <div className="container mx-auto w-full rounded-2xl">
          <div className="items-center py-2 gap-3 text-gray-500 flex justify-between rounded-2xl">
            <div
              className="flex gap-2 cursor-pointer w-full rounded-2xl py-2"
              onClick={() => setsearchbar(true)}
            >
              <div
                className="   text-[16px] bg-transparent w-[100%] px-5 py-1 border-2 outline-none
               flex justify-between rounded-md"
              >
                {" "}
                Search for
                <AiOutlineSearch className="text-[25px] text-gray-500" />
              </div>
            </div>
          </div>

          {/* all listing   page */}



          <li className="flex gap-2">
                <div className="text-black cursor-pointer">
                  <div className="w-full gap-1 text-black md:hidden">

                    {stream_with_colleges?.slice(0,4).map((data, index) => (

                      <div

                        key={index}
                        className=" text-black"

                      >

                        <div
                          className="flex justify-between cursor-pointer"
                          onClick={()=>toggleSlider(data?.colleges)}
                        >


                          <img
                            className="w-5 h-5 rounded-full"
                            src={`${process.env.REACT_APP_API_BASE_URL}image/stream_image/${data.image}`}
                            alt={data?.stream_name}
                          />
                          <p className="capitalize w-full py-1 flex justify-between text-black text-start text-sm ml-2 cursor-pointer">
                            {data?.stream_name} College
                            
                            <svg
                              className="w-8 h-8 text-white"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >

                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 4v16m8-8H4"
                              />

                            </svg>

                          </p>
                        </div>
                      </div>
                    ))}
                    
                    {stream_with_colleges?.length > 4 && (
                      <button
                        onClick={togglePopup}
                        className="mt-2 text-blue-500"
                      >
                        More Colleges
                      </button>
                    )}
                  </div>
                </div>

                {isPopupOpen && (
                  <div className="absolute inset-0 flex items-center w-[100%] h-full justify-center z-30 bg-black bg-opacity-50 backdrop-blur-sm">
                    <div className="bg-white p-4 rounded shadow-lg w-[80%]">
                      <h2 className="text-lg font-bold">All Colleges</h2>
                      {stream_with_colleges?.map((data, index) => (
                        <div key={index} className="flex items-center py-2" onClick={()=> toggleSlider(data?.colleges)}>
                          <img
                            className="w-5 h-5 rounded-full"
                            src={`${process.env.REACT_APP_API_BASE_URL}image/stream_image/${data.image}`}
                            alt={data.stream_name}
                          />
                          <p className="capitalize text-black text-sm ml-2">
                            {data.stream_name} College
                          </p>
                        </div>
                      ))}
                      <button
                        onClick={togglePopup}
                        className="mt-4 text-red-500"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </li>



          <div>
            <ul className="flex py-3 flex-col	gap-2 px-2">
              <div className="flex gap-2">
                <div className="text-black  pointer">
                

                  <li className="px-3 py-1 bg-yellow-200 text-black">
                    <Link
                      onClick={() => setMobilnav(false)}
                      to={"/Live_applications"}
                    >
                      Live Applications
                    </Link>
                  </li>
                  <div className="bg-white p-4">
                    <h2 className="text-green-500 font-semibold text-lg">
                      Why Trust us
                    </h2>
                    <ul className="space-y-4 mt-4">
                      <li className="flex items-center">
                        <Link
                          to="/ourcommitment"
                          onClick={() => setMobilnav(false)}
                        >
                          Our Commitment
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/wediffrent"
                          onClick={() => setMobilnav(false)}
                        >
                          What makes our different?
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/inviteuniversity"
                          onClick={() => setMobilnav(false)}
                        >
                          Invite University
                        </Link>
                      </li>
                      <li className="flex items-center">
                        <Link
                          to="/videoCounselingSection"
                          onClick={() => setMobilnav(false)}
                        >
                          Counseling Book
                        </Link>
                      </li>
                      <li className="flex items-center">
                        <Link
                          to="/ourexpert"
                          onClick={() => setMobilnav(false)}
                        >
                          Our Experts
                        </Link>
                      </li>
                      <li>
                        <Link to="/ConnectWithStudentsAlumni">
                          Connect With Students
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/fakeuniversity"
                          onClick={() => setMobilnav(false)}
                          className="pointer"
                        >
                          Fake university
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/successStories"
                          onClick={() => setMobilnav(false)}
                          className="pointer"
                        >
                          Succes story
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/importantVideos"
                          onClick={() => setMobilnav(false)}
                          className="pointer"
                        >
                          Ask any question
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/blog"
                          onClick={() => setMobilnav(false)}
                          className="pointer"
                        >
                          Blogs & post
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/importantVideos"
                          onClick={() => setMobilnav(false)}
                        >
                          Important Videos
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about"
                          onClick={() => setMobilnav(false)}
                        >
                          About Us
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {/* contact us */}
                  <div className="p-6 bg-white shadow-md rounded-md">
                    <h2 className="text-lg font-semibold text-teal-600 mb-4">
                      Contact Us
                    </h2>

                    <div className="space-y-4">
                      <div className="flex items-center space-x-2">
                        <span
                          role="img"
                          aria-label="phone"
                          className="text-blue-600"
                        >
                          📞
                        </span>
                        <p>
                          New User:{" "}
                          <a href="tel:18004205757" className="text-gray-800">
                          +91 79 7652 7115
                          </a>
                        </p>
                      </div>

                      <div className="flex items-center space-x-2">
                        <span
                          role="img"
                          aria-label="user"
                          className="text-yellow-600"
                        >
                          👤
                        </span>
                        <p>
                          Help Desk :{" "}
                          <a href="tel:18003097979" className="text-gray-800">
                          +91 79 7652 7115
                          </a>
                        </p>
                      </div>

                      <div className="flex items-center space-x-2">
                        <span
                          role="img"
                          aria-label="arrow"
                          className="text-blue-600"
                        >
                          ➡️
                        </span>
                        <p>
                          email{" "}
                          <a href="tel:18003099018" className="text-gray-800">
                          ofcwrk@hotmail.co.uk
                          </a>
                        </p>
                      </div>

                      <div className="flex items-center space-x-2">
                        <span
                          role="img"
                          aria-label="clock"
                          className="text-gray-600"
                        >
                          🕑
                        </span>
                        <p className="text-gray-600">
                          Visit Us (10 AM to 7 PM)
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* app download section */}
                </div>
              </div>

              <li className={` ${user ? "hidden" : "flex"}  gap-2 `}>
                <img
                  src={signupIcon}
                  alt="image not found"
                  className=" w-[20px] h-[20px] mt-[9px]"
                ></img>
                <Link
                  className="text-black mt-2"
                  onClick={() => setuserLogin_popup(true)}
                >
                  Sign Up
                </Link>
              </li>

              <li
                className={`${
                  user ? "flex" : "hidden"
                } text-lg inline-block   mt-4`}
              >
                <button
                  className=" border px-3 py-1 shadow-lg rounded"
                  onClick={logout_handler}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>

          {/* End */}
        </div>

        <div className="flex flex-col items-start space-y-4 mt-4 ml-4">
          {/* Download App Text */}
          <p className="text-green-500 font-semibold text-sm">Download App</p>

          {/* App Store Buttons */}
          <div className="flex space-x-4  mt-4">
            {/* Google Play Button */}
            <a
              href="https://play.google.com/store"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={play} alt="Google Play" className="w-36" />
            </a>
            {/* App Store Button */}
            <a
              href="https://www.apple.com/app-store/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={apple} alt="App Store" className="w-32" />
            </a>
          </div>
        </div>

        <nav className=" bg-white fixed bottom-0 w-[98%] border-t flex justify-around py-2 shadow-lg">
          <Link
            to="/"
            onClick={() => setMobilnav(false)}
            className="text-center"
          >
            <AiFillHome className="h-6 w-6 mx-auto" />
            <span className="text-sm">Home</span>
          </Link>
          <Link
            onClick={() => setMobilnav(false)}
            to={"allUniversity"}
            className="text-center"
          >
            <FaUniversity className="h-6 w-6 mx-auto" />
            <span className="text-sm">Universities</span>
          </Link>
          <div
            className="text-center"
            onClick={() => {
              setsearchbar(true);
            }}
          >
            <AiOutlineSearch className="h-6 w-6 mx-auto" />
            <span className="text-sm" >Search</span>
          </div>
          <Link
            to={"/student_profile"}
            className="text-center"
            onClick={() => setMobilnav(false)}
          >
            <AiFillProfile className="h-6 w-6 mx-auto" />
            <span className="text-sm">Profile</span>
          </Link>
        </nav>

        <div></div>
        
      </div>
      {isSliderOpen && (
        <div
          className={`overflow-y-auto h-full absolute block lg:hidden px-2 pb-[12rem] 
            top-0 right-0 bg-white z-40 w-full duration-700 
            ${isSliderOpen ? "right-[0%]" : "right-[-100%]"}`}
        >
          <div
            className="fixed right-0 top-0 bg-white h-full w-[100%] shadow-lg overflow-y-auto 
          transition-transform duration-300 ease-in"
          >
            <div className="p-4">
              <h2 className="text-lg font-bold mb-4">
                Location wise best university
              </h2>

              {slider_content?.map((data) => (
                <div
                  className=" cursor-pointer font-semi-bold "
                  onClick={() => best_college_handler(data?.state)}
                >
                  {data?.state}
                </div>
              ))}
            </div>
            <div
              className="absolute top-4 right-4 cursor-pointer"
              onClick={() => {
                setSliderOpen(false);
                setMobilnav(true);
              }}
            >
              <img src={backbutton} className="w-8 h-8"></img>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
