import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../../Context_holder";
import CoursePopUp from "../coursePopup/courserPopup";
import { Link } from "react-router-dom";
// import AOS from 'aos';
// import 'aos/dist/aos.css';

const UniversityDiv = () => {
  const { stream_fetch, stream } = useContext(Context);
  const [openScreen, setOpenScreen] = useState(false);
  const [pop_upCourses, setpop_upCourses] = useState([]);
  
  const toggleSidebar = (boolean, courses) => {
    setpop_upCourses(courses);
    setOpenScreen(boolean);
  };

  const close_handler = () => {
    setOpenScreen(false);
  };

  useEffect(() => {
    stream_fetch();
    // AOS.init(); // Initialize AOS
  
  }, []);



  return (
    <div className="w-full flex justify-center px-1 md:px-4">
      <div className="rounded-xl md:w-[90%] w-full mt-4 flex flex-wrap gap-1 justify-evenly text-black">
        {stream?.map((data, index) => (


          <div
            key={index}
            // data-aos="fade-up" // Add AOS animation
            // data-aos-duration="600" // Duration of animation
            // data-aos-delay={index * 100} // Delay based on index
            onClick={() => toggleSidebar(true, data?.courses)}
            className="bg-white   rounded-xl flex flex-col justify-center items-center shadow-2xl 
            w-[22%] md:w-[20%] lg:w-[12%] overflow-hidden"
          >
               
            <Link className="md:p-4  flex flex-col justify-center items-center">
            <div className="w-10 h-10 md:w-20 md:h-20">
              <img
                className="w-full h-full  rounded-full"
                src={`${process.env.REACT_APP_API_BASE_URL}image/stream_image/${data.image}`}
                  loading="lazy"
                alt={data.stream_name}
              />
              </div>
              <p className="text-center md:mb-4 mb-1 text-[8px] md:text-[13px] md:text-base">
                {data?.courses?.reduce((acc, course) => {
                  if (!acc.some(item => item.college_id === course.college_id)) {
                    return [...acc, course];
                  }
                  return acc;
                }, []).length} College
              </p>
              <p className="md:bg-yellow-500 rounded-xl capitalize w-full md:px-4 px-6 md:py-4 py-0
              text-black text-center text-[10px] md:text-[13px]">
                {data.stream_name}
              </p>
            </Link>
          </div>
        ))}
      </div>

      {/* Course Popup */}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          backgroundColor: 'white',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          transform: openScreen ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'transform 0.3s ease-in-out',
          zIndex: 1000,
        }}
      >
        <CoursePopUp onClose={close_handler} courses={pop_upCourses} />
      </div>
    </div>
  );
};

export default UniversityDiv;

















// import React, { useContext, useEffect, useState } from "react";
// import { Context } from "../../../../../Context_holder";
// import CoursePopUp from "../coursePopup/courserPopup";
// import { Link } from "react-router-dom";

// const UniversityDiv = () => {
//   const { stream_fetch, stream } = useContext(Context);
//   const [openScreen, setOpenScreen] = useState(false);
//   const [pop_upCourses, setpop_upCourses] = useState([]);
  
//   const toggleSidebar = (boolean, courses) => {
//     setpop_upCourses(courses);
//     setOpenScreen(boolean);
//   };

//   const close_handler = () => {
//     setOpenScreen(false);
//   };

//   useEffect(() => {
//     stream_fetch();
//   }, []);

//   return (
//     <div className="w-full flex justify-center">
//       <div className="rounded-xl w-[90%] sm:w-full mt-4 flex flex-wrap gap-6 justify-evenly text-black">
//         {stream?.map((data, index) => (
//           <div
//             key={index}
//             onClick={() => toggleSidebar(true, data?.courses)}
//             className="bg-white rounded-xl flex flex-col justify-center items-center shadow-2xl w-[45%] md:w-[30%] lg:w-[15%] overflow-hidden"
//           >
//             <Link className="p-4 flex flex-col justify-center items-center shadow-xl w-full">
//               <img
//                 className="w-20 h-20 md:w-28 md:h-28"
//                 src={`${process.env.REACT_APP_API_BASE_URL}image/stream_image/${data.image}`}
//                 alt={data.stream_name}
//               />
//               <p className="text-center mb-4 text-sm md:text-base">
//                 {data?.courses?.reduce((acc, course) => {
//                   if (!acc.some(item => item.college_id === course.college_id)) {
//                     return [...acc, course];
//                   }
//                   return acc;
//                 }, []).length} College
//               </p>
//               <p className="bg-blue-500 rounded-xl capitalize w-full px-4 py-2 text-white text-center text-sm md:text-lg">
//                 {data.stream_name}
//               </p>
//             </Link>
//           </div>
//         ))}
//       </div>

//       {/* Course Popup */}
//       <div
//         style={{
//           position: 'fixed',
//           top: 0,
//           left: 0,
//           width: '100%',
//           backgroundColor: 'white',
//           boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//           transform: openScreen ? 'translateY(0)' : 'translateY(-100%)',
//           transition: 'transform 0.3s ease-in-out',
//           zIndex: 1000,
//         }}
//       >
//         <CoursePopUp onClose={close_handler} courses={pop_upCourses} />
//       </div>
//     </div>
//   );
// };

// export default UniversityDiv;
