import React, { useContext, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Context } from "../../../../../Context_holder";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PlacementBarChart = ({ id }) => {
  const { placement_score_fetch, placementscore } = useContext(Context);

  useEffect(() => {
    placement_score_fetch(id);
  }, [id]);

  const data = {
    labels: placementscore?.map(data => data.year),
    datasets: [
      {
        label: "Placements",
        data: placementscore?.map(data => data.placementScore),
        backgroundColor: "#4CAF50", // Tailwind green color
        color: "#375151",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#374151", // Tailwind gray-700 color
        },
      },
      title: {
        display: true,
        text: "Year-wise Placement Statistics",
        color: "#1F2", // Tailwind gray-800 color
        font: {
          size: 20,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "white", // Tailwind gray-700 color
        },
        grid: {
          color: "#E5E7EB", // Tailwind gray-300 color
        },
      },
      x: {
        ticks: {
          color: "white", // Tailwind gray-700 color
        },
        grid: {
          color: "#E5E7EB", // Tailwind gray-300 color
        },
      },
    },
  };

  return (
    <div className="w-full  md:py-8 py-2">
      <div className="w-full max-w-[85%] mx-auto  p-6 rounded-lg shadow-lg">
        <div className="flex justify-center items-center">
          <Bar data={data} options={options} className="text-[#37415]" />
        </div>
      </div>
    </div>
  );
};

export default PlacementBarChart;