


import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import { Link } from 'react-router-dom';

export default function Rating_view() {

const{rating_fetch,rating,notify}=useContext(Context)



useEffect(

  ()=>{

    rating_fetch()


  },[]
)




const delete_handler= (id)=>{


  
            
  axios.delete(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_RATING_URL}delete/${id}`)
  
  .then((success) => {

    notify(success.data.msg,success.data.status)


    if (success.data.status === 1) {
      
     rating_fetch()
   

    }

  })

  .catch((error) => {
    console.error('Error:', error);
  });


}

if(rating?.length!=0){

  return (


    <div className="min-h-screen bg-gray-100 py-6 px-6 flex flex-col justify-center sm:py-3">

    <div className="relative py-3 ">
      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-10">
        <div className="max-w-full mx-auto">
          <h1 className="text-3xl font-bold text-center mb-6">Rating List</h1>
          <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
  <thead>
    <tr>

      
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">College Name</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User_Name</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rating</th>
     
     
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th>
    </tr>
  </thead>

  <tbody className="bg-white divide-y divide-gray-200">
    {rating?.rating?.map((data, index) => (
      <tr key={index}>
        
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-900">{data.college_id?.college_name}</div>
        </td>
        
       
        <td className="px-6 py-4 whitespace-nowrap">

          <div className="text-sm text-gray-500">{data?.user?.name ? data?.user?.name:"admin"}</div>

        </td>

        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{data.rating}</div>
        </td>
       
        <td className="px-6 py-8 items-center whitespace-nowrap flex gap-2 ">
          
          <button className='px-3 py-1 font-semibold rounded border shadow-md hover:text-white hover:bg-red-500' onClick={()=>delete_handler(data._id)}>Delete</button>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <button className='px-3 py-1 font-semibold rounded border  hover:shadow-lg'>View</button>
        </td>
      </tr>
    ))}
  </tbody>

</table>

          </div>
        </div>
      </div>
    </div>
  </div>
 
  )
}
else {
  return(
    <div >Please add College Rating</div>
  )
}
  
  
}

