import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../Context_holder';
import { useSearchParams } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa'; // Importing close icon from React Icons
import Select from 'react-select';
import { jsPDF } from 'jspdf';

import 'jspdf-autotable'; // Required for table generation

import axios from 'axios';
import * as XLSX from 'xlsx';
const states = require("../../../Json_files/States.json");
const cities = require("../../../Json_files/Cities.json");

const EnquiriesDetails = () => {
  const { users_fetch, allusers, course_fetch,course , college_state, setcollege_state,handlestateInputChange,statemenuIsOpen,college_city,setcollege_city,handlecityInputChange,citymenuIsOpen,notify} = useContext(Context); 


  const [SearchTerm, setSearchTerm] = useState("");
  const [Searched_college_name, setSearched_college_name] = useState("");
  const [enquiry_time, setenquiry_time] = useState("past24Hours");
  const [searchParams, setSearchParams] = useSearchParams();
  const [popup_status, setpopup_status] = useState(false);
  const [current_user_detals,setcurrent_user_detals] = useState(null);
  const[selected_course,setselected_course] =useState(null)
  const [enquiry_date, setenquiry_date] = useState("");
  const [user_id, setuser_id] = useState("");
  const [user_response_value, setuser_response_value] = useState("");
  const[input_pop_up,setinput_pop_up]= useState(false)
  
  useEffect(
    ()=>{
      course_fetch()
    },[]
  )

  useEffect(() => {
    const query = {};

    if (enquiry_time !== "") {
      query.enquiry_time = enquiry_time;
    }

    if (Searched_college_name !== "") {
      query.college_name = Searched_college_name;
    }


    if (selected_course !== null) {
      query.course = selected_course?.label;
    }

    if (enquiry_date!=="") {
      query.date = enquiry_date
    }

if(college_state!==""){

  query.state = college_state.label
}

if(college_city!==""){

  query.city = college_city.label
}
  
    setSearchParams(query);

    // Fetch users
    users_fetch(null, window.location.search.toString());
    
  }, [enquiry_time, Searched_college_name,selected_course,enquiry_date,college_state,college_city]);


  const search_handler = () => {
    setSearched_college_name(SearchTerm);
  };


  const popUp_handler = (user) => {
    setcurrent_user_detals(user);
    setpopup_status(true);
  };


  const checked_handler=(id,status)=>{

    axios.patch( process.env.REACT_APP_API_BASE_URL +
      process.env.REACT_APP_USER_URL+"checked_status_edit/"+id+"/"+ status,)
      .then((success)=>{
    
        notify(success.data.msg, success.data.status);

    
        if(success.data.status === 1){
          users_fetch(null, window.location.search.toString())
          setuser_response_value("")  
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }

  const user_response_handler=()=>{


    axios.patch( process.env.REACT_APP_API_BASE_URL +
      process.env.REACT_APP_USER_URL+"user_response_edit/"+user_id+"/"+ user_response_value,)
      .then((success)=>{
    
        notify(success.data.msg, success.data.status);

    
        if(success.data.status === 1){
          users_fetch(null, window.location.search.toString()) 
          setinput_pop_up(false) 
        }

      })
      .catch((error) => {
        console.log(error);
      });

  
  }


  const user_response_add=(id)=>{
    setinput_pop_up(true) 
    setuser_id(id)
  }


  const generatePDF=()=> {
    const doc = new jsPDF();

    // Add title

    doc.setFontSize(18);
    doc.text("User Data", 14, 22);

    // Create table
    

    const tableColumn = [ "Name",  "Email","Contact",  "Course", "College Name","Follow up"," User Response"];
    const tableRows = allusers?.users?.map(user => [
      
        user.name,
       
        user.email,
        
         
        user.contact,
        user.course,

       
       user?.collegeDetails?.college_name ? user?.collegeDetails?.college_name : "none",

user.checked==true? "Done": "panding",
user.user_response==""? "none": user.user_response,

    ]);

    doc.autoTable(tableColumn, tableRows, { startY: 30 });

    doc.save("user-data.pdf");
};



  const Reset_handler=()=>{
    setenquiry_date("")
    setenquiry_time("")
    setSearched_college_name("")
    setSearched_college_name("")
    setselected_course(null)
    setSearchTerm("")
    setcollege_city("")
    setcollege_state("")
    
  }


 
    const exportToExcel = () => {
      // Create a new workbook


      const data = allusers?.users?.map(enquiry => ({
        ID: enquiry._id,

        Name: enquiry.name,
        Contact: enquiry.contact,
        Email: enquiry.email,
       
        Course: enquiry.course,
        State: enquiry.state,
        City: enquiry.city,
        Enquiry: enquiry.enquiry,
        College: enquiry.collegeDetails.college_name ,
         checked:enquiry.checked?"done":"pending",
        user_response:enquiry.user_response ,
       

      }));
      const wb = XLSX.utils.book_new();
  
      // Convert the array of objects to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
  
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
      // Generate Excel file and trigger a download
      XLSX.writeFile(wb, `Students Enquiry.xlsx`);
    };

  return (
    <div className="container mx-auto p-4">
      <div className=' flex justify-between items-center mb-3'><h1 className="text-2xl font-bold ">Enquiries Details</h1> <div className='text-2xl font-bold '> Total({allusers?.users?.length})</div></div>
      
      
      <div className=" bg-[#a5e4f5] rounded p-5 flex flex-col md:flex-row mb-4 gap-4 flex-wrap">
  <div className="flex-1 min-w-[200px]">
    <p>College</p>
    <div className="flex gap-2">
      <input
        type="text"
        placeholder="Search college..."
        value={SearchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="p-2 border border-gray-300 rounded-md w-full"
      />
      <button
        className="py-1 px-4 rounded-lg hover:bg-blue-900 bg-[#5555f9] text-white font-semibold"
        onClick={search_handler}>
        Search
      </button>
    </div>
  </div>

  <div className="flex-1 min-w-[200px]">
    <p>Time</p>
    <select
      value={enquiry_time}
      
      onChange={(e) => setenquiry_time(e.target.value)}
      className="p-2 border border-gray-300 rounded-md w-full"
    >
      <option value="anyTime">AnyTime</option>
      <option value="past24Hours">Last 24 hours</option>
      <option value="pastWeek">Last 1 week</option>
      <option value="pastMonth">Last 1 month</option>
    </select>
  </div>

  <div className="flex-1 min-w-[200px]">
    <p>Course</p>
    <Select
      value={selected_course}
      styles={{
        control: (provided) => ({
          ...provided,
          padding: '4px 20px',
          marginTop: '4px',
        }),
      }}

      onChange={(e) => setselected_course(e)}
      name="college_id"
      options={course.filter((course, index, self) =>
        index === self.findIndex((c) => c.courseName === course.courseName)
      )?.map(data => ({ value: data._id, label: data.courseName }))}
    />
  </div>

  <div className="flex-1 min-w-[200px]">
    <p>Date</p>
    <input
      type="date"
      value={enquiry_date}
      className="py-2 border rounded px-3 w-full"
      onChange={(e) => setenquiry_date(e.target.value)}
    />
  </div>

  <div className="flex-1 min-w-[200px]">
    <p>State</p>
    <Select
      value={college_state}
      styles={{
        control: (provided) => ({
          ...provided,
          padding: '4px 20px',
          marginTop: '4px',
        }),
      }}
      onChange={(e) => setcollege_state(e)}
      onInputChange={handlestateInputChange}
      menuIsOpen={statemenuIsOpen}
      name="states"
      options={states.map(data => ({ value: data.name, label: data.name }))}
    />
  </div>

  <div className="flex-1 min-w-[200px]">
    <p>City</p>
    <Select
      value={college_city}
      styles={{
        control: (provided) => ({
          ...provided,
          padding: '4px 20px',
          marginTop: '4px',
        }),
      }}
      onChange={(e) => setcollege_city(e)}
      onInputChange={handlecityInputChange}
      menuIsOpen={citymenuIsOpen}
      name="cities"
      options={cities?.map(data => ({ value: data.name, label: data.name }))}
    />
  </div>

  <div className="flex-1 min-w-[200px]">
    <button
      className="py-1 px-4 rounded-lg hover:bg-[red] bg-[#ffaa00] text-white font-semibold "
      onClick={Reset_handler}
    >
      Reset
    </button>
  </div>
</div>


<div className="overflow-x-auto shadow-lg rounded-lg">
  <table className="min-w-full bg-white text-left border border-gray-300 rounded-md">
    <thead className="bg-gray-100 text-gray-700 font-semibold">
      <tr>
        <th className="p-4 border-b">Name</th>
        <th className="p-4 border-b">Email</th>
        <th className="p-4 border-b">Contact</th>
        <th className="p-4 border-b">Course</th>
        <th className="p-4 border-b">College</th>
        <th className="p-4 border-b text-center">Enquiry</th>
        <th className="p-4 border-b text-center">Follow Up</th>
        <th className="p-4 border-b text-left">Response</th>
      </tr>
    </thead>
    <tbody>
      {allusers?.users?.length === 0 ? (
        <tr>
          <td colSpan="8" className="p-4 text-center text-gray-500">No users found</td>
        </tr>
      ) : (
        allusers?.users?.map((user, index) => (
          <tr key={user.id} className="hover:bg-gray-50 transition-colors duration-300">
            <td className="p-4 border-b">{user.name}</td>
            <td className="p-4 border-b">{user.email}</td>
            <td className="p-4 border-b">{user.contact}</td>
            <td className="p-4 border-b">{user.course}</td>
            <td className="p-4 border-b">{user?.collegeDetails?.college_name || "None"}</td>
            <td className="p-4 border-b text-center">
              <button
                className="py-2 px-4 rounded-md hover:bg-red-800 bg-red-700 text-white font-semibold transition-colors duration-300"
                onClick={() => popUp_handler(user)}
              >
                View
              </button>
            </td>
            <td className="p-4 border-b text-center">
              <button
                className={`py-2 px-4 rounded-md ${user.checked ? "hidden" : "block"} bg-red-600 hover:bg-red-500 text-white font-semibold transition-colors duration-300`}
                onClick={() => checked_handler(user._id, true)}
              >
                Pending
              </button>
              <button
                className={`py-2 px-4 rounded-md ${user.checked ? "block" : "hidden"} bg-green-600 hover:bg-green-500 text-white font-semibold transition-colors duration-300`}
                onClick={() => checked_handler(user._id, false)}
              >
                Done
              </button>
            </td>
            <td className="p-4 border-b flex items-center space-x-2">
              <div className={`${user?.user_response !== "" ? "block" : "hidden"}`}>
                {user?.user_response}
              </div>
              <button
                className="py-1 px-4 rounded-md hover:bg-blue-700 bg-blue-600 text-white font-semibold transition-colors duration-300"
                onClick={() => user_response_add(user?._id)}
              >
                {user?.user_response !== "" ? "Change" : "Add"}
              </button>
            </td>
          </tr>
        ))
      )}
    </tbody>
  </table>
</div>



<div className={`fixed  bg-black bg-opacity-50 top-8  ${input_pop_up? "right-0":"right-[-200%]"}  flex justify-center items-center z-50`}>
  <div className="bg-white p-6 rounded-lg shadow-lg relative">
    <button
      className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
      onClick={ ()=>setinput_pop_up(false)}
    >
      &times;
    </button>
    <input
      type="text"
      className="py-2 px-4 rounded-lg border border-gray-300 w-full"
      placeholder="Write response..."
      value={user_response_value}
      onChange={(e) => setuser_response_value(e.target.value)}
    />
    <button
      className="py-2 px-4 mt-4 w-full rounded-lg bg-blue-600 text-white font-semibold"
    onClick={user_response_handler}
    >
      Submit
    </button>
  </div>
</div>
<div className="flex flex-col mt-5 sm:flex-row justify-between gap-2">
  <button
    className="py-1 px-4 rounded-lg bg-red-600 hover:bg-red-700 text-white font-semibold"
    onClick={generatePDF}
  >
    Download PDF
  </button>
  <button
    className="py-1 px-4 rounded-lg bg-green-600 hover:bg-green-700 text-white font-semibold"
    onClick={exportToExcel}
  >
    Export to Excel
  </button>
</div>



      <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-all duration-300 ${popup_status ? "opacity-100 visible" : "opacity-0 invisible"}`}>
        <div className="relative max-w-4xl w-full bg-white rounded-lg shadow-lg p-6">
          <button
            onClick={() => setpopup_status(false)}
            className="absolute top-3 right-3 text-gray-600 hover:text-gray-900 focus:outline-none"
          >
            <FaTimes size={24} />
          </button>
          <h1 className="text-3xl font-bold mb-6">User Enquiry</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h2 className="text-xl font-semibold mb-2">Personal Information</h2>
              <div className="mb-4">
                <span className="text-gray-600 font-medium">Name: </span>
                <span>{current_user_detals?.name}</span>
              </div>
              <div className="mb-4">
                <span className="text-gray-600 font-medium">Email: </span>
                <span>{current_user_detals?.email}</span>
              </div>
              <div className="mb-4">
                <span className="text-gray-600 font-medium">Phone: </span>
                <span>{current_user_detals?.contact}</span>
              </div>
              <div className="mb-4">
                <span className="text-gray-600 font-medium">Address: </span>
                <span>{current_user_detals?.state}-{current_user_detals?.city}</span>
              </div>
              <div className="mb-4">
                <span className="text-gray-600 font-medium">Course: </span>
                <span>{current_user_detals?.course}</span>
              </div>

              <div className="mb-4">
                <span className="text-gray-600 font-medium">College: </span>
                <span>{current_user_detals?.collegeDetails?.college_name}</span>
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-bold mb-2">Enquiry</h2>
              <div>{current_user_detals?.enquiry}</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );

};

export default EnquiriesDetails;


