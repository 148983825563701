import React, { useContext, useState } from 'react';
import { Context } from '../../../../../Context_holder';
import { useNavigate } from 'react-router-dom';

const places = [
  { name: 'Delhi NCR', icon: '🏙️' },
  { name: 'Bangalore', icon: '🌆' },
  { name: 'Hyderabad', icon: '🏛️' },
  { name: 'Pune', icon: '🏯' },
  { name: 'Mumbai', icon: '🏰' },
  { name: 'Chennai', icon: '🏨' },
  { name: 'Kolkata', icon: '🏫' },
  { name: 'Ahmedabad', icon: '🏢' },
  { name: 'Jaipur', icon: '🏯' },
  { name: 'Lucknow', icon: '🏛️' },
  { name: 'Chandigarh', icon: '🏙️' },
  { name: 'Surat', icon: '🏢' },
  { name: 'Nagpur', icon: '🏫' },
  { name: 'Indore', icon: '🏨' },
  { name: 'Coimbatore', icon: '🏢' },
  // Add more places as needed
];

const TopStudyPlaces = () => {


  const [showAll, setShowAll] = useState(false);

  const{setcollege_city,setfilterHeading }=useContext(Context)
  const navigater=useNavigate()

  const best_college_handler = (city) => {

    setcollege_city({value:city})
  
    navigater("/allUniversity");
  
    setfilterHeading("Top colleges in")
  
  };

  

  // Determine how many places to show based on screen size
  const displayedPlaces = showAll ? places : places.slice(0, window.innerWidth < 768 ? 8 : 24);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="my-10 w-[90%]">
        <h2 className="text-xl  md:mb-4 mb-2 text-green-300">Top Study Places</h2>
        <div className="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-12 md:gap-2 gap-4">
          {displayedPlaces.map((place, index) => (
            <div
              key={index}
              className="flex flex-col items-center  justify-center w-[5.5rem] md:w-24 h-24 bg-white shadow-md rounded-lg p-2 hover:bg-gray-50 cursor-pointer"
              onClick={()=>best_college_handler(place.name)}
            >
              <span className="text-3xl ">{place.icon}</span>
              <p className="mt-1 text-[15px] font-medium text-center text-black">{place.name}</p>
            </div>
          ))}
        </div>
      </div>
      {/* More Button */}
      <button
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        onClick={() => setShowAll((prev) => !prev)}
      >
        {showAll ? 'Show Less' : 'More'}
      </button>
    </div>
  );
};

export default TopStudyPlaces;
