





import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import { Context } from '../../../../Context_holder';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';

export default function Website_blog_edit() {

    const{website_blog_fetch,currentwebsite_blog,quill_value,setquill_value,token,notify }=useContext(Context)
    


const [current_data,setcurrent_data]= useState( null)
const [logo_value, setlogo_value] = useState("")

const logo_ref=useRef()



const {id} =useParams()





    useEffect(
        ()=>{

            website_blog_fetch(id)
      
         
         
        
         
      
        
        },[]
      )



     
useEffect(

    ()=>{
setcurrent_data(currentwebsite_blog)




setquill_value(currentwebsite_blog?.post)
    },[currentwebsite_blog]
)
    

const submit_handler = (e)=>{

        e.preventDefault();
        
        
    
       
       
        const post= quill_value
        
       const author = e.target.author.value
       const heading = e.target.heading.value
    
      const data= {
       
        post:post,
      
        author:author,
        heading:heading
      }
    
      
     
    
      axios.put(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_WEBSITE_BLOG_URL+"edit/"+id,data,{
        headers: {
          Authorization: token}
      })
      .then((success) => {
        
        notify(success.data.msg,success.data.status)
        if (success.data.status === 1) {
         
    
        website_blog_fetch(id)
       
    
        }
      })
    
      .catch((error) => {
        console.error('Error:', error);
      });
    
    
    }


    const logo_handler = ()=>{
        const logo= logo_value
        const old_logo=current_data?.logo
        const id=current_data?._id
        
        const formData = new FormData();
        
        
        formData.append("logo",logo)
        formData.append("old_logo",old_logo)
        
        
        
        
          axios.patch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_WEBSITE_BLOG_URL}logo_edit/${id}`,formData,{
            headers: {
              Authorization: token}
          })
          .then((success) => {
         
           
           notify(success.data.msg,success.data.status)
        
            if (success.data.status === 1) {
                website_blog_fetch(id)
              setlogo_value(null)
              
              logo_ref.current.value=""
            }
          })
        
          .catch((error) => {
            console.error('Error:', error);
          });
        
        
        }

    return (
      <div className='min-h-screen py-10 bg-gray-300'>
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
        <div className="text-center text-4xl font-bold mb-3"> Blog Edit</div>
        <form onSubmit={submit_handler} enctype="multipart/form-data">
        
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
        

           
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="author">
            Author Name
              </label>
              <input
              defaultValue={current_data?.author}
                id="author"
                name="author"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Auther Name"
              />
            </div>
          </div>


         

          <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="heading">
                Heading
              </label>
              <textarea
              defaultValue={current_data?.heading}
                id="heading"
                name="heading"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Heading"
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="heading">
                Post
              </label>

             <div>
             <ReactQuill value={quill_value}  onChange={(e)=> setquill_value(e)}/></div> 

            
           
           
             

         
            </div>


            

            <div className="flex justify-end mb-4 mt-2">
            <button
              type="submit"
              className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save
            </button>
          </div>
          
        </form>

        <div >
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="logo">
                Image
              </label>
              <input
                        ref={logo_ref}
                        type='file'
                        id="logo"
                        name="logo"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        onChange={(e) => setlogo_value(e.target.files[0])}
                    />
            </div>
            <button className={`hover:bg-blue-950 capitalize bg-blue-700 text-white text-md font-semibold px-3 py-1 rounded-md mt-2 ${logo_value ? "block" : "hidden"}`} onClick={logo_handler} >
                        Update Logo
                    </button>

      </div>
      

      
     
    </div>
    

        )
}
