import React, { useState } from 'react';
import { FaBullseye, FaGraduationCap, FaHandsHelping, FaBook } from 'react-icons/fa';

const courses = [
  {
    title: 'UI/UX Design',
    jobGrowth: '22% by 2030',
    salary: '$90k/year (entry-level)',
    duration: '6 months',
    fees: '$1000',
    growth: 'High demand in tech industries',
    icon: <FaBullseye />
  },
  {
    title: 'Cybersecurity',
    jobGrowth: '31% by 2029',
    salary: '$110k/year (entry-level)',
    duration: '9 months',
    fees: '$1500',
    growth: 'Critical as cyber threats increase',
    icon: <FaGraduationCap />
  },
  {
    title: 'Artificial Intelligence',
    jobGrowth: '40% by 2030',
    salary: '$140k/year',
    duration: '12 months',
    fees: '$2500',
    growth: 'Explosive, AI is transforming all industries',
    icon: <FaHandsHelping />
  },
  {
    title: 'Cloud Computing',
    jobGrowth: '26% by 2027',
    salary: '$130k/year',
    duration: '10 months',
    fees: '$1800',
    growth: 'High demand across enterprises',
    icon: <FaBook />
  },
];



const FuturisticCourses = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto p-8 bg-gradient-to-b from-[#18092f] to-purple-900 t0-[#18092f] text-white">
      <h1 className="md:text-2xl  text-md  font-semibold text-center mb-6">Top Futuristic High-Paying Courses for 2024</h1>
      <div className="space-y-6">
        {courses.map((course, index) => (
          <div key={index}>
            <div
              onClick={() => toggleAccordion(index)}
              className="flex items-center justify-between p-4 bg-black rounded-lg cursor-pointer transition-transform duration-300 hover:scale-105"
            >
              <div className="flex items-center">
                <span className="text-3xl mr-4">{course.icon}</span>
                <h2 className="text-xl font-semibold">{course.title}</h2>
              </div>
              <span className="text-lg">{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && (
              <div className="mt-2 p-4 bg-gray-800 rounded-lg transition-all duration-300">
                <ul className="space-y-1">
                  <li><strong className='text-sm'>Job Growth:</strong> {course.jobGrowth}</li>
                  <li><strong className='text-sm'>Average Salary:</strong> {course.salary}</li>
                  <li><strong className='text-sm'>Course Duration:</strong> {course.duration}</li>
                  <li><strong className='text-sm'>Course Fees:</strong> {course.fees}</li>
                  <li><strong className='text-sm'>Career Growth:</strong> {course.growth}</li>
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FuturisticCourses;




// import React, { useState } from 'react';
// import { FaBullseye, FaGraduationCap, FaHandsHelping, FaBook } from 'react-icons/fa';

// const courses = [
//   {
//     title: 'UI/UX Design',
//     jobGrowth: '22% by 2030',
//     salary: '$90k/year (entry-level)',
//     duration: '6 months',
//     fees: '$1000',
//     growth: 'High demand in tech industries',
//     icon: <FaBullseye />
//   },
//   {
//     title: 'Cybersecurity',
//     jobGrowth: '31% by 2029',
//     salary: '$110k/year (entry-level)',
//     duration: '9 months',
//     fees: '$1500',
//     growth: 'Critical as cyber threats increase',
//     icon: <FaGraduationCap />
//   },
//   {
//     title: 'Artificial Intelligence',
//     jobGrowth: '40% by 2030',
//     salary: '$140k/year',
//     duration: '12 months',
//     fees: '$2500',
//     growth: 'Explosive, AI is transforming all industries',
//     icon: <FaHandsHelping />
//   },
//   {
//     title: 'Cloud Computing',
//     jobGrowth: '26% by 2027',
//     salary: '$130k/year',
//     duration: '10 months',
//     fees: '$1800',
//     growth: 'High demand across enterprises',
//     icon: <FaBook />
//   },
// ];

// const FuturisticCourses = () => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   const toggleAccordion = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   return (
//     <div className="container mx-auto p-8 bg-gradient-to-b from-[#18092f] to-purple-900 text-white">
//       <h1 className="text-3xl font-bold text-center mb-6">Top Futuristic High-Paying Courses for 2024</h1>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
//         {courses.map((course, index) => (
//           <div key={index} className="bg-black p-4 rounded-lg transition-transform duration-300 hover:scale-105 cursor-pointer">
//             <div onClick={() => toggleAccordion(index)} className="flex items-center">
//               <span className="text-3xl mr-2">{course.icon}</span>
//               <h2 className="text-xl font-semibold">{course.title}</h2>
//             </div>
//             {activeIndex === index && (
//               <div className="mt-2 p-2 bg-gray-800 rounded-lg">
//                 <ul className="text-gray-400 space-y-1">
//                   <li><strong>Job Growth:</strong> {course.jobGrowth}</li>
//                   <li><strong>Average Salary:</strong> {course.salary}</li>
//                   <li><strong>Course Duration:</strong> {course.duration}</li>
//                   <li><strong>Course Fees:</strong> {course.fees}</li>
//                   <li><strong>Career Growth:</strong> {course.growth}</li>
//                 </ul>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default FuturisticCourses;



// import React, { useState } from 'react';
// import { FaBullseye, FaGraduationCap, FaHandsHelping, FaBook, FaCommentsDollar } from 'react-icons/fa';

// const courses = [
//   {
//     title: 'UI/UX Design',
//     jobGrowth: '22% by 2030',
//     salary: '$90k/year (entry-level)',
//     duration: '6 months',
//     fees: '$1000',
//     growth: 'High demand in tech industries',
//     icon: <FaBullseye />
//   },
//   {
//     title: 'Cybersecurity',
//     jobGrowth: '31% by 2029',
//     salary: '$110k/year (entry-level)',
//     duration: '9 months',
//     fees: '$1500',
//     growth: 'Critical as cyber threats increase',
//     icon: <FaGraduationCap />
//   },
//   {
//     title: 'Artificial Intelligence',
//     jobGrowth: '40% by 2030',
//     salary: '$140k/year',
//     duration: '12 months',
//     fees: '$2500',
//     growth: 'Explosive, AI is transforming all industries',
//     icon: <FaHandsHelping />
//   },
//   {
//     title: 'Cloud Computing',
//     jobGrowth: '26% by 2027',
//     salary: '$130k/year',
//     duration: '10 months',
//     fees: '$1800',
//     growth: 'High demand across enterprises',
//     icon: <FaBook />
//   },
// ];

// const FuturisticCourses= () => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   const toggleAccordion = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   return (
//     <div className="container mx-auto p-8 bg-gradient-to-b from-[#18092f] to-purple-900 text-white">
//       <h1 className="text-3xl font-bold text-center mb-6">
//         Top Futuristic High-Paying Courses for 2024
//       </h1>
//       <div className="space-y-4">
//         {courses.map((course, index) => (
//           <div key={index}>
//             <div
//               onClick={() => toggleAccordion(index)}
//               className="flex items-center justify-between p-4 bg-black rounded-lg cursor-pointer transition-transform duration-300 hover:scale-105"
//             >
//               <div className="flex items-center">
//                 <span className="text-3xl mr-4">{course.icon}</span>
//                 <h2 className="text-xl font-semibold">{course.title}</h2>
//               </div>
//               <span className="text-lg">{activeIndex === index ? '-' : '+'}</span>
//             </div>
//             {activeIndex === index && (
//               <div className="mt-2 p-4 bg-gray-800 rounded-lg transition-all duration-300">
//                 <ul className="space-y-1">
//                   <li><strong>Job Growth:</strong> {course.jobGrowth}</li>
//                   <li><strong>Average Salary:</strong> {course.salary}</li>
//                   <li><strong>Course Duration:</strong> {course.duration}</li>
//                   <li><strong>Course Fees:</strong> {course.fees}</li>
//                   <li><strong>Career Growth:</strong> {course.growth}</li>
//                 </ul>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default FuturisticCourses;



// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const courses = [
//   {
//     title: "UI/UX Design",
//     jobGrowth: "22% by 2030",
//     salary: "$90k/year (entry-level)",
//     duration: "6 months",
//     fees: "$1000",
//     growth: "High demand in tech industries",
//   },
//   {
//     title: "Cybersecurity",
//     jobGrowth: "31% by 2029",
//     salary: "$110k/year (entry-level)",
//     duration: "9 months",
//     fees: "$1500",
//     growth: "Critical as cyber threats increase",
//   },
//   {
//     title: "Artificial Intelligence",
//     jobGrowth: "40% by 2030",
//     salary: "$140k/year",
//     duration: "12 months",
//     fees: "$2500",
//     growth: "Explosive, AI is transforming all industries",
//   },
//   {
//     title: "Cloud Computing",
//     jobGrowth: "26% by 2027",
//     salary: "$130k/year",
//     duration: "10 months",
//     fees: "$1800",
//     growth: "High demand across enterprises",
//   },
//   {
//     title: "Blockchain Development",
//     jobGrowth: "25% by 2030",
//     salary: "$115k/year",
//     duration: "8 months",
//     fees: "$2200",
//     growth: "Rapid growth in fintech",
//   },
//   {
//     title: "DevOps Engineering",
//     jobGrowth: "21% by 2028",
//     salary: "$105k/year",
//     duration: "7 months",
//     fees: "$1700",
//     growth: "Crucial for continuous development pipelines",
//   },
//   {
//     title: "Digital Marketing",
//     jobGrowth: "20% by 2026",
//     salary: "$80k/year",
//     duration: "6 months",
//     fees: "$900",
//     growth: "Huge growth with online businesses",
//   },
//   {
//     title: "Product Management",
//     jobGrowth: "15% by 2027",
//     salary: "$130k/year",
//     duration: "6 months",
//     fees: "$1200",
//     growth: "Increasingly vital for tech startups",
//   },
//   {
//     title: "Mobile App Development",
//     jobGrowth: "19% by 2029",
//     salary: "$100k/year",
//     duration: "8 months",
//     fees: "$1600",
//     growth: "Growing with the rise of mobile technology",
//   },
//   {
//     title: "Machine Learning",
//     jobGrowth: "35% by 2028",
//     salary: "$135k/year",
//     duration: "10 months",
//     fees: "$2300",
//     growth: "Key to data-driven industries",
//   },
//   {
//     title: "Full-Stack Development",
//     jobGrowth: "28% by 2027",
//     salary: "$110k/year",
//     duration: "9 months",
//     fees: "$1900",
//     growth: "Increasing demand for versatile developers",
//   },
//   // Other courses data...
// ];

// const FuturisticCourses = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="container mx-auto p-8">
//       <h1 className="text-3xl font-bold text-center mb-8">
//         Top 12 Futuristic High-Paying Courses for 2024
//       </h1>

//       {/* Slider for mobile view */}
//       <div className="block sm:hidden">
//         <Slider {...settings}>
//           {courses.map((course, index) => (
//             <div key={index}>
//               <div className="bg-black p-6 rounded-lg shadow-lg transform transition duration-500 hover:rotate-2 hover:scale-105 hover:shadow-2xl">
//                 <h2 className="text-xl text-yellow-800 font-semibold mb-2">
//                   {course.title}
//                 </h2>
//                 <ul className="text-gray-400">
//                   <li>
//                     <strong>Job Growth:</strong> {course.jobGrowth}
//                   </li>
//                   <li>
//                     <strong>Average Salary:</strong> {course.salary}
//                   </li>
//                   <li>
//                     <strong>Course Duration:</strong> {course.duration}
//                   </li>
//                   <li>
//                     <strong>Course Fees:</strong> {course.fees}
//                   </li>
//                   <li>
//                     <strong>Career Growth:</strong> {course.growth}
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>

//       {/* Grid view for larger screens */}
//       <div className="hidden sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//         {courses.map((course, index) => (
//           <div
//             key={index}
//             className="bg-black p-6 rounded-lg shadow-lg transform transition duration-500 hover:rotate-2 hover:scale-105 hover:shadow-2xl"
//           >
//             <h2 className="text-xl text-yellow-800 font-semibold mb-2">
//               {course.title}
//             </h2>
//             <ul className="text-gray-400">
//               <li>
//                 <strong>Job Growth:</strong> {course.jobGrowth}
//               </li>
//               <li>
//                 <strong>Average Salary:</strong> {course.salary}
//               </li>
//               <li>
//                 <strong>Course Duration:</strong> {course.duration}
//               </li>
//               <li>
//                 <strong>Course Fees:</strong> {course.fees}
//               </li>
//               <li>
//                 <strong>Career Growth:</strong> {course.growth}
//               </li>
//             </ul>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default FuturisticCourses;



// import React from 'react';
// import { FaBullseye, FaGraduationCap, FaCode, FaShieldAlt } from 'react-icons/fa';

// const courses = [
//   { title: 'UI/UX Design', icon: <FaBullseye />, details: 'Duration: 6 months | $1000' },
//   { title: 'Cybersecurity', icon: <FaShieldAlt />, details: 'Duration: 9 months | $1500' },
//   { title: 'AI & Machine Learning', icon: <FaGraduationCap />, details: 'Duration: 12 months | $2500' },
//   { title: 'Cloud Computing', icon: <FaCode />, details: 'Duration: 10 months | $1800' },
// ];

// const FuturisticCourses = () => {
//   return (
//     <div className="relative p-8 bg-gradient-to-b from-[#18092f] to-purple-900 to-[#18092f] text-white">
//       <h1 className="text-3xl text-center font-bold mb-6">Futuristic Courses Timeline</h1>
//       <div className="relative flex flex-col items-center space-y-12">
//         {courses.map((course, index) => (
//           <div key={index} className="relative flex flex-col items-center group">
//             {/* Node */}
//             <div className="bg-white rounded-full p-4 transform group-hover:scale-110 transition duration-300 shadow-lg">
//               {course.icon}
//             </div>
//             {/* Connecting Line */}
//             {index !== courses.length - 1 && (
//               <div className="h-24 w-1 bg-white"></div>
//             )}
//             {/* Course Information */}
//             <div className=" mt-4 transition-opacity duration-300">
//               <h2 className="text-lg font-bold">{course.title}</h2>
//               <p className="text-sm text-gray-300">{course.details}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default FuturisticCourses;
