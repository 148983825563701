import React from "react";
import "./overteamextra.css";

const OverTeamExtra = () => {
  return (
    <div
      className="relative grid grid-cols-1 md:grid-cols-[45%_50%] md:gap-12 gap-2 overflow-hidden py-2 md:py-16 px-4 md:px-16"
      id="team"
    >
      <div className="text-white md:py-8 py-2 relative z-10">
        <h1 className="text-2xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#76a73f] via-[#338f76] to-[#7598f2] mb-6 md:mb-8 leading-tight">
          Our Best Placed Students <br /> with the Best Companies
        </h1>
        <p className="text-base md:text-lg font-sans leading-relaxed mb-1 md:mb-8 text-black">
        In a world brimming with opportunities, the role of a mentor can be pivotal in helping individuals unlock their full potential. Mentorship provides guidance, support, and wisdom that can significantly shape personal and professional growth.

People often seek out mentors for various reasons. First and foremost, mentors bring experience to the table. They have often navigated the same challenges that mentees face, providing insights and strategies that can streamline the learning process. This firsthand knowledge can help mentees avoid common pitfalls and make more informed decisions.


        </p>
        {/* <form
          id="form"
          className="flex flex-col md:flex-row justify-start space-y-4 md:space-y-0 md:space-x-4"
          autoComplete="off"
        >
          <input
            type="email"
            id="email-id"
            name="email_address"
            aria-label="email address"
            placeholder="Enter your Phone Number"
            required
            className="bg-transparent border-b-2 border-white/30 px-2 py-1 outline-none transition-all duration-300 focus:outline-dotted focus:outline-[#7598f2] focus:outline-offset-[-3px]"
          />
          <button
            type="submit"
            className="flex items-center gap-2 bg-gradient-to-r from-[#76a73f] via-[#338f76] to-[#7598f2] bg-[length:200%] bg-left text-white text-base md:text-lg font-medium border-0 py-2 px-4 rounded-full shadow-lg shadow-black/50 transition-all duration-300 hover:bg-right"
            aria-label="submit"
          >
            
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </button>
        </form> */}
      </div>
      <div className="relative grid grid-cols-3 md:grid-cols-3 gap-4 md:gap-6 w-full max-w-4xl">
        {/* Images */}
        <div className="relative">
          <img
            className="w-full h-full object-cover rounded-lg shadow-lg shadow-black/50 animate-[fadeIn_0.5s_linear_forwards]"
            src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/274f29ce-0d3f-4ac2-a2aa-f9b7bd188b2a"
            alt="Student 1"
              loading="lazy"
            style={{ animationDelay: "0s" }}
          />
        </div>
        <div className="relative">
          <img
            className="w-full h-full object-cover rounded-lg shadow-lg shadow-black/50 animate-[fadeIn_0.5s_linear_forwards]"
            src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/b8a14493-3d9f-4b9b-b93a-56d0bc7243e9"
            alt="Student 2"
              loading="lazy"
            style={{ animationDelay: "0.1s" }}
          />
        </div>
        <div className="relative">
          <img
            className="w-full h-full object-cover rounded-lg shadow-lg shadow-black/50 animate-[fadeIn_0.5s_linear_forwards]"
            src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/03e51e1e-9750-45a5-b75e-a1e341d4562a"
            alt="Student 3"
              loading="lazy"
            style={{ animationDelay: "0.2s" }}
          />
        </div>
        <div className="relative  md:block">
          <img
            className="w-full h-full object-cover rounded-lg shadow-lg shadow-black/50 animate-[fadeIn_0.5s_linear_forwards]"
            src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/5eb50f89-3e5a-480e-860c-8d40d3ba9ffe"
            alt="Student 4"
              loading="lazy"
            style={{ animationDelay: "0.3s" }}
          />
        </div>
        <div className="relative  md:block">
          <img
            className="w-full h-full object-cover rounded-lg shadow-lg shadow-black/50 animate-[fadeIn_0.5s_linear_forwards]"
            src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/86c71a79-2efe-4567-8665-b1e5a1fd9735"
            alt="Student 5"
              loading="lazy"
            style={{ animationDelay: "0.4s" }}
          />
        </div>
        <div className="relative  md:block">
          <img
            className="w-full h-full object-cover rounded-lg shadow-lg shadow-black/50 animate-[fadeIn_0.5s_linear_forwards]"
            src="https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/97ef9643-5202-41aa-80f0-ceeabccdd099"
            alt="Student 6"
              loading="lazy"
            style={{ animationDelay: "0.5s" }}
          />
        </div>
        {/* Colored divs */}
      </div>
    </div>
  );
};

export default OverTeamExtra;
