import React from 'react';
import { FaBullseye, FaGraduationCap, FaHandsHelping, FaBook, FaCommentsDollar } from 'react-icons/fa';
import { MdSchool } from 'react-icons/md';

function WhatMakeUs() {
  return (
    <div className="bg-gradient-to-b from-[#18092f] via-purple-900 to-black py-8 px-8 md:px-16">
      <h1 className="text-4xl md:text-5xl font-bold text-center text-white mb-8">
        Our One Stop <span className="text-green-400">Solution</span>
      </h1>
      <p className="text-lg md:text-xl text-center text-gray-300 mb-12">Here's why we stand out:</p>

      {/* Features Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center">
        
        {/* Feature 1 */}
        <div className="flex flex-col items-center text-white space-y-4">
          <FaBullseye className="text-6xl text-green-400" />
          <h2 className="text-2xl font-semibold">Select Your Desired Course</h2>
          <p className="text-gray-300 max-w-xs">
            Get one step closer to your dream career with the right course.
          </p>
        </div>

        {/* Feature 2 */}
        <div className="flex flex-col items-center text-white space-y-4">
          <MdSchool className="text-6xl text-blue-400" />
          <h2 className="text-2xl font-semibold">Fill in the Basic Details</h2>
          <p className="text-gray-300 max-w-xs">
            Help us know you better with basic information and a few questions.
          </p>
        </div>

        {/* Feature 3 */}
        <div className="flex flex-col items-center text-white space-y-4">
          <FaHandsHelping className="text-6xl text-yellow-400" />
          <h2 className="text-2xl font-semibold">Explore Your Options</h2>
          <p className="text-gray-300 max-w-xs">
            Don't limit yourself. Explore top universities with our curated list.
          </p>
        </div>

        {/* Feature 4 */}
        <div className="flex flex-col items-center text-white space-y-4">
          <FaGraduationCap className="text-6xl text-purple-400" />
          <h2 className="text-2xl font-semibold">Get the Best</h2>
          <p className="text-gray-300 max-w-xs">
            Compare universities based on key aspects like student ratings.
          </p>
        </div>

        {/* Feature 5 */}
        <div className="flex flex-col items-center text-white space-y-4">
          <FaCommentsDollar className="text-6xl text-red-400" />
          <h2 className="text-2xl font-semibold">Scholarship Aid</h2>
          <p className="text-gray-300 max-w-xs">
            Discover colleges offering financial aid and scholarships.
          </p>
        </div>

        {/* Feature 6 */}
        <div className="flex flex-col items-center text-white space-y-4">
          <FaBook className="text-6xl text-indigo-400" />
          <h2 className="text-2xl font-semibold">In-Depth Resources</h2>
          <p className="text-gray-300 max-w-xs">
            Access comprehensive guides and study materials to excel.
          </p>
        </div>

      </div>
    </div>
  );
}

export default WhatMakeUs;


// import React from 'react';
// import imageSrc from "./KEY POINTS.png"


// const WhatMakeUs = () => {
//   return (
//     <div className="relative w-full max-w-full">
//       <img
//         src={imageSrc}
//         alt="Responsive"
//         className="w-full h-auto object-cover"
//       />
   
//     </div>
//   );
// };

// export default WhatMakeUs;



// import React, { useState } from "react";
// import "./index.css";
// import { TbArrowNarrowUp, TbRotateRectangle } from "react-icons/tb";
// import { FcCustomerSupport, FcTodoList } from "react-icons/fc";
// // import { FaWpforms } from "react-icons/fa";

// function OneStopSolution() {
//   const [solution, setSolution] = useState([
//     {
//       ico: <TbArrowNarrowUp className="text-4xl font-[900] text-white" />,
//       titel: "Select Your Desired course",
//       subTit: "Get one step closer to your dream career with the right course",
//     },
//     {
//       ico: <TbRotateRectangle className="text-4xl font-[900] text-white" />,
//       titel: "Fill In the Basic Details",
//       subTit: "Help us know you better with basic information & few questions",
//     },
//     {
//       ico: <FcTodoList className="text-4xl font-[900] text-white" />,
//       titel: "Explore Your Options",
//       subTit:
//         "Don't limit yourself, explore top universities with our specially designed list",
//     },
//     {
//       ico: <TbRotateRectangle className="text-4xl font-[900] text-white" />,
//       titel: "Get the Best",
//       subTit:
//         "Compare top universities on different aspects like Student rating, CV score, Placement assistance etc",
//     },
//     {
//       ico: <FcCustomerSupport className="text-4xl font-[900] text-white" />,
//       titel: "With You, For You",
//       subTit: "Call our team of experts for all your queries and questions.",
//     },
//     {
//       ico: <FcTodoList className="text-4xl font-[900] text-white" />,
//       titel: "Business Studies",
//       subTit: "Call our team of experts for all your queries and questions.",
//     },
//   ]);
//   return (
//     <div className="bg-[#f2f2f2] w-full py-[5rem]   flex flex-col items-center ">
//       <div className="w-fit flex justify-center mb-8">
//         <p className="text-[30px] font-bold">
//           Our One Stop Solution
//         </p>
//       </div>
//       <div className="container mx-auto">
//         <div className="flex flex-wrap gap-3">
//           {solution.map((data, i) => {
//             return (
//               <div className="w-full cursor-pointer sm:w-[48%] lg:w-[30%] mb-5 px-2">
//                 <div className=" flex border-4 gap-3 p-2 min-h-[170px]
//                 rounded-xl
//                  items-center hover:shadow-2xl">
//                   <div className="flex w-[60px] h-[60px] rounded-full
//                    bg-blue-400 items-center text-white justify-center">
//                     {data.ico}
//                   </div>
//                   <div className="w-[80%] ">
//                     <h3 className="bg-yellow-500 text-white pl-2 rounded-md">{data.titel}</h3>
//                     <p className="">{data.subTit}</p>
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default OneStopSolution;
