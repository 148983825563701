import React, { useState } from "react";

const BlogTopSection = ({ search_handler, recent_post_handler }) => {
  const [search_heading, setsearch_heading] = useState("");

  return (
    <div className="w-full flex justify-center ">
    <nav className=" w-full md:bg-gray-800 bg-transparent text-white md:p-4  w-full rounded ">
      <div className=" flex justify-between items-center w-full">
        <button
          // onClick={recent_post_handler("past24Hours")}

          className="md:text-lg text-md font-semibold hover:text-gray-400 ml-1 bg-gray-800 px-2 py-2 "
        >
          Latest
        </button>

        <div className=" flex gap-2 ">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => setsearch_heading(e.target.value)}
            className="px-4 py-2 rounded-lg text-black"
          />
          <button
            className="bg-blue-500  w-full md:w-auto hover:bg-blue-900 text-white md:px-4 px-2 py-2 rounded"
            onClick={search_handler(search_heading)}
          >
            Search
          </button>
        </div>
      </div>
    </nav>
    </div>
  );
};

export default BlogTopSection;
