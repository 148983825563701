import React, { useContext } from "react";
import { Context } from "../../../../../Context_holder";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import img from "./image/7.gif"
import mobimg from "./image/search bn mobile.png"

export default function SearchPage() {
  const {
    collegeName,
    courseName,
    setCollegeName,
    setCourseName,
    setcollege_name,
    setcourse_name,
    searchbar,
    setsearchbar,
    setMobilnav,
  } = useContext(Context);

  const handleSearch = () => {
    setcollege_name(collegeName);
    setcourse_name(courseName);

    navigater("/allUniversity");

    setsearchbar(false);

    setMobilnav(false);
  };
  const navigater = useNavigate();

  return (
    <>
      <div
        className={`flex items-center p-4 fixed w-full h-screen overflow-hidden bg-[#18092f] z-20 top-0 ${
          searchbar ? "left-0" : "left-[-100%]"
        } duration-300`}

      >
     
        <div className="w-full mt-[-5rem]">
       < div className="w-full h-[40vh]">
  <img
    src={img} // Default image (for larger screens)
    alt="internet connection slow image not found"
    className="w-full h-full object-contain object-cover"
    srcSet={`${mobimg} 640w, ${img} 1280w`} // Use mobileImg for screens smaller than 640px
    sizes="(max-width: 640px) 100vw, 50vw" // Adjust size for responsiveness
  />
</div>
        
        
        <div
          className="relative border border-gray-300 bg-white md:rounded-full w-full sm:w-[80%] 
          md:w-[70%] lg:w-[60%] mx-auto py-4 px-4 sm:px-7"
        >
          <div className="flex flex-wrap gap-4 ">
            <div className="flex-1 flex relative  shadow-xl md:shadow-sm">
              <input
                type="text"
               
                value={collegeName}
                onChange={(e) => setCollegeName(e.target.value)}
                placeholder="Enter College Name"
                className="p-2 flex-1 focus:outline-none md:rounded-l-full rounded-xl  w-full"
              />
              <div className="absolute inset-y-0 right-0 flex items-center border-2">
                <div className=" h-full"></div>
              </div>
            </div>

            <input
              type="text"
           
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              placeholder="Enter Course Name"
              className="p-2 flex-1 focus:outline-none rounded-r-full w-full shadow-xl md:shadow-sm"
            />

            <button
              onClick={handleSearch}
              className="py-2 px-4 sm:px-5 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition w-full sm:w-auto"
            >
              Search
            </button>
          </div>
        </div>
        </div>
        <button onClick={() => setsearchbar(false)}>
          <IoCloseSharp className="absolute right-5 text-white text-3xl font-bold top-3 "  />
        </button>
      </div>
    </>
  );
}
