import React from "react";
import { useState } from "react";

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <>
      <section className=" text-white  bg-[#18092f]  w-full py-12 pb-[6rem]">
        <div className="container mx-auto">
          <p className="font-bold text-white text-4xl text-center py-5">
            Let`s clear some doubts
          </p>
          <div className="grid  gap-2 rounded-md ">
            {/* right section start */}
            <div className="col-span-2 px-2">
              {/* 1st ques start */}
              <div
                className={`border-2 border-[#7D7C7C] p-4 mb-3 flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(0)}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-sm md:text-xl">
                  How doescollege world benefit higher education institutions?
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex === 0 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 0 && (
                <div className="p-2 border mb-3 border-[#7D7C7C]">
                  {/* Content for the 1st question */}
                  <ul className="list-disc px-6 font-light text-sm md:text-xl">
                    <li>
                     college world simplifies the assessment and accreditation
                      process by providing a centralized platform for
                      institutions to submit data, track progress, and receive
                      feedback. This helps institutions improve their quality
                      standards and maintain accreditation status more
                      efficiently.
                    </li>
                  </ul>
                </div>
              )}

              {/* 1st ques end */}
              {/* 2nd ques start */}
              <div
                className={`border border-[#7D7C7C] p-4 mb-3 flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(1)}
              >
                <span className="hover:text-[#1BCCBA] text-white font-bold text-sm md:text-xl">
                  Iscollege world available for both undergraduate (UG) and
                  postgraduate (PG) institutions?
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex === 1 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 1 && (
                <div className="p-4 border mb-3 border-[#7D7C7C]">
                  {/* Content for the 2nd question */}
                  <ul className="list-disc px-6 font-light text-sm md:text-xl">
                    <li>
                      Yes,college world caters to both undergraduate and
                      postgraduate institutions, providing tailored assessment
                      and accreditation frameworks for each level of education.
                    </li>
                  </ul>
                </div>
              )}
              {/* 2nd ques end */}
              {/* 3rd ques start */}

              <div
                className={`border border-[#7D7C7C] p-4 mb-3 flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(2)}
              >
                <span className="hover:text-[#1BCCBA] text-white font-bold text-sm md:text-xl">
                  How can institutions accesscollege world?
                </span>
                <span className="text-white ml-auto">
                  {activeIndex === 2 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 2 && (
                <div className="p-4 border mb-3 border-[#7D7C7C]">
                  {/* Content for the 3rd question */}
                  <ul className="list-disc px-6 font-light text-sm md:text-xl">
                    <li>
                      Institutions can accesscollege world through a designated web
                      portal or platform provided by the relevant accrediting
                      agency or regulatory body in their region.
                    </li>
                  </ul>
                </div>
              )}
              {/* 3rd ques end */}
              {/* 4th ques start */}

              <div
                className={`border border-[#7D7C7C] p-4 mb-3 flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(3)}
              >
                <span className="hover:text-[#1BCCBA] text-white font-bold text-sm md:text-xl">
                  How doescollege world contribute to the overall quality of higher
                  education?
                </span>
                <span className="text-white ml-auto">
                  {activeIndex === 3 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 3 && (
                <div className="p-4 border mb-3 border-[#7D7C7C]">
                  {/* Content for the 4th question */}
                  <ul className="list-disc px-6 font-light text-sm md:text-xl">
                    <li>
                     college world promotes continuous improvement in higher
                      education institutions by encouraging self-assessment,
                      benchmarking against quality standards, and fostering a
                      culture of accountability and transparency in the
                      education sector.
                    </li>
                  </ul>
                </div>
              )}
              {/* 4th ques end */}
              {/* 5th ques start */}

              <div
                className={`border border-[#7D7C7C] p-4 mb-3  flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(4)}
              >
                <span className="hover:font-bold text-white font-bold text-sm md:text-xl">
                  Cancollege world help institutions identify areas for improvement?
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex === 4 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 4 && (
                <div className="p-4 border mb-3-2 border-[#313131]">
                  {/* Content for the 5th question */}
                  <ul className="list-disc px-6 font-light text-sm md:text-xl">
                    <li>
                    Yes,college world provides mechanisms for institutions to analyze their performance, identify strengths and weaknesses, and implement targeted improvement strategies based on assessment data and feedback received through the platform.
                    </li>
                  </ul>
                </div>
              )}
              {/* 5th ques end */}
            </div>
            {/* right section end */}
          </div>
        </div>
        {/* second section end */}
      </section>
    </>
  );
}

export default Faq;
