import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { TiTick } from "react-icons/ti";
import AnimatedText from "../../extra/animation-charctor";
import StarRatings from "react-star-ratings";
import "../../animateBounce/bounce.css";
import Typewriter from "typewriter-effect";

const Courses = ({ collegeDetails }) => {
  const [datanum, setDatanum] = useState(3);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const handleIncrement = () => {
    setDatanum((prev) => prev + 3);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center  
     px-2 lg:px-0 bg-[#18092f] py-5 pt-12 ">
      <p className="font-bold text-[2rem] text-whit mb-2">
        <Typewriter
          options={{
            strings: [
              "Explore Parul University Courses",
              "Discover Your Future with Us",
              "Join Our Learning Community",
            ],
            autoStart: true,
            loop: true,
            delay: 50,
            typeSpeed: 100, // Speed at which characters are typed
            deleteSpeed: 50, // Speed at which characters are deleted
            pauseFor: 1500, // Pause between strings
            cursor: "|", // Customize cursor character
          }}
        />
      </p>

      <div className=" w-[100%] container mx-auto flex flex-col p-2 items-center rounded-lg hover:bg-black">
        {collegeDetails?.courses?.slice(0, datanum).map((course, index) => (
          <div
            key={index}
            className="w-full border border-blue-300 md:w-[100%] flex flex-wrap md:flex-nowrap mb-4 bg-[#edf2f]  shadow-xl
            rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out bg-[#18092f] "
            data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
          >
           

            <div
              className="w-full md:w-48 flex justify-center rounded-t-lg md:rounded-none shadow-xl relative"
              data-aos="fade-down"
            >
              <img
                className="object-cover rounded-t-lg md:rounded-none"
                src={`${process.env.REACT_APP_API_BASE_URL}image/course_image/${course.course_image}`}
                alt={course.courseName}
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.8)" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  border: "5px solid rgba(0, 0, 0, 0.3)",
                  borderRadius: "inherit", // Match the border radius of the image
                  pointerEvents: "none",
                }}
              />
            </div>

            <div className="w-full md:w-[calc(100%-12rem)] flex flex-col p-4 bg-[#18092f] text-white">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm font-semibold">
                <div className="  text-white  rounded-md shadow-md">
                  {/* Enhanced Table Styling  bg-[#edf2fa]*/}
                  <table className="w-full text-left border-collapse">
                    <tbody>
                      <tr className="border bg-[#18092f]  border-red-300 px-2 py-1">
                        <td className="font-bold text-yellow-400 border-r border-white pl-2 py-[0.3] pr-2">
                          Course Name:
                        </td>
                        <td className="pl-2"> {course.courseName}</td>
                      </tr>
                      <tr className="border border-white px-2 py-1 border-red-300">
                        <td className="font-bold text-yellow-400 border-r border-white pl-2 py-[0.3] pr-2">
                          Fees:
                        </td>
                        <td className="pl-2">{course.fees} / Year</td>
                      </tr>
                      <tr className="border border-white px-2 py-1 border-red-300">
                        <td className="font-bold text-yellow-400 border-r border-white pl-2 py-[0.3] pr-2">
                          Duration:
                        </td>
                        <td className="pl-2">{course.duration} Years</td>
                      </tr>
                      <tr className="border border-white px-2 py-1 border-red-300">
                        <td className="font-bold text-yellow-400 border-r border-white pl-2 py-[0.3] pr-2">
                          Mode:
                        </td>
                        <td className="pl-2">{course.mode}</td>
                      </tr>
                      <tr className="border border-white px-2 py-1 border-red-300">
                        <td className="font-bold text-yellow-400 border-r border-white pl-2 py-[0.3] pr-2">
                          Time:
                        </td>
                        <td className="pl-2">{course.time}</td>
                      </tr>
                      <tr className="border border-white px-2 py-1 border-red-300">
                        <td className="font-bold text-yellow-400 border-r border-white pl-2 py-[0.3] pr-2">
                          Scholarship:
                        </td>
                        <td className="pl-2">{course.scholarship}</td>
                      </tr>
                      <tr className="border border-white px-2 py-1 border-red-300">
                        <td className="font-bold text-yellow-400 border-r border-white pl-2 py-[0.3] pr-2">
                          Specialisation:
                        </td>
                        <td className="pl-2">
                          {course?.specialisation?.join(", ")}
                        </td>
                      </tr>
                      <tr className="border border-white px-2 py-1 border-red-300">
                        <td className="font-bold text-yellow-400 border-r border-white pl-2 py-[0.3] pr-2">
                          Approved By:
                        </td>
                        <td className="pl-2">{course?.approved?.join(", ")}</td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
                <div className="p-4  bg-white text-black rounded-md shadow-md">
                  {/* Ratings and Additional Info */}
                  <div className="flex gap-2">
                    <StarRatings
                      rating={course.avgCourseRating ?? 0}
                      starRatedColor="gold"
                      numberOfStars={5}
                      starDimension="22px"
                      starSpacing="3px"
                      readonly
                    />
                    <p className=" text-[18px] mt-1 font-medium text-blue-600">
                      {course.rating} out of 5
                    </p>
                  </div>

                  <div className="text-sm font-semibold mt-4">
                    <div className="flex items-center text-black">
                      <div className="bg-black rounded-md mr-2">
                        <TiTick className="text-green-800" />
                      </div>
                      <div className="text-yellow-400">
                        Approved by{" "}
                        <span className="uppercase text-black">
                          {course?.approved?.join(", ")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    className="px-2.5 py-1.5 w-[150px] bg-gradient-to-r from-orange-500 to-red-600 
                    rounded-lg text-white font-bold shadow-md hover:shadow-xl transition-shadow duration-200 mt-4"
                  >
                    <AnimatedText course_name={course?.courseName} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        {datanum < (collegeDetails?.courses?.length ?? 0) && (
          <button
            onClick={handleIncrement}
            type="button"
            className="text-black bg-white hover:bg-blue-800 focus:ring-4
             focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2
              mb-2 shadow-md hover:shadow-lg transition-shadow duration-200"
          >
            View All
          </button>
        )}
      </div>
    </div>
  );
};

export default Courses;

// import React, { useState, useEffect } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { TiTick } from "react-icons/ti";
// import AnimatedText from "../../extra/animation-charctor";
// import StarRatings from "react-star-ratings";

// const Courses = ({ collegeDetails }) => {
//   const [datanum, setDatanum] = useState(3);

//   useEffect(() => {
//     AOS.init({
//       duration: 1000,
//       offset: 200,
//     });
//   }, []);

//   const handleIncrement = () => {
//     setDatanum((prev) => prev + 3);
//   };

//   return (
//     <div className="w-full px-2 lg:px-0 bg-[#18092f] py-5 pt-12">
//       <p className="font-bold text-[2rem] text-center mb-8 text-white">
//         Explore Parul University Courses
//       </p>
//       <div className="container mx-auto flex flex-col p-2 items-center rounded-lg  ">
//         {collegeDetails?.courses?.slice(0, datanum).map((course, index) => (
//           <div
//             key={index}
//             className="w-full md:w-[98%] flex flex-wrap md:flex-nowrap mb-4 bg-[#edf2fa]
//             rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
//             data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
//           >
//             <div
//               className="w-full md:w-48 flex justify-center rounded-t-lg md:rounded-none shadow-md "
//               data-aos="fade-down"
//             >
//               <img
//                 className="object-cover rounded-t-lg md:rounded-none"
//                 src={`${process.env.REACT_APP_API_BASE_URL}image/course_image/${course.course_image}`}
//                 alt={course.courseName}
//                 style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
//               />
//             </div>
//             <div
//               className="flex flex-col justify-start p-4 leading-normal w-full bg-[#18092f] shadow-xl  text-white"
//               data-aos="fade-up"
//             >
//               <div className="w-full">
//                 <div className="flex  gap-8 bg-white rounded-md  border-2 items-center mb-2">
//                   <h2 className="text-lg font-bold tracking-tight text-pink-700 bg-white shadow-lg px-2 rounded-lg">
//                     {course.courseName}
//                   </h2>
//                   <h2 className="text-lg font-bold  text-yellow-500  ">
//                     {course.fees} / Year
//                   </h2>
//                 </div>
//                 <div className="text-sm font-semibold flex flex-wrap gap-4 mb-2 text-gray-700">
//                   <span className="text-yellow-400 ">Course Details :</span>
//                   <div className="flex items-center gap-2 text-white-600 text-white">
//                     <p>{course.duration} Years</p>
//                     <p>{course.mode}</p>
//                     <p>{course.time}</p>
//                   </div>
//                 </div>
//                 <div className="flex items-center mb-2">
//                   <StarRatings
//                     rating={course.avgCourseRating ?? 0}
//                     starRatedColor="gold"
//                     numberOfStars={5}
//                     starDimension="22px"
//                     starSpacing="3px"
//                     readonly
//                   />
//                   <p className="ms-1 text-[15px] font-medium text-blue-600">
//                     {course.rating} out of 5
//                   </p>
//                 </div>
//                 <div className="text-sm font-semibold flex flex-wrap gap-4 mb-2">
//                   <span className="text-yellow-400">Scholarship:</span>
//                   <div className="text-white-600">{course.scholarship}</div>
//                 </div>
//                 <div className="text-sm font-semibold flex flex-wrap gap-4 mb-2">
//                   <div className="flex flex-wrap gap-2 text-white-600">
//                     <span className=" font-bold text-yellow-400">
//                       Specialisation :
//                     </span>
//                     {course?.specialisation?.map((spec, i) => (
//                       <p key={i}>
//                         {spec}
//                         {i < course.specialisation.length - 1 ? ", " : ""}
//                       </p>
//                     ))}
//                   </div>
//                 </div>
//                 <div className="text-sm font-semibold flex gap-4 mb-2">
//                   <div className="flex items-center text-white-600">
//                     <div className="bg-white rounded-md mr-2">
//                       <TiTick className="text-green-800" />
//                     </div>
//                     <div className="text-yellow-400">
//                       Approved by{" "}
//                       <span className="uppercase text-white">
//                         {course?.approved?.map((aprovedData, index) => {
//                           return <span> {aprovedData}</span>;
//                         })}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <p className="mb-3 text-sm text-gray-600 text-left">
//                   {course.description}
//                 </p>
//                 <button
//                   className="px-2.5 py-1.5 w-[150px] bg-gradient-to-r from-orange-500 to-red-600
//                 rounded-lg text-white font-bold shadow-md hover:shadow-xl transition-shadow duration-200"
//                 >
//                   <AnimatedText />
//                 </button>
//               </div>
//             </div>
//           </div>
//         ))}
//         {datanum < (collegeDetails?.courses?.length ?? 0) && (
//           <button
//             onClick={handleIncrement}
//             type="button"
//             className="text-white bg-[#6D28D9] hover:bg-blue-800 focus:ring-4
//              focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2
//               mb-2 shadow-md hover:shadow-lg transition-shadow duration-200"
//           >
//             View All
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Courses;
