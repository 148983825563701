import React from 'react';
import './index.css';

export default function StudentsPlaced({ placed_students }) {
  return (
    <div className="slider">
      <div className="slide-track">
        {placed_students?.map((data) => (

          <div className="slide" key={data._id}>
            <img
              src={
                process.env.REACT_APP_API_IMAGE_URL +
                'students_placed_image/' +
                data?.student_image
              }
                loading="lazy"
              alt="student"
            />
            <div className=' capitalize'>{data?.name}</div>
            <div className="company capitalize">{data?.company}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
