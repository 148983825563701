import React, { useEffect } from 'react';
// import 'aos/dist/aos.css';
// import AOS from 'aos';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonials = [
  {
    id: 1,
    name: 'John Doe',
    designation: 'Graduate Student',
    text: "I am immensely grateful to have found [Education Course Provider]'s website. Their dedication to providing top-notch educational courses is unparalleled.",
  },
  {
    id: 2,
    name: 'Jane Smith',
    designation: 'Undergraduate Student',
    text: "[Education Course Provider] has been instrumental in my academic journey. The courses are well-structured, and the mentors are extremely knowledgeable.",
  },
  {
    id: 3,
    name: 'Michael Brown',
    designation: 'PhD Candidate',
    text: "Enrolling in [Education Course Provider]'s programs was one of the best decisions I've made. The courses are rigorous yet rewarding.",
  },
  {
    id: 4,
    name: 'Emily Johnson',
    designation: 'High School Student',
    text: "As a high school student, I was looking for opportunities to expand my knowledge beyond the traditional curriculum. [Education Course Provider] offered exactly what I needed with their engaging courses and dedicated mentors.",
  },
  {
    id: 5,
    name: 'David Wilson',
    designation: 'Career Professional',
    text: "I have been able to advance my career significantly thanks to [Education Course Provider]. The practical skills I've gained from their courses have been invaluable in my professional life.",
  },
];

const WebTestimonial = () => {
  useEffect(() => {

  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full flex justify-center items-center md:py-8 py-5">
      <div className="w-[90%]">
        <h2 className="md:text-2xl text-xl font-semibold text-[#00c9a7] md:mb-8 mb-2 text-center">Testimonials</h2>
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="p-2 w-[29%]">
              <div className="border rounded-lg shadow-lg px-2 w-full md:py-[2rem] px-4 md:border-gray-300 border-2" >
                <p className="text-lg text-[#b2c0d8] md:h-[10rem]">{testimonial.text}</p>
                <p className="text-sm text-[#b2c0d8] mt-4">{`- ${testimonial.name}, ${testimonial.designation}`}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default WebTestimonial;
