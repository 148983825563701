import { useContext, useEffect, useState } from "react";
import { Context } from "../../../../Context_holder";
import { FaUser, FaBook, FaCreditCard, FaGift, FaBriefcase, FaUsers, FaQuestionCircle, FaRegFileAlt, FaCommentDots, FaSignOutAlt, FaTrashAlt,FaEdit, FaEnvelope, FaPhone, FaMapMarkerAlt, FaMale, FaFemale ,FaHome } from 'react-icons/fa';
import axios from "axios";
import Select from 'react-select';
import { Link, useNavigate } from "react-router-dom";

const states = require("../../../../Json_files/States.json");
const cities = require("../../../../Json_files/Cities.json");




export default function StudentProfile() {
const{user,course_fetch,course,selected_city,selected_state,setselected_city,search_cities,setselected_state,setsearch_cities,notify,setuser,setusertoken,userlogout_handler}=useContext(Context)

const[selected_course,setselected_course]= useState(null)

const navigator= useNavigate()


useEffect(
  ()=>{
    course_fetch()
  },
  []
)

useEffect(() => {
  const search_city = cities.filter(data => data.state === selected_state?.value);
  setsearch_cities(search_city);
}, [selected_state]);


const uniqueCourses = course.filter((course, index, self) =>
  index === self.findIndex((c) => c.courseName === course.courseName)
);
const [isEditOpen, setIsEditOpen] = useState(false);

const handleEditClick = () => {
  setIsEditOpen(true);
};

const handleCloseClick = () => {
  setIsEditOpen(false);
};


const edit_handler = (e) => {
  e.preventDefault();

  // Access input values
  const name = e.target.name.value;
  const email = e.target.email.value;
  const contact = e.target.contact.value;
  const birth = e.target.birth.value;
  const gender = e.target.gender.value;
  const state = selected_state?.value;
  const city = selected_city?.value;
  const course = selected_course?.label;

  // Log the selected values for debugging
 

  // Construct the data object
  const data = {
    name: name,
    email: email,
    contact: contact,
    birth: birth,
    gender: gender,
    state: state,
    city: city,
    course: course,
  };

 
  

  
  axios
    .put(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_USER_URL}edit/${user?._id}`,
      data
    )
    .then((response) => {
      // Log the success response
      notify(response.data.msg,response.data.status)

      if (response.data.status === 1) {
        axios.post( process.env.REACT_APP_API_BASE_URL+ process.env.REACT_APP_USER_URL+"login",{email:email})


        .then(
          (succes)=>{

            if(succes.data.status==1){

            
              setuser(succes.data.user)
              setusertoken(succes.data.token)
              localStorage.setItem("user",JSON.stringify(succes.data.user)) 
              localStorage.setItem("usertoken",succes.data.token)
  
            }

          }
        )
        .catch(
          (error)=>{

          }
        )
      
      

         


        setIsEditOpen(false);
        setselected_state(null)
        setselected_course(null)
        setselected_city( null)


      }
    })
    .catch((error) => {
      // Handle errors
      console.error("Error updating user:", error);
    });
};


const student_logout_handler=()=>{

  userlogout_handler()
  navigator("/")

}


const user_account_delete=()=>{
  axios.delete( process.env.REACT_APP_API_BASE_URL+ process.env.REACT_APP_USER_URL+"delete/"+user?._id)
    .then((response) => {
      // Log the success response
      notify(response.data.msg,response.data.status)

      if (response.data.status === 1) {
      
            
        localStorage.removeItem("user");
        localStorage.removeItem("usertoken");
    
        setuser(null);
        setusertoken(null);
        navigator("/")
  
      }

          }
        )
        .catch(
          (error)=>{

          }
        )
      
  

      }
    
      const [currentIndex, setCurrentIndex] = useState(0);
  
      const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
    
      const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      };
    
      const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? items.length - 1 : prevIndex - 1
        );
      };


const sidebarItems = [
  {
    label: 'Home',        // Text label for the button
    icon: "FaHome",         // React icon component for the Home icon
    isBold: true  ,
    url:"/"        // Boolean to determine if the text should be bold
  },

  { label: 'Profile Details', icon: 'FaUser',isBold: true },
 


  // { label: 'Enrollment Details', icon: 'FaBook' },
  // { label: 'Best EMI Options', icon: 'FaCreditCard' },
  // { label: 'Exciting Offers', icon: 'FaGift' },
  // { label: 'Internships & Job Offers', icon: 'FaBriefcase' },
  // { label: 'Community & Batchmates', icon: 'FaUsers' },
  // { label: 'Q&A Panel', icon: 'FaQuestionCircle' },
  // { label: 'Make Your CV (AI Driven)', icon: 'FaRegFileAlt' },
  // { label: 'Feedback & Support', icon: 'FaCommentDots' },
  
];

const iconMapping = {
  FaHome:FaHome,
  FaUser: FaUser,
  
  // FaBook: FaBook,
  // FaCreditCard: FaCreditCard,
  // FaGift: FaGift,
  // FaBriefcase: FaBriefcase,
  // FaUsers: FaUsers,
  // FaQuestionCircle: FaQuestionCircle,
  // FaRegFileAlt: FaRegFileAlt,
  // FaCommentDots: FaCommentDots,
  
};

    return ( 
    


      <>
     
  
      <div className="flex w-full min-h-screen bg-gray-100">
        {/* Sidebar */}
        <aside className="w-[250px] lg:block hidden h-screen bg-gradient-to-b from-[#002147] to-[#004e92] shadow-lg text-white overflow-y-auto">
          <nav className="p-5">
            <ul>
              {sidebarItems?.map((data, index) => {
                const IconComponent = iconMapping[data.icon];
                return (
                  <li key={index} className="mb-4 group">
                    <button className="flex items-center p-3 rounded-lg group-hover:bg-[#fdc800] group-hover:text-[#002147] transition-colors duration-300">
                      {IconComponent && <IconComponent className="text-2xl text-[#fdc800] group-hover:text-[#002147]" />}
                      <span className={`ml-3 ${data.isBold ? "font-bold" : "font-normal"} ${data.isBold ? "text-xl" : "text-md"}`}>{data.label}</span>
                    </button>
                  </li>
                );
              })}
              <li className="mb-4 group" onClick={student_logout_handler}>
                <button className="flex items-center p-3 rounded-lg group-hover:bg-[#fdc800] group-hover:text-[#002147] transition-colors duration-300">
                  <FaSignOutAlt className="text-2xl text-[#fdc800] group-hover:text-[#002147]" />
                  <span className="ml-3">Log Out</span>
                </button>
              </li>
              <li className="mb-4 group" onClick={user_account_delete}>
                <button className="flex items-center p-3 rounded-lg group-hover:bg-[#fdc800] group-hover:text-[#002147] transition-colors duration-300">
                  <FaTrashAlt className="text-2xl text-[#fdc800] group-hover:text-[#002147]" />
                  <span className="ml-3">Delete Account</span>
                </button>
              </li>
            </ul>
          </nav>
        </aside>

        {/* Profile Details */}
        <div className="flex-1 h-screen capitalize p-6 sm:p-10 bg-[#f0f4f8] overflow-y-auto">
       
       {/* menu headbar */}


       <div className="w-full py-2 lg:hidden block overflow-x-auto scrollbar-thin scrollbar-thumb-yellow-500 scrollbar-track-blue-900 bg-gradient-to-r to-[#002147] from-[#004e92] text-[#fdc800] px-4 sm:px-6 shadow-lg mb-6">
  <ul className="flex space-x-4 whitespace-nowrap">
    {sidebarItems?.map((data, index) => {
      const IconComponent = iconMapping[data.icon];
      return (
        <Link to={data.url} key={index} className="flex-shrink-0 group" >
          <button className="flex items-center p-3 rounded-lg text-white group-hover:bg-[#fdc800] group-hover:text-[#002147] transition-colors duration-300">
            {IconComponent && (
              <IconComponent className="text-2xl text-[#fdc800] group-hover:text-[#002147]" />
            )}
            <span
              className={`ml-3 ${data.isBold ? "font-bold text-lg" : "font-normal text-sm"} truncate`}
            >
              {data.label}
            </span>
          </button>
        </Link >
      );
    })}
    <li className="group" onClick={student_logout_handler}>
      <button className="flex items-center p-3 rounded-lg text-white group-hover:bg-[#fdc800] group-hover:text-[#002147] transition-colors duration-300">
        <FaSignOutAlt className="text-2xl text-[#fdc800] group-hover:text-[#002147]" />
        <span className="ml-3">Log Out</span>
      </button>
    </li>
    <li className="group" onClick={user_account_delete}>
      <button className="flex items-center p-3 text-white rounded-lg group-hover:bg-[#fdc800] group-hover:text-[#002147] transition-colors duration-300">
        <FaTrashAlt className="text-2xl text-[#fdc800] group-hover:text-[#002147]" />
        <span className="ml-3">Delete Account</span>
      </button>
    </li>
  </ul>
</div>



{/* main content */}

          <div className="bg-gradient-to-r from-[#002147] to-[#004e92] text-[#fdc800] p-4 sm:p-6 rounded-lg shadow-lg mb-6">
            <h1 className="text-xl sm:text-2xl md:text-3xl font-extrabold">{user?.name}</h1>
            <p className="text-sm sm:text-md md:text-lg mt-2">Welcome to College World</p>
          </div>



          <div className="bg-[#002147] p-4 sm:p-6 rounded-lg shadow-lg border border-[#004e92] bg-gradient-to-br from-[#002147] to-[#004e92]">
            <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4 sm:mb-6 text-[#fdc800]">Contact Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
              {[
                { icon: <FaEnvelope />, label: 'Mail Address', value: user?.email },
                { icon: <FaPhone />, label: 'Mobile Number', value: user?.contact },
                { icon: <FaMale />, label: 'Gender', value: user?.gender },
                { icon: <FaMapMarkerAlt />, label: 'City', value: user?.city },
                { icon: <FaMapMarkerAlt />, label: 'State', value: user?.state },
                { icon: <FaBriefcase />, label: 'Course', value: user?.course },
              ].map((item, index) => (
                <div key={index} className="bg-[#fdc800] p-4 rounded-lg shadow-md flex items-start space-x-3 overflow-hidden">
                  <div className="text-[#002147] text-lg sm:text-xl md:text-2xl">{item.icon}</div>
                  <div className="flex-1">
                    <p className="text-xs sm:text-sm md:text-base text-gray-800 truncate">{item.label}</p>
                    <p className="text-sm sm:text-md md:text-lg font-medium truncate">{item.value}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4 sm:mt-6 text-right">
              <button onClick={handleEditClick} className="bg-[#003a6f] text-[#fdc800] py-2 px-4 sm:px-6 rounded-full shadow-md shadow-[#fdc800] hover:shadow-[#003a6f] hover:bg-[#fdc800] hover:text-[#003a6f] transition-colors duration-300">
                <FaEdit className="inline mr-2" /> Edit
              </button>
            </div>
          </div>

          {isEditOpen && (
            <div className="fixed inset-0 bg-[#002147] bg-opacity-80 flex items-center justify-center p-4">
              <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg border border-[#004e92] bg-gradient-to-br from-[#ffffff] to-[#f9f9f9] relative w-full max-w-2xl mx-auto max-h-[90vh] overflow-auto">
                <button onClick={handleCloseClick} className="absolute top-4 right-4 text-[#002147] text-lg sm:text-xl md:text-2xl">&times;</button>
                <div className="overflow-auto max-h-[80vh]">
                  <form className="space-y-4 sm:space-y-6" onSubmit={edit_handler}>
                    {/* Name and Email Inputs */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
                      <div className="p-4 bg-[#fdc800] rounded-lg shadow-md">
                        <label className="block text-gray-800 text-sm sm:text-md md:text-lg">Name</label>
                        <input
                          type="text"
                          name="name"
                          required
                          className="w-full p-2 mt-2 rounded-md border border-[#004e92] focus:outline-none focus:ring-2 focus:ring-[#fdc800] focus:border-[#004e92]"
                        />
                      </div>
                      <div className="p-4 bg-[#fdc800] rounded-lg shadow-md">
                        <label className="block text-gray-800 text-sm sm:text-md md:text-lg">Mail Address</label>
                        <input
                          type="email"
                          name="email"
                          required
                          className="w-full p-2 mt-2 rounded-md border border-[#004e92] focus:outline-none focus:ring-2 focus:ring-[#fdc800] focus:border-[#004e92]"
                        />
                      </div>
                    </div>

                    {/* Mobile Number and Date of Birth Inputs */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
                      <div className="p-4 bg-[#fdc800] rounded-lg shadow-md">
                        <label className="block text-gray-800 text-sm sm:text-md md:text-lg">Mobile Number</label>
                        <input
                          type="tel"
                          name="contact"
                          required
                          className="w-full p-2 mt-2 rounded-md border border-[#004e92] focus:outline-none focus:ring-2 focus:ring-[#fdc800] focus:border-[#004e92]"
                        />
                      </div>
                      <div className="p-4 bg-[#fdc800] rounded-lg shadow-md">
                        <label className="block text-gray-800 text-sm sm:text-md md:text-lg">Date of Birth</label>
                        <input
                          required
                          type="date"
                          name="birth"
                          className="w-full p-2 mt-2 rounded-md border border-[#004e92] focus:outline-none focus:ring-2 focus:ring-[#fdc800] focus:border-[#004e92]"
                        />
                      </div>
                    </div>

                    {/* Gender Selection */}
                    <div className="p-4 bg-[#fdc800] rounded-lg shadow-md">
                      <span className="block text-gray-800 text-sm sm:text-md md:text-lg mb-2">Gender</span>
                      <div className="flex items-center space-x-4">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            name="gender"
                            value="male"
                            className="form-radio text-blue-600 focus:outline-none focus:ring-2 focus:ring-[#fdc800]"
                          />
                          <span className="ml-2 text-gray-800">Male</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            name="gender"
                            value="female"
                            className="form-radio text-pink-600 focus:outline-none focus:ring-2 focus:ring-[#fdc800]"
                          />
                          <span className="ml-2 text-gray-800">Female</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            name="gender"
                            value="other"
                            className="form-radio text-gray-600 focus:outline-none focus:ring-2 focus:ring-[#fdc800]"
                          />
                          <span className="ml-2 text-gray-800">Other</span>
                        </label>
                      </div>
                    </div>

                    {/* State and City Selection */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
                      <div className="p-4 bg-[#fdc800] rounded-lg shadow-md">
                        <label className="block text-gray-800 text-sm sm:text-md md:text-lg">State</label>
                        <Select
                          value={selected_state}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              padding: '4px 20px',
                              marginTop: '4px',
                            }),
                          }}
                          onChange={(e) => setselected_state(e)}
                          name="states"
                          options={states.map((data) => ({ value: data.name, label: data.name }))}
                        />
                      </div>
                      <div className="p-4 bg-[#fdc800] rounded-lg shadow-md">
                        <label className="block text-gray-800 text-sm sm:text-md md:text-lg">City</label>
                        <Select
                          value={selected_city}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              padding: '4px 20px',
                              marginTop: '4px',
                            }),
                          }}
                          onChange={(e) => setselected_city(e)}
                          name="cities"
                          options={search_cities.map((data) => ({ value: data.name, label: data.name }))}
                        />
                      </div>
                    </div>

                    {/* Course Selection */}
                    <div className="p-4 bg-[#fdc800] rounded-lg shadow-md">
                      <label className="block text-gray-800 text-sm sm:text-md md:text-lg">Course</label>
                      <Select
                        value={selected_course}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            padding: '4px 20px',
                            marginTop: '4px',
                          }),
                        }}
                        onChange={(e) => setselected_course(e)}
                        name="Course_id"
                        options={uniqueCourses?.map((data) => ({ value: data._id, label: data.courseName }))}
                      />
                    </div>

                    {/* Submit Button */}
                    <div className="mt-4 sm:mt-6 text-right">
                      <button
                        type="submit"
                        className="bg-[#004e92] text-[#fdc800] py-2 px-4 sm:px-6 rounded-full shadow-md hover:bg-[#003a6f] transition-colors duration-300"
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>



    
      
     

      
    );
  }
  