





import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import { Context } from '../../../../Context_holder';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function Website_news_add() {

    const{quill_value,setquill_value ,token,notify}=useContext(Context)
    



useEffect(
    ()=>{
        setquill_value(null)
    },
    []
)






   

    const submit_handler = (e)=>{

        e.preventDefault();
        
        
    
     const logo= e.target.logo.files[0]
     const news_media_logo=e.target.news_media_logo.files[0]
    
    
     
    const url = e.target.url.value
    const heading = e.target.heading.value
    
      const formData = new FormData();
    
    
      formData.append('logo', logo);
   
      formData.append('url', url);
      
      formData.append('news_media_logo', news_media_logo);


      formData.append('heading', heading);
    
      
     
    
      axios.post(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_WEBSITE_NEWS_URL+"add",formData,{
        headers: {
          Authorization: token}
      })
      .then((success) => {
        
        notify(success.data.msg,success.data.status)
    
        if (success.data.status === 1) {
         
    
          e.target.reset();
    

    
        }
      })
    
      .catch((error) => {
        console.error('Error:', error);
      });
    
    
    }

    return (
      <div className='min-h-screen py-10 bg-gray-300'>
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
        <div className="text-center text-4xl font-bold mb-3"> News Add For The Website</div>
        <form onSubmit={submit_handler} enctype="multipart/form-data">
        
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
           


            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="logo">
                Image
              </label>
              <input
                type='file'
                id="logo"
                name="logo"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

          
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="news_media_logo">
                News Media Logo
              </label>
              <input
                type='file'
                id="news_media_logo"
                name="news_media_logo"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="url">
                URL
              </label>
              <input
                id="url"
                name="url"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="URL"
              />
            </div>
           
          </div>


         

          <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="heading">
                Heading
              </label>
              <textarea
                id="heading"
                name="heading"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Heading"
              />
            </div>

         


            

            <div className="flex justify-end mb-4 mt-2">
            <button
              type="submit"
              className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
          
        </form>
      </div>
    

      
     
    </div>
    

        )
}

