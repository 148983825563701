import React from "react";
import { FaCheckCircle } from 'react-icons/fa';
import { FaLightbulb } from 'react-icons/fa';

function UniversityFact({ collegeDetails }) {
  console.log(collegeDetails);

  return (
    <div className="w-full">
      <div className="md:max-w-[90%] w-full mx-auto md:p-6  py-6 px-2 rounded-lg">
        <div className="flex items-center mb-4">
          <FaLightbulb className="text-yellow-500 text-2xl md:mr-2" />
          <h2 className="text-2xl font-bold text-[#94d928]">University Interesting Facts</h2>
        </div>
        <ul className="list-disc pl-5">
          {collegeDetails?.facts?.map((data, index) => (
            <li key={index} className="mb-2 text-gray-200 flex items-center">
              <FaCheckCircle className="text-green-500 text-lg mr-2" />
              {data}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default UniversityFact;