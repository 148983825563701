import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import { Link } from 'react-router-dom';

export default function College_view() {

  const{colleges,college_fetch,token,notify }=useContext(Context)
  console.log(colleges,"bhawanishankar sharma")
useEffect(
  ()=>{

   college_fetch()

  
  },[]
)


const delete_handler= (id,logo,campus_images,pdf,office_photo)=>{


  
            
  axios.delete(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COLLEGE_URL}delete/${id}/${logo}/${campus_images}/${pdf}/${office_photo}`,{
    headers: {
      Authorization: token}
  })
  
  .then((success) => {
  
    notify(success.data.msg,success.data.status)

    if (success.data.status === 1) {
      
     college_fetch()


    }

  })

  .catch((error) => {
    console.error('Error:', error);
  });


}

if(colleges?.length!=0){
  return (


    <div className="min-h-screen bg-gray-100 py-6 px-6 flex flex-col justify-center sm:py-3">

    <div className="relative py-3 ">
      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-10">
        <div className="max-w-full mx-auto">
          <h1 className="text-3xl font-bold text-center mb-6">College List</h1>
          <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
  <thead>
    <tr>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Logo</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">College Name</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Established Year</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Affiliated To</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Website</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th>
    </tr>
  </thead>
  <tbody className="bg-white divide-y divide-gray-200">
  
    {colleges?.map((college, index) => (
      
     
      <tr key={index}>
        <td className="px-6 py-4 whitespace-nowrap">
          <img src={process.env.REACT_APP_API_IMAGE_URL + "college_logo/" + college.university_banner[0]} alt="College Logo" className="w-16 h-16 rounded-full" />
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-900">{college.college_name}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{college.estdYear}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm  flex gap-2 text-gray-500"> 
            
           {
            college?.affiliatedTo?.map(
              (data)=>{
return(
  <div>/{data}/</div>
 
)
              }
            )
           }
            
            
            </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{college.type}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <Link to={college.officialWebsite} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
            Visit Website
          </Link>
        </td>
        <td className="px-6 py-8 items-center whitespace-nowrap flex gap-2 ">
          <Link to={`/admin/college/edit/${college._id}`}  className='px-3 py-1 bg-blue-700 hover:bg-blue-900 text-white font-semibold rounded'>Edit</Link>
          <button className='px-3 py-1 font-semibold rounded border shadow-md hover:text-white hover:bg-red-500' onClick={()=>delete_handler(college._id,JSON.stringify(college.university_banner),JSON.stringify(college.campus_images),college.pdf,college.office_photo)}>Delete</button>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <button className='px-3 py-1 font-semibold rounded border  hover:shadow-lg'>View</button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

          </div>
        </div>
      </div>
    </div>
  </div>
 
  )
}
else {
  return(
    <div >Please add colleges</div>
  )
}
  
  
}
