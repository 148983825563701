
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import Select from 'react-select';
import axios from 'axios';
export default function Course_add() {

  const{college_fetch,colleges ,selected_college,setselected_college,token,stream_fetch,stream,selected_stream,setselected_stream,notify}=useContext(Context)



  


  useEffect(
    ()=>{
  
      college_fetch()
      setselected_college(null)
      stream_fetch()
      setselected_stream(null)
    },[]
  )
 


  const handleSubmit = (e) => {
    e.preventDefault();
  
    const college_id = selected_college?.value;
   const stream_id= selected_stream?.value
   
    const courseName = e.target.courseName.value;
    const duration = e.target.duration.value;
    const specialisation = e.target.specialisation.value.split("$").filter(a => a !== "");
    const scholarship = e.target.scholarship.value;
    const mode = e.target.mode.value;
    const time = e.target.time.value;
    const fees = e.target.fees.value;
    const course_image= e.target.course_image.files[0]
    const approved = e.target.approved.value.split("$").filter(a => a !== "");



  
    const formData = new FormData();

formData.append('college_id', college_id);
formData.append("stream_id",stream_id);
formData.append('courseName', courseName);
formData.append('duration', duration);
formData.append('specialisation', JSON.stringify(specialisation));

formData.append('mode', mode);
formData.append('time', time);
formData.append('fees', fees);
formData.append('scholarship', scholarship);
formData.append('course_image', course_image); 
formData.append("approved",JSON.stringify(approved))
 

    axios.post(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COURSE_URL}add`, formData,{
      headers: {
        Authorization: token}
    })
    .then((response) => {
      notify(response.data.msg,response.data.status)

      if (response.data.status === 1) {
        e.target.reset();
       
        setselected_college(null)
        setselected_stream(null)
      }
    })
    .catch((error) => {
       
      
      
      })
     
  };

  return (
    <div className="min-h-screen py-10 bg-gray-300">
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4">Add New Course For Perticuler Collegea</h2>
      <form onSubmit={handleSubmit} className="w-full">
  
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-4">
          <div>
            <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">College ID</label>
            <Select
              value={selected_college}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: '4px 20px',
                  marginTop: '4px',
                }),
              }}
              onChange={(e) => { setselected_college(e) }}
              name="college_id"
              options={colleges?.college?.map(data => ({ value: data._id, label: data.college_name }))}
            />
          </div>

          <div>
            <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">Stream ID</label>
            <Select
              value={selected_stream}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: '4px 20px',
                  marginTop: '4px',
                }),
              }}
              onChange={(e) => { setselected_stream(e) }}
              name="college_id"
              options={stream?.map(data => ({ value: data._id, label: data.stream_name }))}
            />
          </div>

          <div>
            <label htmlFor="courseName" className="block text-sm font-medium text-gray-700">Course Name</label>
            <input
              type="text"
              id="courseName"
              name="courseName"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div>
            <label htmlFor="duration" className="block text-sm font-medium text-gray-700">Duration</label>
            <input
              type="number"
              id="duration"
              name="duration"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
        </div>
  
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
          <div>
            <label htmlFor="specialisation" className="block text-sm font-medium text-gray-700">Specialisation <span className='text-[red]'>($saprated)</span></label>
            <input
              type="text"
              id="specialisation"
              name="specialisation"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
        
          <div>
            <label htmlFor="mode" className="block text-sm font-medium text-gray-700">Mode</label>

            <select
              id="mode"
              name="mode"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">Select Mode</option>
              <option value="On-Campus">On Campus</option>
              <option value="Distance">Distance</option>
              
            </select>

          </div>


          <div>
            <label htmlFor="time" className="block text-sm font-medium text-gray-700">Time</label>
            <select
              id="time"
              name="time"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">Select Time</option>
              <option value="Full Time">Full-Time</option>
              <option value="Part Time">Part-Time</option>
              <option value="Distence">Distence</option>
            </select>
          </div>

          
          <div>
            <label htmlFor="" className="block text-sm font-medium text-gray-700">Approved <span className='text-[red]'>($saprated)</span></label>
            <input
              type="text"
              id="approved"
              name="approved"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>



          <div>
            <label htmlFor="scholarship" className="block text-sm font-medium text-gray-700">Scholarship</label>
            <input
              type="text"
              id="scholarship"
              name="scholarship"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>

          <div>
            <label htmlFor="fees" className="block text-sm font-medium text-gray-700">Fees</label>
            <input
              type="number"
              id="fees"
              name="fees"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>

          <div>
            <label htmlFor="" className="block text-sm font-medium text-gray-700">Image</label>
            <input
              type="file"
              id="course_image"
              name="course_image"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>

          
        </div>
  
        <div className="flex justify-end mb-4">
          <button
            type="submit"
            className="bg-blue-500 w-full md:w-auto hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add Course
          </button>
        </div>
      </form>
    </div>
  </div>
  
  );
}

