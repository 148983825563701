import React, { useContext, useEffect, useState } from 'react'
import axios from "axios"
import { Link, useNavigate } from 'react-router-dom'
import { Context } from '../../../../Context_holder'
import { IoClose } from "react-icons/io5";

export default function UserLoginPopup() {
  const { setusertoken, setuser, userLogin_popup, setuserLogin_popup, notify, setuserSignUp_popup } = useContext(Context)

  const [email, setemail] = useState("")
  const [otp, setotp] = useState("")
  const [otpsection, setotpsection] = useState(false)
  const [seconds, setSeconds] = useState(60)
  const [isActive, setIsActive] = useState(false)
  const [message, setmessage] = useState("")
  
  const navigator = useNavigate()

  const submit_login_handler = () => {
    if (email !== "") {
     

      axios.post(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_OTP_URL + "send", { email})
      
        .then((success) => {
          notify(success.data.msg, success.data.status)
          if (success.data.status === 1) {

            setotpsection(true)
            startCountdown()

          }
        })
        .catch((error) => {

          // Handle error

        })
    } else {
      
      setmessage("Please enter the email")
    }
  }

  useEffect(() => {
    let timer;
    if (isActive && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      setSeconds(60)
    }
    return () => clearInterval(timer);
  }, [isActive, seconds]);

  const startCountdown = () => {
    setIsActive(true);
  }

  const OtpConfirm_handler = () => {

    axios.post(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_OTP_URL + "verify", { email, otp })
      .then((success) => {
        notify(success.data.msg, success.data.status)
        if (success.data.status === 1) {

          axios.post(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_USER_URL + "login", { email })
            .then((success) => {
              notify(success.data.msg, success.data.status)
              if (success.data.status === 1) {
                setuserLogin_popup(false)
                setuser(success.data.user)
                setusertoken(success.data.token)
                localStorage.setItem("user", JSON.stringify(success.data.user)) 
                localStorage.setItem("usertoken", success.data.token)
              } else {
                reset_handler()
                setuserSignUp_popup(true)
                setuserLogin_popup(false)
              }
            })
            .catch((error) => {
              // Handle error
            })
        }
      })
      .catch((error) => {
        // Handle error
      })
  }

  const reset_handler = () => {
    setIsActive(false)
    setotpsection(false)
    setemail("")
    setotp("")
    setSeconds(60)
  }

  const sign_up_handler = () => {
    setuserLogin_popup(false)
    setuserSignUp_popup(true)
    setotp("")
  }

  return (
    <div className={`h-[100%] flex items-center justify-center py-10 ${userLogin_popup ? "top-0" : "top-[-140%]"} z-20 duration-300 fixed top-0 left-0 w-full bg-opacity-90 bg-[#002147]`}>
      <button className='absolute right-5 top-4 text-[#fdc800]' onClick={() => setuserLogin_popup(false)}>
        <IoClose className='text-3xl' />
      </button>

      <div className='w-full max-w-lg px-4'>
        <div className='flex justify-center mb-5'>
          <Link to="/" onClick={() => setuserLogin_popup(false)} className="flex items-center">
            <h1 className="text-[#fdc800] lg:hover:scale-110 duration-300 font-bold text-[24px] sm:text-[30px]">AAOPADHE</h1>
          </Link>
        </div>

        <div className="bg-white p-8 rounded-md shadow-lg border-4 border-[#fdc800] w-full">
          <div className='flex justify-center'>
            <i className="fa-solid fa-user text-6xl text-[#002147] mb-3"></i>
          </div>
          <h2 className="text-xl font-semibold text-[#002147] mb-6 text-center uppercase">User Login</h2>

          <button className={`text-[16px] font-semibold text-[#fdc800] mb-8 ${!otpsection ? "hidden" : "block"}`} onClick={reset_handler}>
            Change The Email
          </button>

          <div className={`mb-4 ${otpsection ? "hidden" : "block"}`}>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              onChange={(e) => setemail(e.target.value)}
              type="email"
              id="email"
              value={email}
              name="email"
              placeholder='Enter Email'
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <span className='text-[red]'>{message}</span>
          </div>

          <div className={`mb-4 ${!otpsection ? "hidden" : "block"}`}>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="otp">
              OTP
            </label>
            <input
              type="number"
              id="otp"
              value={otp}
              maxLength={6}
              onChange={(e) => setotp(e.target.value)}
              name="otp"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <button
            className={`bg-[#002147] w-full ${otpsection ? "hidden" : "block"} hover:bg-[#fdc800] text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
            onClick={submit_login_handler}
          >
            Get OTP
          </button>

          <button
            className={`bg-[#002147] w-full ${!otpsection ? "hidden" : "block"} hover:bg-[#fdc800] text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
            onClick={OtpConfirm_handler}
          >
            Confirm OTP
          </button>

          <div className={`${!isActive ? "hidden" : "block"} text-gray-500 mt-2 capitalize`}>
            You can resend OTP after <span className='text-[#002147] font-semibold text-[16px]'>0.{seconds} sec</span>
          </div>

          <div className={`${!otpsection || isActive ? "hidden" : "block"} text-gray-500 capitalize`}>
            Didn't receive the OTP yet? <button className='text-[#002147] mt-2 font-bold' onClick={submit_login_handler}> RESEND </button>
          </div>

          <div onClick={sign_up_handler}>
            Don’t have an account? <button className='text-[#002147] mt-4 font-bold'>Sign up</button>
          </div>
        </div>
      </div>
    </div>
  )
}
