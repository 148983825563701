import React, { useContext } from "react";
import "./index.css";
import { Context } from "../../../../../Context_holder";
import axios from "axios";

const AnimatedText = ({ course_name }) => {

  const { setapply_popUpIsOpen, user, setuserSignUp_popup } =
    useContext(Context);


  const enquiry_api = () => {
    if (user) {
      axios
        .patch(
          process.env.REACT_APP_API_BASE_URL +
            process.env.REACT_APP_USER_URL +
            "course_edit/" +
            user?._id +
            "/" +
            course_name
        )
        .then((success) => {
          if (success.data.status === 1) {
            setapply_popUpIsOpen(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setuserSignUp_popup(true);
    }
  };

  return (
    <div target="_blank" onClick={enquiry_api}>
      <div className="container pointer">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="animate-charcter">Apply now</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedText;
