import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import 'aos/dist/aos.css';
// import AOS from 'aos';


// Custom Arrow components
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrow slick-next`}
      style={{ ...style, display: "block", background: "#333", borderRadius: '50%' }}
      onClick={onClick}
    >
      ›
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrow slick-prev`}
      style={{ ...style, display: "block", background: "#333", borderRadius: '50%' }}
      onClick={onClick}
    >
      ‹
    </div>
  );
};

const Website_news_Slider = ({ website_news }) => {
  
  const [slidesToShow, setSlidesToShow] = useState(3);

  // Automatically adjust slides per view based on screen size
  useEffect(() => {
    
      // AOS.init({ duration: 50 });
  
    const updateSlidesToShow = () => {
      if (window.innerWidth < 640) {
        setSlidesToShow(2); // 1 story on mobile
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(2); // 2 stories on tablets
      } else {
        setSlidesToShow(4); // 3 stories on larger screens
      }
    };
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="relative w-full flex flex-col items-center justify-center mx-auto md:mt-[5rem] mt-4 md:mb-[3rem] mb-1 ">
      
      <div className="w-[90%]">
      <div className=" md:text-2xl text-md font-semibold  md:text-start text-center md:mb-[3rem] mb-1 ml-2   text-black">IMPORTANT NEWS</div>
      <Slider {...settings}>
        {website_news?.map((slide,index) => (
          <div
            key={index}
            className="px-2"
           
          >
            <div className="relative  bg-white shadow-md rounded-md overflow-hidden border border-gray-300 h-60 md:h-80 flex flex-col md:px-0 px02">
              {/* Logo Image */}
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}image/website_news_photo/${slide.logo}`}
                alt="News Thumbnail"
                className="w-full h-40 object-cover"
              />
              
              {/* Text Content */}
              <div className="p-4 flex-1 flex flex-col justify-between">
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}image/news_media_image/${slide.news_media_logo}`}
                alt="News Media"
                className="w-full h-12 object-cover"
              />
                <p className="text-center text-black text-sm truncate">{slide.heading}</p>
              </div>

              {/* News Media Image */}
          
            </div>
          </div>
        ))}
      </Slider>
      </div>
    </div>
  );
};

export default Website_news_Slider;
