import React, { useState, useEffect, useContext } from 'react';

import Post from '../post/post';
import PostTopSection from '../blogtopsection/blogTop';
import { Context } from '../../../../../Context_holder';
import { useSearchParams } from 'react-router-dom';

const BlogSection = () => {

const{website_blog_fetch,website_blog}=useContext(Context)

  const [posts, setPosts] = useState([]);
  
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);


  const [ Heading_Terms,setHeading_Terms ]=useState("")
  const [ posted_time,setposted_time ]=useState("")
const [searchParams, setSearchParams] = useSearchParams();



  const search_handler=(search_term)=>{

    setHeading_Terms(search_term)

  }

  const recent_post_handler=(posted_time)=>{
    setposted_time(posted_time)
  }


  useEffect(() => {

    const query = {};

    if(Heading_Terms!=""){
      query.heading=Heading_Terms
    }

    if(posted_time!=""){
      query.posted=posted_time
    }

    setSearchParams(query);
    website_blog_fetch(null,window.location.search.toString());
    
  }, 
  [Heading_Terms,posted_time]);



  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
    setDisplayedPosts(posts.slice(0, (currentPage + 1) * 5));
  };

  return (

    <div className="container mx-auto p-4 ">
      <PostTopSection search_handler={search_handler} recent_post_handler={recent_post_handler}/>
      <div className="my-4">
        {website_blog?.map((post,index )=> (
          <Post key={index} post={post} />
        ))}
      </div>
      {displayedPosts.length<posts.length && (
        <div className='flex justify-center'>
<div
          onClick={handleLoadMore}
          className="px-4 text-center bg-blue-500  text-white py-2 rounded-lg hover:bg-blue-600"
        >
          {loading ? 'Loading...' : 'Load More'}
        </div>

        </div>
        
      )}
    </div>
  );
};

export default BlogSection;
