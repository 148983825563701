import React, { useContext, useEffect } from "react";
import { Context } from "../../../Context_holder";
import { Link } from "react-router-dom";

const Approved_colleges = () => {
  const { college_fetch, colleges, setcollege_name } = useContext(Context);
  useEffect(() => {
    college_fetch();
  }, []);

  console.log(colleges);

  return (
    <div className="w-full flex justify-center">
      <div className="md:w-[90%] w-full py-1 md:py-8">
        <h1 className="md:text-3xl text-xl text-green-300 font-bold text-center md:mb-8 mb-2 mt-4">
          Approved aaopadhe Universities
        </h1>
        <div className="grid grid-cols-3 md:grid-cols-6 md:gap-6 gap-2">
          {colleges?.map((university, index) => (
            <Link
              onClick={() => setcollege_name(university?.college_name)}
              to={"/allUniversity"}
              key={index}
              className="bg-white rounded-lg shadow-lg md:p-6 p-2 flex flex-col items-center text-center"
            >
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}image/college_logo/${university?.university_banner[0]}`}
                alt={university?.college_name}
                 loading="lazy"
                className="w-full h-16 mb-4 rounded-md"
              />
              <p className="md:text-lg text-sm md:font-semibold mb-2  text-black">
                {university?.courses?.length} Courses
              </p>
              <p className="text-gray-600 text-sm md:text-lg">{university?.college_name}</p>
            </Link>
          ))}
        </div>
        {/* <div className="text-center mt-8">
        <button className="px-6 py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600">View More Universities</button>
      </div> */}
      </div>
    </div>
  );
};

export default Approved_colleges;


