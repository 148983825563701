

import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import Select from 'react-select';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function Placement_add() {
  const{college_fetch,colleges ,selected_college,setselected_college,quill_value,setquill_value,token,notify}=useContext(Context)



  


  useEffect(
    ()=>{
  
      college_fetch()
      setselected_college(null)
    
    },[]
    
  )
 


  const handleSubmit = (e) => {
    e.preventDefault();
  
    const college_id = selected_college?.value;
   const placemenet_paragraph= quill_value
    
   
  
    const data = {
        college_id:college_id,
        placemenet_paragraph:placemenet_paragraph,
    };

 

    axios.post(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_PLACEMENT_URL}add`, data,{
      headers: {
        Authorization: token}
    })
    .then((response) => {
      notify(response.data.msg,response.data.status)
     

      if (response.data.status === 1) {
        e.target.reset();
        setselected_college(null)
        
        setquill_value(null)
      }
    })
    .catch((error) => {
       
      
      
      })
     
  };

  return (
    <div className="min-h-screen py-10 bg-gray-300">
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4"> Placement paragraph For particuler college</h2>
      <form onSubmit={handleSubmit} className="w-full">
  
        <div className="grid grid-cols-1  gap-6 mb-4">
          <div>
            <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">College ID</label>
            <Select
              value={selected_college}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: '4px 20px',
                  marginTop: '4px',
                }),
              }}
              onChange={(e) => { setselected_college(e) }}
              name="college_id"
              options={colleges?.college?.map(data => ({ value: data._id, label: data.college_name }))}
            />
          </div>

          <div className='mb-6'>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college_name">About Placement</label>
          <div>
          <ReactQuill value={quill_value}  onChange={(e)=> setquill_value(e)}/></div> 
        </div>
          
        </div>
  
       
  
        <div className="flex justify-end mb-4">
          <button
            type="submit"
            className="bg-blue-500 md:w-auto hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add Placement
          </button>
        </div>
      </form>
    </div>
  </div>
  
  );
}


