import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaGraduationCap, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

// Custom Arrow components
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrow slick-next`}
      style={{ ...style, display: "block", background: "#333", borderRadius: '50%' }}
      onClick={onClick}
    >
      <FaArrowRight color="#fff" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrow slick-prev`}
      style={{ ...style, display: "block", background: "#333", borderRadius: '50%' }}
      onClick={onClick}
    >
      <FaArrowLeft color="#fff" />
    </div>
  );
};

// Slick slider settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function OurBlog({ collegeDetails }) {
  return (
    <div className="w-full py-12 px-4 lg:px-0 bg-gray-200">
      <div className="container mx-auto">
        <div className="text-center py-3 mb-8">
          <p className="font-bold text-md flex gap-1 items-center justify-center text-gray-800 mb-2">
            <FaGraduationCap />
            Our Blog
          </p>
          <h2 className="font-bold md:text-4xl text-xl text-gray-900">Latest Blog & News</h2>
        </div>
        <Slider {...settings}>
          {collegeDetails?.posts?.map((data, index) => (
            <Link
              to={`/universityblog/${data._id}`}
              key={index}
              className="group bg-white shadow-md rounded-md overflow-hidden relative border border-gray-300"
            >
              <div className="relative">
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}image/Post_image/${data.logo}`}
                  alt={data.logo}
                  className="w-full h-[220px] object-cover transition-transform duration-300 transform group-hover:scale-105"
                />
                <div className="absolute top-4 left-4 bg-[#333] text-white p-3 rounded-md shadow-lg">
                  <p className="font-bold text-sm">{data.icon}</p>
                  <p className="text-xs">{data.month}</p>
                </div>
              </div>
              <div className="p-6">
                <h5 className="text-2xl font-bold text-gray-800 mb-3 hover:text-red-500">{data.hed}</h5>
                <p className="text-gray-700 mb-4 text-sm">{data.pre}</p>
                <div className="flex items-center gap-2 text-gray-600">
                  <p className="text-sm font-medium">{data.heading}</p>
                  <FaArrowRight />
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default OurBlog;
