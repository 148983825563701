
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Context } from '../../../../Context_holder';
import axios from 'axios';

export default function Scholarship_view() {

  const { scholarship_fetch,scholarship,token,notify}= useContext(Context)

  console.log(scholarship,"67890-");

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  

const delete_handler=(id)=>{


  axios.delete(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_SCHOLARSHIP_URL+"delete/"+id,{
    headers: {
      Authorization: token}
  })
  .then((success) => {
    notify(success.data.msg,success.data.status)

    if (success.data.status === 1) {
    
      scholarship_fetch()

    }
    
  })
  .catch((error) => {
   
    
    }
    
    
    )


}
  

useEffect(
    ()=>{
      scholarship_fetch()

      
      
    },
    []
)

if(scholarship){
    return (
        <div className="min-h-screen bg-gray-100 py-6 px-6 flex flex-col justify-center sm:py-3">

    <div className="relative py-3 ">

      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-10">
        <div className="max-w-full mx-auto">
          <h1 className="text-3xl font-bold text-center mb-6">Scholarship List</h1>
          <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">

  <thead>
    <tr>
      
      
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">College name</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Scholarship Type</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Organisation</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Deadline</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Numbers</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">international_students</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Website</th>

      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th>
    </tr>
  </thead>
  <tbody className="bg-white divide-y divide-gray-200">

    {scholarship?.map((data, index) => (
      <tr key={index}>
       
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-900">{data.college_id?.college_name}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{data.scholarship_type}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{data.organisation}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{  monthNames[new Date(data.application_deadline).getMonth()]} { new Date(data.application_deadline).getDate() }</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {data.no_of_scholarships}
        </td>

        <td className="px-6 py-4 whitespace-nowrap">
          {data.amount}
        </td>

        <td className="px-6 py-4 whitespace-nowrap">
          {data.international_students?"Yes":"No"}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <Link to={data.scholarship_link} className=' px-3 py-2 border  rounded-md shadow-lg'>Link</Link>
          
        </td>


        <td className="px-6 py-8 items-center whitespace-nowrap flex gap-2 ">
          <Link to={`/admin/scholarship/edit/${data._id}`}  className='px-3 py-1 bg-blue-700 hover:bg-blue-900 text-white font-semibold rounded'>Edit</Link>
          <button className='px-3 py-1 font-semibold rounded border shadow-md hover:text-white hover:bg-red-500' onClick={()=>delete_handler(data._id)}>Delete</button>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <button className='px-3 py-1 font-semibold rounded border  hover:shadow-lg'>View</button>
        </td>
      </tr>
    ))}
  </tbody>
  
</table>

          </div>
        </div>
      </div>
    </div>
  </div>
 
    );
}

   
}
