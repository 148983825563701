

import React from 'react';
import AnimatedText from '../../extra/animation-charctor';
import parse from 'html-react-parser';

function AboutUniversity({collegeDetails}) {
  return (
    <section className="relative w-full overflow-hidden py-8 flex items-start pt-12 justify-center bg-gradient-to-b from-[#18092f] via-purple-900 to-[#18092f] ">
      <div className="relative w-[100%] z-10  bg-opacity-90  rounded-lg ">
        <div className="mx-auto ">
          <div 
            className="inline px-4 py-[2px] rounded-xl bg-gradient-to-r from-purple-500 via-pink-500 to-purple-600 text-4xl font-extrabold font-Noto-senc mb-4"
            style={{
              textTransform: 'uppercase',
              backgroundImage: 'linear-gradient(-225deg, hsl(253, 64%, 4%) 0%, #44107a 29%, #060304 67%, #fff800 100%)',
              backgroundSize: '200% auto',
              color: '#F9E400',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitTextFillColor: 'transparent',
              animation: 'textclip 2s linear infinite',
              display: 'inline-block',
              fontSize: '35px',
            }}
          >
         About {collegeDetails.college_name}  
          </div>
          <p className="text-blue-900 text-lg leading-relaxed mt-4 font-semibold preview">

          {collegeDetails?.about&& parse(collegeDetails?.about)}
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUniversity;