import React, { useContext } from "react";
import "./index.css"; // Assuming you have custom styles in index.css
import { Context } from "../../../../../Context_holder";
import axios from "axios";


function NewsLetter() {
  const { setuserSignUp_popup, user, enquiry_value, setenquiry_value,setapply_popUpIsOpen,notify } = useContext(Context);
  
  console.log(enquiry_value)


  const enquiry_api = () => {

    const data={
      enquiry: enquiry_value ?? null,
    };

  

    axios
      .patch(
        process.env.REACT_APP_API_BASE_URL +
        process.env.REACT_APP_USER_URL +
        "enquiry_edit/" +
        user?._id,
        data
      )
      .then((success) => {
        console.log("enquiry_edit:", success);
        if (success.data.status === 1) {
          setenquiry_value("");
          setapply_popUpIsOpen(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const enquiry_Handler = () => {

    if (user == null) {
      setuserSignUp_popup(true);
    } 

    else {
      if(enquiry_value!=""){

        enquiry_api();
      }

      else {
        notify("please fill the input",0)

      }
      

    }

  };

  return (
    <div className="w-full py-2 bg-black flex justify-center pt-[4rem] ">
      <div className="md:w-[60%] w-[90%] py-4 bg-[#18092f] rounded-lg shadow-lg px-8">
        <h2 className="text-center text-2xl font-semibold uppercase text-blue-600 mb-4">
          Enquiry Now
        </h2>
        <p className="text-center text-sm text-white mb-6">
          If you have any questions about our courses, admission process, or
          anything else, feel free to reach out. We're here to help you on
          your academic journey.
        </p>
        <div className="flex flex-col md:flex-row md:justify-center gap-4">
          
          <textarea
            placeholder="Your Enquiry"
            className="w-full md:w-[80%] h-12 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            value={enquiry_value}
            onChange={(e)=>setenquiry_value(e.target.value)}
          />
          
          <button
            type="button"
            onClick={enquiry_Handler}
            className="px-4 md:ml-4 w-full md:w-auto h-12 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm transition duration-200"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewsLetter;
