import React, { useContext } from "react";
import { Context } from "../../../../../Context_holder";
import { useNavigate } from "react-router-dom";

const AdmissionButtons = () => {

  const { setcourse_name,setfilterHeading } = useContext(Context);
  const buttons = [
    "B Ed",
    "MBA",
    "MBBS",
    "BA",
    "M Tech",
    "PhD",
    "LLB",
    "D El Ed",
    "BSc",
    "B Pharmacy"
  ];

  const navigater=useNavigate()


  const course_handler = (course_name) => {

    setcourse_name(course_name)
  
  
    navigater("/allUniversity");

    setfilterHeading("Admission 2024")
  
    
  
  };
  

  return (
    <div className="text-center md:mt-8 mt-4">
      <h2 className="md:text-2xl text-md font-semibold md:mb-6 mb-4 text-green-400">Open 2024 Live Admission</h2>
      <div className="flex flex-wrap gap-4 justify-center">
        {buttons.map((buttonText, index) => (
          <button
            key={index}
            className="px-4 py-2 bg-white border border-gray-300 rounded-full shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 text-gray-700"
            onClick={()=>course_handler(buttonText)}
          >
            {buttonText} Admission 2024 <span className="ml-2 text-gray-400">➔</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AdmissionButtons;
