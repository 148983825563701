import React from "react";
import "./index.css";
import hostel from "./image/hostel.png";
import cafe from "./image/cafe.png";
import lab from "./image/lab.png";
import computerLab from "./image/lab-technician.png";
import medical from "./image/medical-team.png";
import transport from "./image/shipment.png";
import gym from "./image/weightlifter.png";
import wifi from "./image/wifi.png";
import ci from "./image/cinema.png";
import library from "./image/library (1).png";


const UniversityFacilities = ({ collegeDetails }) => {
  return (
    <div id="wrap" className="flex-wrap flex justify-center w-full ">
      <div className="text-center">
        <h2 className="text-bold text-[1.5rem]">
          {collegeDetails.college_name}
          <span className={``}> Facilities</span>
        </h2>
      </div>

      <div
        id="wrap2"
        className="md:gap-[3rem] gap-8 flex-wrap flex  w-[100%] justify-center">
        <div className="linkbutton py-[2rem]  ">
          <img
            src={gym}
            className="h-[60px] w-[65px]  "
            alt="image not found"
          ></img>
          <h2>Gym</h2>
          <div className="ease peterriver"></div>
        </div>

        <div className="linkbutton  ">
          <img
            src={hostel}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Hostel</h2>
          <div className="ease belizehole"></div>
        </div>

        <div className="linkbutton">
          <img
            src={cafe}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Cafeteria</h2>
          <div className="ease amethyst"></div>
        </div>

        <div className="linkbutton">
          <img
            src={lab}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Laboratory</h2>
          <div className="ease westeria"></div>
        </div>

        <div className="linkbutton">
          <img
            src={computerLab}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Computer Labs</h2>
          <div className="ease wetasphalt"></div>
        </div>

        <div className="linkbutton">
          <img
            src={ci}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Auditorium</h2>
          <div className="ease midnightblue"></div>
        </div>

        <div className="linkbutton">
          <img
            src={wifi}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Wi-Fi Campus</h2>
          <div className="ease sunflower"></div>
        </div>

        <div className="linkbutton">
          <img
            src={transport}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Transport</h2>
          <div className="ease orange"></div>
        </div>

        <div className="linkbutton">
          <img
            src={medical}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Medical</h2>
          <div className="ease carrot"></div>
        </div>

        <div className="linkbutton">
          <img
            src={library}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Library</h2>
          <div className="ease alizarin"></div>
        </div>
      </div>
    </div>
  );
};

export default UniversityFacilities;
