import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../../../../Context_holder';
import Select from 'react-select';
import axios from 'axios';
import { useParams } from 'react-router-dom';


export default function Course_edit() {
    const{college_fetch,colleges ,selected_college,setselected_college,course_fetch,currenetcourse,token,stream_fetch,stream,selected_stream,setselected_stream,notify}=useContext(Context)

    const {id} = useParams()

    const [currenet_data,setcurrenet_data]=useState(null) 

 
    const [logo_value, setlogo_value] = useState("")

    const logo_ref=useRef()
    
    const logo_handler = ()=>{
      const logo= logo_value
      const old_logo=currenet_data?.course_image
      const id=currenet_data?._id
      const formData = new FormData();
      
      
      formData.append("logo",logo)
      formData.append("old_logo",old_logo)
      
      
        axios.patch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COURSE_URL}logo_edit/${id}`,formData,{
          headers: {
            Authorization: token}
        })
        .then((success) => {
         

   
     notify(success.data.msg,success.data.status)
      
          if (success.data.status === 1) {
            course_fetch(id)
            setlogo_value(null)
            logo_ref.current.value=""
          }
        })
      
        .catch((error) => {
          console.error('Error:', error);
        });
      
      
      }

    useEffect(
      ()=>{
    
        college_fetch()
        course_fetch(id)
        stream_fetch()
        
       
      
      },[]
    )

  
    
useEffect(
    ()=>{
      setcurrenet_data(currenetcourse)
      
        setselected_college({ label:currenetcourse?.college_id?.college_name,
          value: currenetcourse?.college_id?._id})

        setselected_stream({ label:currenetcourse?.stream_id?.stream_name,
          value: currenetcourse?.stream_id?._id})

      

    },
    
    [currenetcourse]
)
    
  
  





  
    const edit_handler = (e) => {

      e.preventDefault();
    
      const college_id = selected_college?.value;
      const courseName = e.target.courseName.value;
      const stream_id= selected_stream?.value;
      const duration = e.target.duration.value;
      const specialisation = e.target.specialisation.value.split("$").filter(a => a !== "");
      const approved = e.target.approved.value.split("$").filter(a => a !== "");
      const scholarship = e.target.scholarship.value;
      const mode = e.target.mode.value;
      const time = e.target.time.value;
      const fees = e.target.fees.value;
      
    
      const data = {
        college_id: college_id,
        courseName: courseName,
        duration: duration,
        specialisation: specialisation,
        mode: mode,
        time: time,
        fees: fees,
        scholarship: scholarship,
        stream_id:stream_id,
        approved:approved

      };
  
   
  
      axios.put( `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COURSE_URL}edit/${id}`, data,{
        headers: {
          Authorization: token}
      })
      .then((response) => {
        
  console.log(response);
  
        if (response.data.status === 1) {
            
          e.target.reset();
          notify(response.data.msg,response.data.status)
          course_fetch(id)
          
          
        }
      })
      .catch((error) => {
         
        
        })
       
    };

        return (
            <div className="min-h-screen py-10 bg-gray-300">
            <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
              <h2 className="text-2xl font-bold mb-4">Edit Course</h2>
              <form onSubmit={edit_handler}className="w-full">
          
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-4">
                  <div>
                    <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">College ID</label>
                    <Select
                      value={selected_college}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          padding: '4px 20px',
                          marginTop: '4px',
                        }),
                      }}
                      onChange={(e) => { setselected_college(e) }}
                      name="college_id"
                      options={colleges?.college?.map(data => ({ value: data._id, label: data.college_name }))}
                    />
                  </div>

 
                  <div>
            <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">Stream ID</label>
            <Select
              value={selected_stream}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: '4px 20px',
                  marginTop: '4px',
                }),
              }}
              onChange={(e) => { setselected_stream(e) }}
              name="college_id"
              options={stream?.map(data => ({ value: data._id, label: data.stream_name }))}
            />
          </div>

                  <div>
                    <label htmlFor="courseName" className="block text-sm font-medium text-gray-700">Course Name</label>
                    <input
                    defaultValue={currenet_data?.courseName}
                      type="text"
                      id="courseName"
                      name="courseName"
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    />
                  </div>
                  <div>
                    <label htmlFor="duration" className="block text-sm font-medium text-gray-700">Duration</label>
                    <input
                    defaultValue={currenet_data?.duration}
                      type="number"
                      id="duration"
                      name="duration"
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    />
                  </div>
                </div>
          
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
                  <div>
                    <label htmlFor="specialisation" className="block text-sm font-medium text-gray-700">Specialisation <span className='text-[red]'>($saprated)</span></label>
                    <input
                         defaultValue={currenet_data?.specialisation.join('$').replace(/,/g, '$')}
                      type="text"
                      id="specialisation"
                      name="specialisation"
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    />
                  </div>
               
                  <div>
                    <label htmlFor="mode" className="block text-sm font-medium text-gray-700">Mode</label>
                    <select
                     defaultValue={currenet_data?.mode}
                     
                      id="mode"
                      name="mode"
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    >
                      <option value="">Select Mode</option>
                      <option value="on-campus">On Campus</option>
                      <option value="distance">Distance</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="time" className="block text-sm font-medium text-gray-700">Time</label>
                    <select
                    defaultValue={currenet_data?.time}
                      id="time"
                      name="time"
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    >
                      <option value="">Select Time</option>
                      <option value="fulltime">Full-Time</option>
                      <option value="parttime">Part-Time</option>
                      <option value="Distence">Distence</option>
                    </select>
                  </div>


                  <div>
            <label htmlFor="" className="block text-sm font-medium text-gray-700">Approved <span className='text-[red]'>($saprated)</span></label>
            <input
            defaultValue={currenet_data?.approved.join('$').replace(/,/g, '$')}
              type="text"
              id="approved"
              name="approved"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>


                  <div>
                    <label htmlFor="scholarship" className="block text-sm font-medium text-gray-700">Scholarship</label>
                    <input

defaultValue={currenet_data?.scholarship}
                      type="text"
                      id="scholarship"
                      name="scholarship"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    />
                  </div>
                  <div>
                    <label htmlFor="fees" className="block text-sm font-medium text-gray-700">Fees</label>
                    <input
                    defaultValue={currenet_data?.fees}
                      type="number"
                      id="fees"
                      name="fees"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    />
                  </div>
                </div>

        
          
                <div className="flex justify-end mt-4">
                  <button
                    type="submit"
                    className="bg-blue-500 w-full md:w-auto hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Save
                  </button>
                </div>
              </form>
              <div >
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="logo">
                Image
              </label>

              <input
                        ref={logo_ref}
                        type='file'
                        id="logo"
                        name="logo"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        onChange={(e) => setlogo_value(e.target.files[0])}
                    />
            </div>
            <button className={`hover:bg-blue-950 capitalize bg-blue-700 text-white text-md font-semibold px-3 py-1 rounded-md mt-2 ${logo_value ? "block" : "hidden"}`} onClick={logo_handler} >
                        Update Image
                    </button>
            </div>
          </div>
          
          );
    
  
   
}
