import React from 'react';
import UniversityDiv from '../../../components/common/universityDiv/page';
// import CoursePopUp from '../../../components/common/coursePopup/courserPopup';
import AnimatedText from './animateheading';

const CourseType = () => {
  return (
    <div className="relative">
      {/* Main content */}
      <div className="w-full py-5 md:py-16 md:pb-8">
        <div className="flex flex-col items-center text-center">
          <AnimatedText/>
          <div className="w-full flex justify-center mt-2 md:mt-12">
            <UniversityDiv />
          </div>
        </div>
      </div>

      {/* Sidebar (hidden initially, slides down when clicked) */}
      <div className="fixed bottom-0 right-0 p-4 md:hidden">
        {/* Example sidebar content */}
   
      </div>
    </div>
  );
};

export default CourseType;
