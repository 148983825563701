import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { FaEye } from "react-icons/fa";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles

const Website_stories = ({ websitestory }) => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    // AOS.init(
    //   {
    //     offset: 100, // Yahan aap apne hisaab se value set karein
    //     duration: 100 // Animation ka duration (milliseconds)
    //   }
    // ); // Initialize AOS

    const updateSlidesToShow = () => {
      if (window.innerWidth < 640) {
        setSlidesToShow(2);
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(3);
      }
    };

    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    nextArrow: <div className="slick-arrow">›</div>,
    prevArrow: <div className="slick-arrow">‹</div>,
  };

  return (
    <div className="relative w-full flex flex-col justify-center items-center mx-auto p-4 mt-[2rem]">
      <div className="text-start text-[1rem] ml-4 w-[90%]">
        <div>Trusted Voices</div>
        <div className="font-bold md:text-[1.2rem] text-md text-blue-300">Your Source for Authentic Insights</div>
      </div>
      <Slider {...settings} className="w-[90%] mt-4">
        {websitestory?.map((slide,index) => (
          <div key={index} className="px-2" > {/* Add AOS attribute here */}
            <div className="bg-gray-800 rounded-xl overflow-hidden shadow-lg h-60 w-full " >
              <div className="bg-black h-8 w-full flex items-center justify-between px-2">
                <div className="flex items-center"  >
                  <FaEye className="text-white mr-1 text-lg" />
                  <span className="text-white text-sm">{slide.views}</span>
                </div>
              </div>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}image/story_thumbnail/${slide.thumbnail}`}
                  loading="lazy"
                alt={"thumbnail"}
                className="h-full w-full object-cover " 
              />
              <div className="bg-black h-8 w-full"></div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Website_stories;
