import React from 'react';
import { FaBullseye, FaGraduationCap, FaHandsHelping, FaBook, FaCommentsDollar } from 'react-icons/fa';
import { MdSchool } from 'react-icons/md';

function Why() {
  return (
    <div className="bg-gradient-to-b from-[#18092f] via-purple-900 to-[#18092f] p-8 md:p-16">
      <h1 className="text-3xl md:text-5xl font-bold text-center text-white mb-4">
        Why We’re <span className="text-green-500">Different</span>
      </h1>
      <p className="text-lg md:text-xl text-center text-gray-300 mb-12">Top Features in Timeline</p>

      {/* Timeline Container */}
      <div className="relative flex flex-col items-center md:grid md:grid-cols-2 md:gap-8">

        {/* Timeline Line */}
        <div className="absolute w-1 bg-gray-500 h-full left-1/2 transform -translate-x-1/2 hidden md:block"></div>

        {/* Timeline Item 1 */}
        <div className="timeline-item relative mb-8 md:mb-16 flex md:flex-row-reverse md:items-center">
          <div className="timeline-icon bg-green-500 p-4 rounded-full shadow-lg mb-4 md:mb-0">
            <FaBullseye className="text-white text-3xl" />
          </div>
          <div className="ml-6 md:mr-6">
            <h2 className="text-xl md:text-2xl font-bold text-green-500">Personalized Matches</h2>
            <p className="text-white mt-2">Find the best college tailored to your course and career goals.</p>
          </div>
        </div>

        {/* Timeline Item 2 */}
        <div className="timeline-item relative mb-8 md:mb-16 flex md:items-center">
          <div className="timeline-icon bg-blue-500 p-4 rounded-full shadow-lg mb-4 md:mb-0">
            <MdSchool className="text-white text-3xl" />
          </div>
          <div className="ml-6 md:ml-6">
            <h2 className="text-xl md:text-2xl font-bold text-blue-500">Vast College Options</h2>
            <p className="text-white mt-2">Explore top colleges across all courses, all in one place.</p>
          </div>
        </div>

        {/* Timeline Item 3 */}
        <div className="timeline-item relative mb-8 md:mb-16 flex md:flex-row-reverse md:items-center">
          <div className="timeline-icon bg-yellow-500 p-4 rounded-full shadow-lg mb-4 md:mb-0">
            <FaHandsHelping className="text-white text-3xl" />
          </div>
          <div className="ml-6 md:mr-6">
            <h2 className="text-xl md:text-2xl font-bold text-yellow-500">Expert Assistance</h2>
            <p className="text-white mt-2">Get step-by-step guidance from our experienced counselors.</p>
          </div>
        </div>

        {/* Timeline Item 4 */}
        <div className="timeline-item relative mb-8 md:mb-16 flex md:items-center">
          <div className="timeline-icon bg-purple-500 p-4 rounded-full shadow-lg mb-4 md:mb-0">
            <FaGraduationCap className="text-white text-3xl" />
          </div>
          <div className="ml-6 md:ml-6">
            <h2 className="text-xl md:text-2xl font-bold text-purple-500">Trusted Insights</h2>
            <p className="text-white mt-2">Real student reviews to guide your decisions.</p>
          </div>
        </div>

        {/* Timeline Item 5 */}
        <div className="timeline-item relative mb-8 md:mb-16 flex md:flex-row-reverse md:items-center">
          <div className="timeline-icon bg-red-500 p-4 rounded-full shadow-lg mb-4 md:mb-0">
            <FaCommentsDollar className="text-white text-3xl" />
          </div>
          <div className="ml-6 md:mr-6">
            <h2 className="text-xl md:text-2xl font-bold text-red-500">Scholarship Aid</h2>
            <p className="text-white mt-2">Discover colleges offering financial support and scholarships.</p>
          </div>
        </div>

        {/* Timeline Item 6 */}
        <div className="timeline-item relative mb-8 md:mb-16 flex md:items-center">
          <div className="timeline-icon bg-indigo-500 p-4 rounded-full shadow-lg mb-4 md:mb-0">
            <FaBook className="text-white text-3xl" />
          </div>
          <div className="ml-6 md:ml-6">
            <h2 className="text-xl md:text-2xl font-bold text-indigo-500">In-Depth Resources</h2>
            <p className="text-white mt-2">Access comprehensive guides and study materials.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why;
