import React, { useContext, useEffect, } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import Typewriter from 'typewriter-effect';
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import "./styles/HomeBanner.css"
import "./index.css";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import homebanner1 from "./image/h1.jpg";
import homebanner2 from "./image/h2.jpg";
import homebanner3 from "./image/h3.jpg";
import homebanner4 from "./image/h4.jpg";
// import homebanner5 from "./image/a (1).jpg";
import { Context } from "../../../../../Context_holder";
import img from "./image/h5.jpg"
const homebanner = [homebanner1, homebanner2, homebanner3, homebanner4];

const HomeBanner = () => {
  const { slider_banners_fetch, slider_banner } = useContext(Context);

  useEffect(() => {
    slider_banners_fetch();
  }, []);

  console.log(
    `${process.env.REACT_APP_API_BASE_URL}image/slider_banner/${slider_banner[0]?.banner} - Bhawani be like, "Yeh toh bas shuruaat hai!" 😂`
  );
  console.log(slider_banner ,"bannnerrrrr")
  

  return (
    <>
      {/* <div className="flex relative  md:w-full z-[-1]  sm:h-[25rem] md:h-[30rem] ">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
        
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {slider_banner[0]?.banner?.map((data, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="md:w-full object-cover h-[10rem] md:h-[30rem] ">
                  <img
                    className="md:w-full  md:h-full object-cover"
                    src={`${process.env.REACT_APP_API_BASE_URL}image/slider_banner/${data}`}
                    // src={img}
                      loading="lazy"
                    alt="image not found"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div> */}




<div className="bg-gradient-to-b from-[#18092f] via-purple-900 to-[#18092f] p-8 md:p-16 text-center relative overflow-hidden">
      {/* Decorative Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-purple-900 opacity-50 pointer-events-none"></div>
      <div className="absolute top-10 left-20 w-32 h-32 bg-green-500 rounded-full blur-3xl opacity-30 animate-pulse"></div>
      <div className="absolute bottom-10 right-20 w-40 h-40 bg-purple-500 rounded-full blur-3xl opacity-30 animate-pulse"></div>

      {/* Main Content */}
      <h1 className="text-4xl md:text-6xl font-extrabold text-white mb-6 animate-fadeIn">
        Welcome to <span className="text-green-500 animate-jump">AaoPadhe</span>
      </h1>

      {/* Typing Effect */}
      <p className="text-lg md:text-2xl text-gray-300 mb-6">
        <Typewriter
          options={{
            strings: [
              "Empowering students with the right guidance on courses and career paths.",
              "Helping you explore colleges and achieve your dreams.",
              "Your one-stop destination for education and success."
            ],
            autoStart: true,
            loop: true,
            delay: 50,
            deleteSpeed: 25,
          }}
        />
      </p>

      {/* Call to Action Buttons */}
     
    </div>

    </>
  );
};

export default HomeBanner;
