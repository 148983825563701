import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../../../Context_holder";
import Select from "react-select";
import { IoClose } from "react-icons/io5";

const states = require("../../../../Json_files/States.json");

export default function UserSignUp_Popup() {
  const {
    user,
    course_fetch,
    course,
    selected_state,
    setselected_state,
    handlestateInputChange,
    statemenuIsOpen,
    setuser,
    setusertoken,
    userSignUp_popup,
    setuserSignUp_popup,
    notify,
    setuserLogin_popup,
  } = useContext(Context);

  const { id } = useParams();
  const [selected_course, setselected_course] = useState(null);

  const [otp, setotp] = useState("");
  const [otpsection, setotpsection] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  

  const form_ref = useRef();

  useEffect(() => {
    course_fetch();
  }, []);

  const uniqueCourses = course?.filter(
    (course, index, self) =>
      index === self.findIndex((c) => c.courseName === course.courseName)
  );
  useEffect(() => {
    if (!user) {  // If no user is logged in (user is null or undefined)
        // Set an interval to show the sign-up popup after 30 seconds
        const interval = setInterval(() => {
            setuserSignUp_popup(true); 
        }, 30000);

        // Cleanup function to clear the interval when component unmounts or when 'user' changes
        return () => {
            clearInterval(interval);  
        };
    }

    // If user is logged in (user is not null or undefined)
    if (user) {
        setuserSignUp_popup(false);  // Close the sign-up popup
    }
}, [user, setuserSignUp_popup]); 



  useEffect(() => {
    let timer;

    if (isActive && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);

      setSeconds(60);
    }
    return () => clearInterval(timer);
  }, [isActive, seconds]);

  const startCountdown = () => {
    setIsActive(true);
  };

  const otp_section_hadler = (e) => {
    e.preventDefault();
    const email = form_ref.current.email.value;

    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_USER_URL}exist_user/${email}`
      )
      .then((success) => {
        if (success.data.status === 0) {
          notify("this email already registered", success.data.status);
        } else {
          axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_OTP_URL}send`,
              { email: form_ref.current.email.value }
            )
            .then((success) => {
              notify(success.data.msg, success.data.status);
              console.log(success);

              if (success.data.status === 1) {
                setotpsection(true);
                startCountdown();
              }
            })
            .catch((error) => {
              /* Handle error */
            });
        }
      })
      .catch((error) => {
        /* Handle error */
      });
  };

  const submit_signup_handler = () => {
    const name = form_ref.current.name.value;
    const email = form_ref.current.email.value;
    const contact = form_ref.current.contact.value;

    const state = selected_state?.value;
    const city = form_ref.current.city.value;
    const course = selected_course?.label;

    const data = { name, email, contact, state, city, course };

    if (id) data.college_id = id;
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_USER_URL}/sign_up`,
        data
      )

      .then((success) => {
        notify(success.data.msg, success.data.status);
        if (success.data.status === 1) {
          setuser(success.data.user);
          setusertoken(success.data.token);
          localStorage.setItem("user", JSON.stringify(success.data.user));
          localStorage.setItem("usertoken", success.data.token);

          setuserSignUp_popup(false);
        } else {
          reset_handler();
        }
      })
      .catch((error) => {
        /* Handle error */
      });
  };

  const api_handler = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_OTP_URL}verify`,
        { email: form_ref.current.email.value, otp }
      )
      .then((success) => {
        notify(success.data.msg, success.data.status);
        if (success.data.status === 1) {
          submit_signup_handler();
        }
      })
      .catch((error) => {
        /* Handle error */
      });
  };

  const reset_handler = () => {
    setIsActive(false);
    setotpsection(false);

    setotp("");
    setSeconds(60);
  };

  const sign_in_handler = () => {
    setuserSignUp_popup(false);
    setuserLogin_popup(true);
  };

  return (
    <div
      className={`h-[100%] w-full fixed top-0 z-20 duration-300  ${
        userSignUp_popup ? "top-0" : "top-[-250%]"
      } py-5`}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.9)"}}
    >
      <button
        className="absolute left-5 top-4 text-white"
        onClick={() => setuserSignUp_popup(false)}
      >
        <IoClose className="text-3xl text-[#fdc800]" />
      </button>

      <div className="flex justify-center mb-1">
        <Link to="/" className="flex items-center">
          <h1
            className="bg-transparent lg:hover:scale-110 duration-300 font-bold text-[22px] sm:text-[30px]"
            style={{ color: "#fdc800" }}
          >
            AAOPADHE
          </h1>
        </Link>
      </div>

      <div className="h-auto border-4 py-2 border-[#fdc800] bg-white px-5 sm:px-10 rounded-md shadow-lg w-[95%] sm:w-[85%] md:w-[70%] lg:w-[55%] xl:w-[50%] 2xl:w-[40%] mx-auto scrollbar-hidden">
        <div className="flex justify-center">
          <i
            className="fa-solid fa-user text-5xl mb-2 sm:text-6xl"
            style={{ color: "#002147" }}
          ></i>
        </div>

        <button
          className={`text-[14px] sm:text-[16px] font-semibold ${
            !otpsection ? "hidden" : "block"
          } text-blue-600 capitalize mb-6 sm:mb-8`}
          onClick={reset_handler}
        >
          Change Details
        </button>

        <form ref={form_ref} onSubmit={otp_section_hadler}>
          <div className={`${otpsection ? "hidden" : "block"}`}>
            <div className="mb-3 sm:mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-1"
                htmlFor="name"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                className="shadow appearance-none border rounded w-full py-1 sm:py-2 px-2 sm:px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-[#fdc800] focus:border-transparent"
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div className="mb-3 sm:mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-1"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="shadow appearance-none border rounded w-full py-1 sm:py-2 px-2 sm:px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-[#fdc800] focus:border-transparent"
                />
              </div>

              <div className="mb-3 sm:mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-1"
                  htmlFor="contact"
                >
                  Contact Number
                </label>
                <input
                  type="number"
                  id="contact"
                  name="contact"
                  required
                  className="shadow appearance-none border rounded w-full py-1 sm:py-2 px-2 sm:px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-[#fdc800] focus:border-transparent"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div className="mb-3 sm:mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-1">
                  State
                </label>
                <Select
                  options={states?.map((state) => ({
                    value: state.name,
                    label: state.name,
                  }))}
                  value={selected_state}
                  onChange={setselected_state}
                  onInputChange={handlestateInputChange}
                  menuIsOpen={statemenuIsOpen}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>

              <div className="mb-3 sm:mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-1">
                  City
                </label>

                <input
                  type="text"
                  id="city"
                  name="city"
                  required
                  className="shadow appearance-none border rounded w-full py-1 sm:py-2 px-2 sm:px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-[#fdc800] focus:border-transparent"
                />
              </div>
            </div>

            <div className="mb-3 sm:mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Course
              </label>
              <Select
                options={uniqueCourses.map((course) => ({
                  value: course.courseName,
                  label: course.courseName,
                }))}
                value={selected_course}
                onChange={setselected_course}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>

            <div className="flex items-center justify-between mt-4 sm:mt-5">
              <button
                className="bg-[#002147] hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>

          <div className={`${otpsection ? "block" : "hidden"}`}>
            <div className="mb-3 sm:mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                OTP
              </label>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setotp(e.target.value)}
                className="shadow appearance-none border rounded w-full py-1 sm:py-2 px-2 sm:px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-[#fdc800] focus:border-transparent"
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                className="bg-[#002147] hover:bg-yellow-500 hover:text-[#002147] text-yellow-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={api_handler}
                type="button"
              >
                Verify OTP
              </button>

              <span className="text-sm">
                {isActive ? (
                  `Resend in ${seconds}s`
                ) : (
                  <button
                    type="submit"
                    className="text-blue-600 cursor-pointer"
                  >
                    Resend OTP
                  </button>
                )}
              </span>
            </div>
          </div>
        </form>

        <div className="flex items-center justify-center mt-1 sm:mt-8">
          <p>
            Already have an account?{" "}
            <span
              className="text-[#002147] cursor-pointer font-bold"
              onClick={sign_in_handler}
            >
              Sign In
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
