import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../../../Context_holder";

const HoverUniversities = ({ top10Courses, top10College }) => {
  const { setcollege_name, setcourse_name } = useContext(Context);

  const navigator = useNavigate();
  const college_Search_handler = (collegeName) => {
    setcollege_name(collegeName);

    navigator("/allUniversity");
  };

  const course_Search_handler = (courseName) => {
    setcourse_name(courseName);

    navigator("/allUniversity");
  };

  return (
    <>
      {/* Responsive Layout with colorful design */}

      <div className="w-full py-5 px-5  md:block hidden ">
        <div className="flex flex-col lg:flex-row lg:justify-between space-y-6 lg:space-y-0">
          {/* Top Universities */}
          <div className="w-full lg:w-[48%] bg-[#002147] p-1 rounded-lg shadow-md 
          hover:shadow-lg transition-shadow duration-300">
            <div className="font-bold text-xl mb-4 text-[#fdc800]">
              Top Universities
            </div>
            <ul className="text-left overflow-hidden">
              {top10College?.map((data,index) => (
                <li
                  key={index}
                  className="font-[400] mb-1 p-1 rounded-md bg-white text-black text-[#002147] hover:text-white
                  hover:bg-[#fdc800] transform hover:scale-105 transition-all 
                  duration-300 ease-in-out cursor-pointer shadow-md hover:shadow-lg"
                  onClick={() => college_Search_handler(data?.college_name)}
                >
                  <Link>{data?.college_name}</Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Top Courses */}
          <div className="w-full lg:w-[48%]  bg-[#002147] p-1 rounded-lg shadow-md 
          hover:shadow-lg transition-shadow duration-300">
            <div className="font-bold text-xl mb-4 text-[#fdc800]">
              Trending Courses
            </div>
            <ul className="text-left overflow-hidden">
              {top10Courses?.map((data,index) => {
                console.log("Course Data: ", data); // Logs data to console for debugging
                return (
                  <li
                    key={index}
                    className="font-[400] mb-1 p-1 rounded-md bg-yellow-200 text-[#002147]
                    hover:text-white hover:bg-[#fdc800] transform hover:scale-105 transition-all
                    duration-300 ease-in-out cursor-pointer shadow-md hover:shadow-lg"
                    onClick={() => course_Search_handler(data?.courseName)}
                  >
                    <Link className="">{data?.courseName}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HoverUniversities;
