import React from "react";

function Scholarships({ collegeDetails }) {
  const Headings = [
    "Scholarship Type",
    "Organisation",
    "Application Deadline",
    "No. of Scholarships",
    "Amount",
    "International Students",
    "Scholarship Link"
  ];

  const keysMap = {
    "Scholarship Type": "scholarship_type",
    "Organisation": "organisation",
    "Application Deadline": "application_deadline",
    "No. of Scholarships": "no_of_scholarships",
    "Amount": "amount",
    "International Students": "international_students",
    "Scholarship Link": "scholarship_link"
  };

  const result = Headings.map(heading => {
    const key = keysMap[heading];
    const value = collegeDetails?.scholarships?.[0]?.[key] || "N/A"; // Provide a default value if undefined
    return {
      label: heading,
      value
    };
  });

  return (
    <div className="w-full  py-8">
      <div className="w-full max-w-[85%] mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-center mb-6 text-teal-600">
          Scholarships For Students On The Basis Of Marks/CGPA
        </h1>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gradient-to-r from-indigo-100 via-white to-purple-300 rounded-lg shadow-lg">
            <thead>
              <tr>
                <th className="px-4 py-3 text-left border-b border-indigo-300 text-lg font-semibold text-teal-700">Scholarship Details</th>
                <th className="px-4 py-3 text-left border-b border-indigo-300 text-lg font-semibold text-teal-700">Details</th>
              </tr>
            </thead>
            <tbody>
              {result.map((data, index) => (
                <tr key={index} className="border-t border-indigo-300">
                  <td className="px-4 py-2 bg-indigo-50 text-sm text-indigo-800 border-r border-indigo-300">
                    {data.label}
                  </td>
                  <td className="px-4 py-2 bg-purple-50 text-sm text-purple-800">
                    {data.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Scholarships;