import React from "react";
import collegeImage from "./ele ani 2.gif"; 

function SearchCourses() {
  return (
    <div className="flex items-center justify-center h-screen w-[100%]">
      <div className="flex flex-col md:flex-row max-w-screen-lg mx-auto w-[90%]">
        {/* Left Section (Content) */}
        <div className="md:w-1/2 md:pr-8 md:mt-[6rem] mt-0">
      
            <h2 className="md:text-3xl text-xl font-bold mb-4">Discover Your Perfect University</h2>
            <p className="text-gray-400 mb-6">
              At COLLEGE WORLD, finding the ideal university is as simple as typing in your preferences.
              Just like the sparkles emerging from your screen, our platform illuminates your search for the best university.
            </p>
            <p className="text-gray-400 mb-6">
              Explore, compare, and choose from a wide range of options tailored to your needs.
              Start your journey to higher education with ease and clarity.
            </p>
            <p className="text-gray-400">
              Find your perfect match today!
            </p>
      
        </div>

        {/* Right Section (Image) */}
        <div className="md:w-1/2 mt-4 md:mt-0">
          <img
            src={collegeImage}
            alt="College"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  );
}

export default SearchCourses;
