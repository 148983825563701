import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Context } from '../../../../Context_holder';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const cities = require("../../../../Json_files/Cities.json");
const states = require("../../../../Json_files/States.json");

export default function College_add() {



  const {
    selected_state,
    setselected_state,
    selected_city,
    setselected_city,
    statemenuIsOpen,
    setstateMenuIsOpen,
    citymenuIsOpen,
    setcityMenuIsOpen,
    search_cities,
    setsearch_cities,
    handlestateInputChange,
    handlecityInputChange,
    setcurrenetcolleges,quill_value,setquill_value,fact_value,setfact_value,
    professor,setprofessor,token,notify
  } = useContext(Context);

  
  useEffect(() => {
    const search_city = cities.filter(data => data.state === selected_state?.value);
    setsearch_cities(search_city);
  }, [selected_state]);

  const submit_handler = (e) => {
    e.preventDefault();

    const college_name = e.target.college_name.value;
    const estdYear = e.target.estdYear.value;
    const affiliatedTo = e.target.affiliatedTo.value.split("$").filter(a => a !== "");
    const type = e.target.type.value;
    const programmesOffered = e.target.programmesOffered.value.split("$").filter(a => a !== "");
    const director = e.target.director.value;
    const contactNumber = e.target.contactNumber.value;
    const officialWebsite = e.target.officialWebsite.value;
    const address = e.target.address.value;
    const about = quill_value;
    const facts = e.target.facts.value.split("$").filter(a => a !== "");
    const about_professor= professor 
    const logo = e.target.logo.files;
    const state = selected_state?.value;
    const city = selected_city?.value;
    const campus = e.target.campus.files;
    const pdf = e.target.pdf.files[0];
    const education_loan= e.target.education_loan.value
    const loan_contact=e.target.loan_contact.value

    const registered_instructors=e.target.registered_instructors.value
    const office_photo= e.target.office_photo.files[0]

  



    const formData = new FormData();
    formData.append('college_name', college_name);
    formData.append('estdYear', estdYear);
    formData.append('affiliatedTo', JSON.stringify(affiliatedTo));
    formData.append('type', type);
    formData.append('programmesOffered', JSON.stringify(programmesOffered));
    formData.append('director', director);
    formData.append('contactNumber', contactNumber);
    formData.append('address', address);
    formData.append('about', about);
    formData.append("facts",JSON.stringify(facts));
    formData.append('officialWebsite', officialWebsite);
    
    formData.append('city', city);
    formData.append('state', state);
    formData.append("pdf", pdf);
    formData.append("education_loan",education_loan)
    formData.append("loan_contact",loan_contact)
    formData.append("registered_instructors",registered_instructors)
  
    formData.append("professor",about_professor)
    formData.append("office_photo",office_photo)

  
    for (let data of campus) {
      formData.append('campus', data);
    }

    for (let data of logo) {
      formData.append('logo', data);
    }
    

    axios.post(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COLLEGE_URL}submission`, formData,{
      headers: {
        Authorization: token}
    })
      .then((success) => {
        console.log(success);
        notify(success.data.msg,success.data.status)
        
          
        if (success.data.status === 1) {
         
          e.target.reset();
          setselected_city(null);
          setselected_state(null);
          setquill_value(null)
          setfact_value(null)
          setprofessor(null)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  useEffect(() => {
    setselected_city(null);
    setselected_state(null);
    setcurrenetcolleges(null);
    setfact_value(null)
    setquill_value(null)
    setprofessor(null)
    
  }, []);

  return (
    <div className="min-h-screen py-10 bg-gray-300">
  <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
    <div className="text-center text-4xl font-bold mb-3">College Submission</div>
    <form onSubmit={submit_handler} encType="multipart/form-data">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college_name">College Name</label>
          <input
            id="college_name"
            name="college_name"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="College Name"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="estdYear">Established Year</label>
          <input
            id="estdYear"
            name="estdYear"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Year"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="director">Director</label>
          <input
            id="director"
            name="director"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Director"
          />
        </div>
        
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
       
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">Address</label>
          <textarea
            id="address"
            name="address"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Address"
          ></textarea>
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="education_loan">About Education Loan</label>
          <textarea
            id="education_loan"
            name="education_loan"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Education Loan"
          />
        </div>
      </div>

      <div className='mb-6'>
          <label className="block text-gray-700 text-sm mt-4 font-bold mb-2" htmlFor="college_name">About Professor</label>
          <div>
          <ReactQuill value={professor}  onChange={(e)=> setprofessor(e)}/></div> 
        </div>

      <div className='mb-6'>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college_name">About College</label>
          <div>
          <ReactQuill value={quill_value}  onChange={(e)=> setquill_value(e)}/></div> 
        </div>

        <div  className='mb-6'>

          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college_name">Facts with <span className='text-[red]'>($saprated)</span> </label>
          <div>
          <textarea
            id="facts"
            name="facts"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Facts"
          />
            
            </div> 
        </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">Type</label>
          <select
            id="type"
            name="type"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" disabled selected>Select type</option>
            <option value="private">Private</option>
            <option value="public">Public</option>
            <option value="government">Government</option>
          </select>
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="programmesOffered">Programmes Offered <span className='text-[red]'>($saprated)</span></label>
          <input
            id="programmesOffered"
            name="programmesOffered"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Diploma/Certificate"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="affiliatedTo">Approved <span className='text-[red]'>($saprated)</span></label>
          <input
            id="affiliatedTo"
            name="affiliatedTo"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Affiliated To"
          />
        </div>

      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactNumber">College Contact Number</label>
          <input
            id="contactNumber"
            name="contactNumber"
            type="tel"
            pattern="[0-9]{10}"
            required
            maxLength="10"
            minLength="10"
            autoComplete="tel"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="+91"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="loan_contact">Education Loan Contact Number</label>
          <input
            id="loan_contact"
            name="loan_contact"
            type="tel"
            pattern="[0-9]{10}"
            required
            maxLength="10"
            minLength="10"
            autoComplete="tel"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="+91"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="officialWebsite">Official Website</label>
          <input
            id="officialWebsite"
            name="officialWebsite"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Website"
          />
        </div>

        
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">

  
      <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="registered_instructors"> Registered Instructors</label>
          <input
            id="registered_instructors"
            name="registered_instructors"
            type="number"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Instructors"
          />
        </div>

       

        <div>
          <label htmlFor="state" className="block text-sm font-bold text-gray-700">State</label>
          <Select
            value={selected_state}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: '4px 20px',
                marginTop: '4px',
              }),
            }}
            onChange={(e) => { setselected_state(e) }}
            onInputChange={handlestateInputChange}
            menuIsOpen={statemenuIsOpen}
            name="states"
            options={states.map(data => ({ value: data.name, label: data.name }))}
          />
        </div>
        <div>
          <label htmlFor="city" className="block text-sm font-bold text-gray-700">City</label>
          <Select
            value={selected_city}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: '4px 20px',
                marginTop: '4px',
              }),
            }}
            onChange={(e) => { setselected_city(e) }}
            onInputChange={handlecityInputChange}
            menuIsOpen={citymenuIsOpen}
            name="cities"
            options={search_cities.map(data => ({ value: data.name, label: data.name }))}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="logo">Main image</label>
          <input
          multiple={true}
            type="file"
            id="logo"
            name="logo"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="campus">Campus Images</label>
          <input
            type="file"
            id="campus"
            name="campus"
            multiple={true}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pdf">College PDF</label>
          <input
            type="file"
            id="pdf"
            name="pdf"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pdf">Office Photo</label>
          <input
            type="file"
            id="office_photo"
            name="office_photo"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

      </div>

      <div className="flex justify-end mb-4">
        <button
          type="submit"
          className="bg-blue-500 w-full md:w-auto hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>

  );
}
