import React, { useEffect, useState } from 'react';

const CourseSidebar = () => {
  const courses = ["B.Tech", "M.Tech", "MBA", "BBA", "BCA", "MCA", "BA", "MA"];
  const [scrollIndex, setScrollIndex] = useState(0);
  const itemsPerView = 4;

  // Infinite scrolling effect
  useEffect(() => {
    const scrollEffect = setInterval(() => {
      setScrollIndex((prevIndex) => (prevIndex === courses.length - itemsPerView ? 0 : prevIndex + 1));
    }, 2000);

    return () => clearInterval(scrollEffect);
  }, [courses.length]);

  // Function to handle manual navigation
  const handleNext = () => {
    setScrollIndex((prevIndex) => (prevIndex === courses.length - itemsPerView ? 0 : prevIndex + 1));
  };

//   const handlePrev = () => {
//     setScrollIndex((prevIndex) => (prevIndex === 0 ? courses.length - itemsPerView : prevIndex - 1));
//   };

  return (
    <div className="flex items-center justify-center bg-gradient-to-b from-[#18092f] via-purple-900 to-[#18092f]

 shadow-md" >
      <div className="relative w-full max-w-2xl overflow-hidden">
        
        {/* Previous Button */}
        {/* <button
          onClick={handlePrev}
          className="absolute left-0 top-1/2 transform -translate-y-1/2  text-white p-2 rounded-l"
        >
          &#8592;
        </button> */}

        {/* Course Slider */}
        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${scrollIndex * (100 / itemsPerView)}%)` }}
        >
          {courses.map((course, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-1/4 py-4  text-white flex items-center justify-center text-sm md:text-xl font-bold"
            >
              {course}
            </div>
          ))}
        </div>

        {/* Next Button */}
        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2  text-blue-700 p-2 rounded-r"
        >
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default CourseSidebar;
