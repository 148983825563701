import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../../Context_holder";

const TopCourses = () => {
  const { setcourse_name,setfilterHeading } = useContext(Context);
  const navigater=useNavigate()

  const course_handler = (course_name) => {

    setcourse_name(course_name)
  
  
    navigater("/allUniversity");
    setfilterHeading("Admission 2024")
    
  
  };

  const courses = 
  [
    "B Ed",
    "MBA",
    "MBBS",
    "BA",
    "M Tech",
    "PhD",
    "LLB",
    "D El Ed",
    "BSc"
  ]
 

  return (
    <div className="w-full flex justify-center md:mt-8 mt-1 md:py-4 py-2">
      <div className="w-11/12 lg:w-[90%] text-center">
        <h2 className="md:text-2xl text-xl font-semibold md:mb-6 mb-3 text-green-300">
          Top Courses for 2024 Admission
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {courses.map((course, index) => (
            <button
              key={index}
              className="w-full py-3 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200
               transition-all duration-200 text-gray-700"
              onClick={()=>course_handler(course)}
            >
              {course} Admission 2024
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopCourses;
