import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Context } from '../../../../Context_holder';



export default function Banner_view() {
const {banners_fetch,banner,token,notify}= useContext(Context)

  
useEffect(

  ()=>{

    banners_fetch()


  },[]
)


const delete_handler= (id,banner)=>{


  
            
  axios.delete(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_BANNER_URL}delete/${id}/${banner}`,{
    headers: {
      Authorization: token}
  })
  
  .then((success) => {
    notify(success.data.msg,success.data.status)
    

    if (success.data.status === 1) {
      
        banners_fetch()
        
    }

  })

  .catch((error) => {
    console.error('Error:', error);
  });


}

if(banner?.length!=0){
  return (


    <div className="min-h-screen bg-gray-100 py-6 px-6 flex flex-col justify-center sm:py-3">

    <div className="relative py-3 ">
      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-10">
        <div className="max-w-full mx-auto">
          <h1 className="text-3xl font-bold text-center mb-6">banners list List</h1>
          <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
  <thead>
    <tr>
    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Banners</th>
      


      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th>
    </tr>
  </thead>

  <tbody className="bg-white divide-y divide-gray-200">
    {banner?.map((data, index) => (
      <tr key={index}>



<td className="px-6 py-4 whitespace-nowrap">
        <div className=' flex flex-wrap gap-2'>

{
  data?.banner.map(
    (data)=>{
      return(
        
          <img src={process.env.REACT_APP_API_IMAGE_URL + "college_banners/" + data} alt="College Logo" className=" w-[50px] h-[50px]" />
        
      )
    }
    
  )
}
</div>       
        </td>
                
      
        <td className="px-6 py-8 items-center whitespace-nowrap flex gap-2 ">
         
          <button className='px-3 py-1 font-semibold rounded border shadow-md hover:text-white hover:bg-red-500' onClick={()=>delete_handler(data._id,JSON.stringify(data.banner))}>Delete</button>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <button className='px-3 py-1 font-semibold rounded border  hover:shadow-lg'>View</button>
        </td>
      </tr>
    ))}
  </tbody>

</table>

          </div>
        </div>
      </div>
    </div>
  </div>
 
  )}

  else{

    return(
      <div> Please add Bannerss</div>
    )
  }
}
