import React from 'react'

export default function CollegeBanner1st({banner}) {

  return (
   <img 
   src={`${process.env.REACT_APP_API_BASE_URL}image/college_banners/${banner}`}
   loading="lazy" 

    alt="" 

    />
  )

}
