import React, { useState } from "react";

function UniFaq({ collegeDetails }) {
  const [activeIndex, setActiveIndex] = useState(false);

  return (
    <>
      <section className="text-white w-full py-12 pb-[6rem]">
        <div className="container mx-auto">
          <p className="font-bold text-white md:text-4xl text-xl text-center py-5">
            Let`s clear some doubts
          </p>
          <div className="grid gap-2 rounded-md ">
            <div className="col-span-2 px-2">
              {collegeDetails?.doubts?.map((doubts, index) => (
                <div>
                  <div
                    className={`border border-red-300 p-4 mb-3 flex items-center ${
                      activeIndex == index ? "text-[#1BCCBA]" : "text-[#fff] "
                    }`}
                    onClick={() => setActiveIndex(index)}
                  >
                    <span className="hover:text-[0D1282] text-white font-bold md:text-xl text-sm">
                      {doubts.doubt}
                    </span>
                    <span className="text-[#1BCCBA] ml-auto">
                      {activeIndex == index ? "-" : "+"}
                    </span>
                  </div>

                  <div
                    className={`p-2 border mb-3 border-red-300 rounded-md ${
                      activeIndex == index ? "block" : "hidden"
                    }`}
                  >
                    {/* Content for the 1st question */}
                    <ul className="list-disc px-6 font-light">
                      <li className="md:text-2xl text-[12px]">{doubts.answer}</li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UniFaq;
