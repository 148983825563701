import React, { useContext, useEffect } from "react";

import { Context } from "../../../../../Context_holder";

// import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";


const Top10College = () => {
  
  const { top10College, college_fetch_by_ratings,rounded_rating,setcollege_name } = useContext(Context);

  const navigater=useNavigate()

  useEffect(() => {
    college_fetch_by_ratings();

  }, []);


  const college_handler = (college_name) => {

    setcollege_name(college_name)
  
  
    navigater("/allUniversity");
  
    
  
  };


  return (
    <div className="w-full flex flex-col items-center shadow-md rounded-lg md:mt-[8rem] mt-[1rem]">
  <div className="md:w-[90%] w-full" >
    <div className="md:text-2xl text-xl font-semibold mb-4  text-green-300  text-center">
      TOP 10 COLLEGE
    </div>
    <div className="w-full">
      <table className="w-full border border-gray-300 rounded-lg shadow-lg table-auto">
        <thead className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <tr>
            <th className="py-3 px-2 md:px-6 text-left md:text-center text-sm md:text-xl">Rank</th>
            <th className="py-3 px-2 md:px-6 text-left md:text-center text-sm md:text-xl">College Name</th>
            <th className="py-3 px-2 md:px-6 text-left md:text-center text-sm md:text-xl">Location</th>
            <th className="py-3 px-2 md:px-6 text-left md:text-center text-sm md:text-xl">Rating</th>
          </tr>
        </thead>
        <tbody className="hover:text-black">
          {top10College?.map((college, index) => (
            <tr 
              key={index}

              className="border-b  border-2 cursor-pointer hover:bg-gray-100 hover:text-black"
              onClick={()=>college_handler(college?.college_name)}
              
            >
              <td className="py-4 px-2 md:px-6  font-medium bg-black text-white text-center">
                # {index + 1}
              </td>
              <td className="py-4 px-2 md:px-6 text-black md:text-[17px]  text-[13px] hover:text-black text-start">
                {college.college_name}
              </td>
              <td className="py-4 px-2 md:px-6 text-black md:text-[17px] text-[13px] hover:text-black text-start">
                {college.state} - {college.city}
              </td>
              <td className="py-4 px-2 md:px-6 text-black md:text-[17px] text-[13px] font-semibold text-start">
                {rounded_rating(college.averageRating)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
</div>

  );
};

export default Top10College;
