import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ReactStarsRating from "react-awesome-stars-rating";
import { FaDownload } from "react-icons/fa";
import { Context } from "../../../../../Context_holder";
import Dropdown from "../../DropDown/DropDown";
import { IoClose } from "react-icons/io5";
import axios from "axios";

function AllUniversity() {
  const {
    college_fetch,
    colleges,
  
    setstream_name,
    setcourse_name,
    course_name,
    college_name,
    setcollege_name,
    stream_name,
    college_type,
    setcollege_type,
    college_state,
    setcollege_state,
    college_city,
    setcollege_city,
    course_time,
    setcourse_time,
    duration,
    setduration,
    selectedFeesRange,
    setselectedFeesRange,
    specialisation,
    setspecialisation,
    collegeName,
    setCollegeName,
    courseName,
    setCourseName,
  
    setsearchbar,
   
    rounded_rating,
    ScrollComponent,
    setapply_popUpIsOpen, user, setuserSignUp_popup,filterHeading
  } = useContext(Context);

  const navigater = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [enableFilters, setEnableFilters] = useState([]);

  // State for managing the number of colleges displayed
  const [collegesDisplayed, setCollegesDisplayed] = useState(5);
  const [isFilterVisible, setIsFilterVisible] = useState(false);


 

const enquiry_api = (id) => {
  if (user) {
    axios
      .patch(
        process.env.REACT_APP_API_BASE_URL +
          process.env.REACT_APP_USER_URL +
          "college_edit/" +
          user?._id +
          "/" +
          id
      )
      .then((success) => {
        if (success.data.status === 1) {
          setapply_popUpIsOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } 
  else {
    setuserSignUp_popup(true);
  }
};

  useEffect(() => {
    const query = {};
    const filters = [];

    if (course_name !== "") {
      localStorage.setItem("course_name", course_name);
      query.course_name = course_name;

      filters.push({
        label: "Course ",
        state: setcourse_name,
        local_storage: { name: "course_name" },
        value: course_name,
      });
    }

    if (course_time !== "") {
      localStorage.setItem("course_time", course_time);
      query.course_time = course_time;
      filters.push({
        label: "Course Type",
        state: setcourse_time,
        local_storage: { name: "course_time" },
        value: course_time,
      });
    }

    if (stream_name !== "") {
      localStorage.setItem("stream_name", stream_name);
      query.stream_name = stream_name;
      filters.push({
        label: "stream",
        state: setstream_name,
        local_storage: { name: "stream_name" },
        value: stream_name,
      });
    }

    if (college_name !== "") {
      localStorage.setItem("college_name", college_name);
      query.college_name = college_name;
      filters.push({
        label: "college",
        state: setcollege_name,
        local_storage: { name: "college_name" },
        value: college_name,
      });
    }

    if (college_state !== "") {
      localStorage.setItem("college_state", JSON.stringify(college_state));
      query.college_state = college_state.value;
      filters.push({
        label: "state",
        state: setcollege_state,

        local_storage: { name: "college_state" },

        value: college_state?.value,
      });
    }

    if (college_city !== "") {

      localStorage.setItem("college_city", JSON.stringify(college_city));

      query.college_city = college_city.value;

      filters.push({
        label: "city",
        state: setcollege_city,
        local_storage: { name: "college_city" },
        value: college_city.value,
      });
    }

    if (college_type != "") {
      localStorage.setItem("college_type", college_type);
      query.college_type = college_type;
      filters.push({
        label: "college type",
        state: setcollege_type,
        local_storage: { name: "college_type" },
        value: college_type,
      });
    }

    if (duration != null) {
      localStorage.setItem("duration", duration);
      query.duration = duration;
      filters.push({
        label: "duration",
        state: setduration,
        local_storage: { name: "duration" },
        value: duration,
      });
    }

    if (selectedFeesRange?.max != null || selectedFeesRange?.min != null) {
      
      localStorage.setItem("max_fees", selectedFeesRange?.max);
      localStorage.setItem("min_fees", selectedFeesRange?.min);
      query.min_fees = selectedFeesRange?.min;
      query.max_fees = selectedFeesRange?.max;

      filters.push({
        label: "Fees",
        state: setselectedFeesRange,
        local_storage: {
          name: "",
          subname: { first: "max_fees", second: "min_fees" },
        },
        value:` ${selectedFeesRange?.min}`- `${selectedFeesRange?.max}`,
      });
    }

    if (specialisation !== "") {
      localStorage.setItem("specialisation", specialisation);
      query.specialisation = specialisation;
      filters.push({
        label: "Specialisation",
        state: setspecialisation,
        local_storage: { name: "specialisation" },
        value: specialisation,
      });
    }

    setCourseName(course_name);
    setCollegeName(college_name);
    setSearchParams(query);
    college_fetch(null, window.location.search.toString());
    setEnableFilters(filters);
    ScrollComponent()
  }, [
    course_name,
    course_time,
    stream_name,
    college_name,
    college_state,
    college_city,
    college_type,
    duration,
    specialisation,
    selectedFeesRange,
  ]);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prev) => !prev);
  };
 

  const handleSearch = () => {
    setcollege_name(collegeName);
    setcourse_name(courseName);
    navigater("/allUniversity");
    setsearchbar(false);
  };

  const loadMoreColleges = () => {
    setCollegesDisplayed((prevCount) => prevCount + 5);
  };

  const filters_remove_Handler = (data) => {
    if (data.label == "duration") {
      localStorage.removeItem(data.local_storage.name);
      data.state(null);
      setEnableFilters(
        enableFilters?.filter((filters) => {
          if (filters.label != data.label) {
            return true;
          } else {
            return false;
          }
        })
      );
    } else if (data.local_storage.name != "") {
      localStorage.removeItem(data.local_storage.name);
      data.state("");
      setEnableFilters(
        enableFilters?.filter((filters) => {
          if (filters.label != data.label) {
            return true;
          } else {
            return false;
          }
        })
      );
    } else {
      localStorage.removeItem(data.local_storage.subname.first);
      localStorage.removeItem(data.local_storage.subname.second);
      data.state("");
      setEnableFilters(
        enableFilters?.filter((filters) => {
          if (filters.label != data.label) {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  };

 

  return (
    <div className="w-full py-10 bg-white text-black" id="page_on_the_top">
    <div className="flex flex-col sm:flex-row items-center p-4">
      <div
        className="relative border border-gray-300 bg-white w-full rounded-md md:rounded-full py-4 px-4 sm:px-7 
          sm:w-[80%] md:w-[70%] lg:w-[60%] mx-auto"
      >
        <div className="flex flex-col sm:flex-row flex-wrap gap-4">
          <div className="flex-1 flex relative">
            <input
              type="text"
              defaultValue={college_name}
              value={collegeName}
              onChange={(e) => setCollegeName(e.target.value)}
              placeholder="Enter College Name"
              className="p-2 flex-1 focus:outline-none rounded-xl md:rounded-r-full w-full"
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <div className=" h-full"></div>
            </div>
          </div>
  
          <input
            type="text"
            defaultValue={course_name}
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            placeholder="Enter Course Name"
            className="p-2 flex-1 focus:outline-none rounded-md md:rounded-r-full w-full"
          />
  
          <button
            onClick={handleSearch}
            className="py-2 px-4 sm:px-5 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition w-full sm:w-auto"
          >
            Search
          </button>
        </div>
      </div>
    </div>


{/* ----------------------------------------------------- */}
    <div className=" px-4 md:text-[2rem] text-xl text-center">
      <span className="gap-2 ">
       <span>Top College </span> 
      {college_city&&filterHeading?` ${filterHeading}(${college_city.value})`:"" }
      </span>

      <span>
      {course_name&& filterHeading? `${filterHeading}(${course_name})`:"" }
      </span>
      
      </div>
{/* ----------------------------------------------------- */}
   
  
    {/* filter views section start */}
    <div className="flex flex-wrap gap-2 container mx-auto mt-6">
      {enableFilters?.map((data) => (
        <div className="border py-1 px-2 rounded-md shadow-sm flex items-center gap-2 relative bg-yellow-500">
          <div>
            <div className="text-red">{data?.label}</div>
            <div className="capitalize font-semibold text-black">
              {data?.value}
            </div>
          </div>
  
          <button onClick={() => filters_remove_Handler(data)}>
            <IoClose className="text-black mt-[-1.4rem] transition duration-300 ease-in-out" />
          </button>
        </div>
      ))}
    </div>
    {/* filter views section end */}
  
    <div className="container mx-auto cursor-pointer">
      <div className="flex flex-col md:flex-row justify-between gap-3 mt-12">
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <div className="relative w-full border bg-black text-blue-300 py-4" onClick={toggleFilterVisibility}>
            <h1 className="text-center text-2xl font-semibold">Choose By Filter</h1>
            <div
              className="absolute left-1/2 transform -translate-x-1/2 bottom-[-30px] w-[10px] h-[10px] border-l-8 
                border-r-8 border-t-8 border-transparent border-t-blue-300"
            ></div>
          </div>
  
          <div className={`bg-white text-black shadow-xl px-3 py-5 my-8 ${isFilterVisible ? 'block md:block' : 'hidden md:block'}`}>
            <div className="flex justify-between items-center px-5 py-5 bg-[#18092f] text-white shadow-xl">
              TOTAL {colleges?.college?.length}
            </div>
            <div className="h-[500px] overflow-y-scroll transition duration-300 ease-in-out">
              <Dropdown />
            </div>
          </div>
        </div>
        
        <div className="w-full md:w-3/4">
          <div className="flex flex-wrap justify-center gap-4">
            {colleges?.slice(0, collegesDisplayed)?.map((data, index) => (
              <div key={index} className="w-full sm:mx-0 mx-2 sm:w-[49%] md:w-[40%] text-xl font-bold pb-3 mb-5">
                <div className="w-full rounded-md overflow-hidden shadow-2xl transition">
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL}image/college_logo/${data.university_banner[0]}`}
                    className="w-full h-48 object-cover"
                    alt={`${data.college_name} Banner`}
                  />
                  <div className="bg-[#29349b] text-white shadow-lg px-4 py-4">
                    <h2 className="text-xl font-bold mb-2">{data.college_name}</h2>
                    <div className="flex items-center space-x-2 mb-4">
                      <ReactStarsRating
                        isEdit={false}
                        value={rounded_rating(data.avgCollegeRating) ?? 0}
                        className="flex items-center"
                      />
                      <span className="text-sm text-white transition animate-bounce">
                        {rounded_rating(data.avgCollegeRating)}/5
                      </span>
                    </div>
  
                    <div className="flex items-center space-x-2 mb-4">
                      <h5 className="text-sm bg-red-900 text-white rounded-md px-4">{data.affiliatedTo}</h5>
                      <span className="flex items-center gap-1 bg-green-200 text-green-700 text-sm px-2 py-1 rounded">
                        <FaDownload size={20} />
                        Download
                      </span>
                    </div>
  
                    <div className="flex justify-between border-t border-gray-200 pt-3 mt-3">
                      <Link to={`/university-page/${data._id}`} className="text-xl font-bold">
                        <span>More</span>
                      </Link>
  
                      <button
                        onClick={()=>enquiry_api(data?._id)}
                        className="bg-yellow-400 text-black py-1 px-4 rounded hover:bg-blue-600 hover:text-white transition duration-200"
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {collegesDisplayed < colleges.length && (
            <button
              onClick={loadMoreColleges}
              className="mt-5 py-2 px-5 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition"
            >
              Load More Colleges
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default AllUniversity;