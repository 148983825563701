import React, { useContext } from "react";
import { FaBookReader, FaGraduationCap, FaWhatsapp } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import { PiStudentBold } from "react-icons/pi";
import MotionText from "../../extra/motionText/index";
import StarRatings from "react-star-ratings";
import { Context } from "../../../../../Context_holder";
import axios from "axios";


function UniversityBanner({ collegeDetails }) {

  const { setapply_popUpIsOpen, user, setuserSignUp_popup } =

    useContext(Context);


  const enquiry_api = () => {
    if (user) {
      axios
        .patch(
          process.env.REACT_APP_API_BASE_URL +
            process.env.REACT_APP_USER_URL +
            "college_edit/" +
            user?._id +
            "/" +
            collegeDetails._id
        )
        .then((success) => {
          if (success.data.status === 1) {
            setapply_popUpIsOpen(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } 
    else {
      setuserSignUp_popup(true);
    }
  };


  return (
    <section className="relative  bg-[#18092f]  text-white py-8  w-full">
      <div className=" md:w-[90%]  w-full mx-auto flex flex-col lg:flex-row items-center justify-between mt-[2rem]">
        {/* Text Section */}
        <div className="lg:w-1/2  text-left px-4 ">
          <div className=" md:text-[2rem] font-bold md:mb-6 mb-3 ">
            <p className="text-4xl font-playpen">
              {" "}
              {collegeDetails.college_name}
            </p>
          </div>

          <div className=" flex    md:mt-1 gap-2">
            <div>Approved By</div>{" "}
            <div className="font-bold  font-bold text-[xl] gap-2 bg-white text-black px-2 rounded-xl ">
              {collegeDetails.affiliatedTo}
            </div>
          </div>

          <div className="mb-2 md:flex mt-2">
            <div className="flex flex-col lg:flex-row lg:space-x-4 md:text-left  items-start ">
              <div className="flex items-start justify-center lg:justify-start space-x-2 mb-2 lg:mb-0  ">
                <svg
                  className="w-6 h-6 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 16h.01M12 16h.01M16 16h.01M9 20h6M5 8l7-7 7 7M5 8v12a2 2 0 002 2h10a2 2 0 002-2V8"
                  ></path>
                </svg>
                <span className="md:text-[15px] text-sm">
                  Lifetime Courses From Top Instructor
                </span>
              </div>
              <div className="flex items-center justify-center lg:justify-start space-x-2">
                <svg
                  className="w-6 h-6 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 12c2.28 0 4.34.69 6.04 1.87M12 16v4m0-4h-4m4 0h4m-6-6a4 4 0 118 0 4 4 0 01-8 0zm0-4a4 4 0 100 8 4 4 0 000-8zM12 14l3 2-3 2"
                  ></path>
                </svg>
                <span className="md:text-[15px] text-sm">Over 15,000 Courses</span>
              </div>
            </div>
          </div>

          <p className="mb-8 md:text-lg  text-md text-gray-300  ">
            Whether you're looking to start a new career 
            our courses will help you achieve your goals.
          </p>

          <div className="flex  w-full gap-1 mb-4 ">
            <button className="bg-yellow-400 md:text-sm text-[12px] md:mb-4 text-black font-semibold md:py-3 md:px-8 px-4 py-2 rounded-full 
            hover:bg-yellow-500 transition duration-300" onClick={enquiry_api}>
              Get Started for Apply
            </button>
            <div className="flex md:gap-2  gap-[10px] items-center justify-center lg:justify-start ml-4 ">
              <StarRatings
                rating={collegeDetails.avgCollegeRating ?? 0}
                starRatedColor="gold"
                numberOfStars={5}
                starDimension="20px"
                starSpacing="2px"
                readonly
              />
              <div className="text-white text-sm md:text-xl">
                (reviews {collegeDetails.collegeReviews.length})
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-start  lg:justify-start gap-4 mb-8">
            <button
              type="button"
              className="focus:outline-none text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5"
            >
              <a
                className="text-white no-underline"
                href={`tel:+91${collegeDetails.contactNumber}`}
                rel="noopener noreferrer"
              >
                Call now
              </a>
            </button>
            <button
              type="button"
              className="focus:outline-none text-white bg-green-500 hover:bg-green-600 font-medium rounded-lg text-sm px-5 py-2.5"
            >
              <a
                className="text-white flex items-center gap-1 no-underline"
                href={`https://wa.me/${collegeDetails.contactNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-white text-sm ">Chat now </span> <FaWhatsapp />
              </a>
            </button>
          </div>
        </div>

        {/* Image Section */}
        <div className="lg:w-1/2 flex justify-center lg:justify-end mt-10 lg:mt-0 px-4">
          <img
            src={`${process.env.REACT_APP_API_BASE_URL}image/college_logo/${collegeDetails.university_banner[0]}`}
            alt="Student with Laptop"
            className="w-full max-w-lg rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
}

export default UniversityBanner;
