

import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import { Link } from 'react-router-dom';


export default function Website_news_view() {

const{website_news_fetch,website_news,token,notify}=useContext(Context)


useEffect(

  ()=>{

    website_news_fetch()

  },[]
)



const delete_handler= (id,logo,news_media_logo)=>{


  
            
  axios.delete(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_WEBSITE_NEWS_URL}delete/${id}/${logo}/${news_media_logo}`,{
    headers: {
      Authorization: token}
  })
  
  .then((success) => {
    notify(success.data.msg,success.data.status)

    if (success.data.status === 1) {
      
        website_news_fetch()

    }

  })

  .catch((error) => {
    console.error('Error:', error);
  });


}

if(website_news?.length!=0){
  return (


    <div className="min-h-screen bg-gray-100 py-6 px-6 flex flex-col justify-center sm:py-3">

    <div className="relative py-3 ">
      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-10">
        <div className="max-w-full mx-auto">
          <h1 className="text-3xl font-bold text-center mb-6">News List</h1>
          <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
  <thead>
    <tr>

      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Logo</th>
     
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Heading</th>
      

      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">News Media</th>

      
     
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th>
    </tr>
  </thead>
  

  <tbody className="bg-white divide-y divide-gray-200">
    {website_news?.map((data, index) => (
      <tr key={index}>
        <td className="px-6 py-4 whitespace-nowrap">
          <img src={process.env.REACT_APP_API_IMAGE_URL + "website_news_photo/"+data.logo} alt=" Logo" className="w-16 h-16 rounded-full" />
        </td>
       
       
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{data.heading}</div>
        </td>


        <td className="px-6 py-4 whitespace-nowrap">
            
        <img src={process.env.REACT_APP_API_IMAGE_URL+"news_media_image/"+data?.news_media_logo} alt=" Logo" className="w-16 h-16 rounded-full" />
        </td>
        
      
        <td className="px-6 py-8 items-center whitespace-nowrap flex gap-2 ">
          
          <button className='px-3 py-1 font-semibold rounded border shadow-md hover:text-white hover:bg-red-500' onClick={()=>delete_handler(data._id,data.logo, data.news_media_logo)}>Delete</button>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <button className='px-3 py-1 font-semibold rounded border  hover:shadow-lg'>View</button>
        </td>
      </tr>
    ))}
  </tbody>

</table>

          </div>
        </div>
      </div>
    </div>
  </div>
 
  )
}
else {
  return(
    <div >Please add Website News</div>
  )
}
  
  
}


