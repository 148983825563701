import React, { useContext } from "react";
import { FaArrowRight, FaGraduationCap } from "react-icons/fa6";
import parse from 'html-react-parser';
import { Context } from "../../../../../Context_holder";
import { Link } from "react-router-dom";

function OurEvent({ collegeDetails }) {
  const { monthNames } = useContext(Context);

  return (
    <div className="w-full py-5 px-4 lg:px-0 flex justify-center md:mt-[8rem] mt-2 md:mb-[3rem] mb-[1rem]">
      <div className="container mx-auto">
        <div className="text-center py-3">
          <p className="font-bold text-md flex gap-1 items-center justify-center text-red-400 mb-0">
            <FaGraduationCap />
            Our Event
          </p>
          <h2 className="font-bold text-2xl">Upcoming Events</h2>
        </div>
        <div className="flex flex-wrap justify-between">
          {collegeDetails?.events?.map((data, index) => (
            <Link
              to={`/college_event/${collegeDetails._id}`}
              key={index}
              className="w-full md:w-[48%] lg:w-[30%] mb-4 group"
            >
              <div className="overflow-hidden relative">
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}image/event_image/${data.logo}`}
                  alt={data.alt}
                  className="w-full h-[200px] object-cover group-hover:scale-110 transition-transform duration-1000"
                />
                <div className="absolute top-2 left-2 bg-[#ff7350] p-2 text-white rounded-md">
                  <h4 className="mb-0 text-lg">{new Date(data.date).getDate()}</h4>
                  <p className="font-thin mb-0 text-sm">{monthNames[new Date(data.date).getMonth()]}, {new Date(data.date).getFullYear()}</p>
                </div>
              </div>
              <div className="py-4 px-4 bg-white text-black group-hover:bg-[#125875] group-hover:text-[#96bece] rounded-md">
                <div className="flex justify-between">
                <h5 className="font-bold group-hover:text-white text-lg">{data.heading}</h5>
                <div className="flex items-center gap-2 text-sm ">
                  <p className="font-semibold text-yellow-700 group-hover:text-[#96bece]">{data.time}</p>
                  <FaArrowRight className="text-[#777] group-hover:text-[#96bece]" />
                  <p className="text-red-400 group-hover:text-white font-semibold">{data.location}</p>
                </div>
                </div>
               
                <p className="font-thin text-sm line-clamp-3">{data?.description && parse(data?.description)}</p>
                {data?.description?.length > 100 && (
                  <p className="text-blue-500 cursor-pointer mt-2">... <span className="font-semibold">More</span></p>
                )}
              
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurEvent;
