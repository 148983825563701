import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Context } from '../../../../Context_holder';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
const cities= require("../../../../Json_files/Cities.json")
 const states= require("../../../../Json_files/States.json")

export default function College_edit() {

    const {selected_state,setselected_state,selected_city,setselected_city,statemenuIsOpen,citymenuIsOpen,search_cities, setsearch_cities,handlestateInputChange,handlecityInputChange,college_fetch,currenetcolleges,quill_value,setquill_value,fact_value,setfact_value,professor,setprofessor,token,notify}= useContext(Context)

    

const [logo_value,setlogo_value] = useState(null)
const [campus_images_value,setcampus_images_value] = useState(null)
const [pdf_value,setpdf_value] = useState(null)
const [office_photo_value,setoffice_photo_value] = useState(null)

const[current_data,setcurrent_data]=useState(null)

 const {id} = useParams()

const pdf_ref= useRef()
const logo_ref= useRef()
const campus_ref= useRef()
const office_photo_ref= useRef()


 useEffect(
    ()=>{
        
        college_fetch(id)

    },

    [id]
 )

 useEffect(
()=>{
setcurrent_data(currenetcolleges)
        setselected_city({ label:currenetcolleges?.city, value:currenetcolleges?.city})
        setselected_state({label:currenetcolleges?.state,value:currenetcolleges?.state})
        setquill_value(currenetcolleges?.about)
    setfact_value(currenetcolleges?.facts)
    setprofessor(currenetcolleges?.professor)
        
    },[currenetcolleges]
 )


  useEffect(
    ()=>{


      const search_city= cities.filter(
        data=> {
          if( data.state==selected_state?.value){
            return true
          }
    
          else  return false
        }
      )
      

      setsearch_cities(search_city)
    },[selected_state]
  )
 

const edit_handler = (e)=>{

    e.preventDefault();
    
   
 const college_name= e.target.college_name.value
 const estdYear= e.target.estdYear.value
 const affiliatedTo = e.target.affiliatedTo.value.split("$").filter(a => a !== "");
 const type= e.target.type.value
 const programmesOffered = e.target.programmesOffered.value.split("$").filter(a => a !== "");
 const director= e.target.director.value
 const contactNumber= e.target.contactNumber.value
 const officialWebsite= e.target.officialWebsite.value
 const address= e.target.address.value
 const about= quill_value
 const facts = e.target.facts.value.split("$").filter(a => a !== "");
 const state= selected_state?.value 
 const city= selected_city?.value 
 const education_loan= e.target.education_loan.value
    const loan_contact=e.target.loan_contact.value
   

    
    const about_professor= professor

    const registered_instructors=e.target.registered_instructors.value
 
 const data = {
    college_name: college_name,
    estdYear: estdYear,
    affiliatedTo: affiliatedTo,
    type: type,
    facts:facts,
    programmesOffered: programmesOffered,
    director: director,
    contactNumber: contactNumber,
    officialWebsite: officialWebsite,
    address: address,
    about: about,
    professor:about_professor,
    state: state,
    city: city,
    education_loan:education_loan,
    loan_contact:loan_contact,
    registered_instructors:registered_instructors,  
  };
 
  console.log(data);
  
 
 

  axios.put(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COLLEGE_URL}edit/${id}`,data,{
    headers: {
      Authorization: token}
  })
  .then((success) => {
   console.log(success);
   
   notify(success.data.msg,success.data.status)

    if (success.data.status ===1) {
      e.target.reset();

      college_fetch(id)
     
      setselected_city(null)
      setselected_state(null)
   

    }
  })

  .catch((error) => {
    console.error('Error:', error);
  });


}

const logo_handler = ()=>{

const logo = logo_value




const old_logo=current_data?.university_banner

const id=current_data?._id

const formData = new FormData();


formData.append("old_logo",JSON.stringify(old_logo))


for(let data of logo){

  formData.append("logo",data)
}






  axios.patch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COLLEGE_URL}logo_edit/${id}`,formData,{
    headers: {
      Authorization: token}
  })
  .then((success) => {
   
    notify(success.data.msg,success.data.status)
    if (success.data.status === 1) {
      
    college_fetch(id)
   
      setlogo_value(null)
      logo_ref.current.value=""
    }
  })

  .catch((error) => {
    console.error('Error:', error);
  });


}

const pdf_handler = ()=>{
    const pdf= pdf_value
    const old_pdf=current_data?.pdf
    const id=current_data?._id
    
    const formData = new FormData();
    
    
    formData.append("pdf",pdf)
    formData.append("old_pdf",old_pdf)
    
    
    
    
      axios.patch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COLLEGE_URL}pdf_edit/${id}`,formData,{
        headers: {
          Authorization: token}
      })
      .then((success) => {
       
        notify(success.data.msg,success.data.status)
        if (success.data.status === 1) {
          college_fetch(id)
        
          setpdf_value(null)
          pdf_ref.current.value=""

    
        }
      })
    
      .catch((error) => {
        console.error('Error:', error);
      });
    
    
    }




    const campus_images_handler = ()=>{

        const campus_images= campus_images_value
        const old_campus_images=current_data?.campus_images
        const id=current_data?._id
        
        const formData=new FormData();
        
        
       
        for(let data of campus_images){

            formData.append("campus_images",data)
        }


        formData.append("old_campus_images",JSON.stringify(old_campus_images))
        
        
        
        
          axios.patch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COLLEGE_URL}campus_images_edit/${id}`,formData,{
            headers: {
              Authorization: token}
          })
          .then((success) => {
           
            notify(success.data.msg,success.data.status)
            if (success.data.status === 1) {

              college_fetch(id)
             
              setcampus_images_value(null)
              campus_ref.current.value=""
        
            }
          })
          
        
          .catch((error) => {
            console.error('Error:', error);
          });
        
        
        }

        
        const office_photo_handler = ()=>{
          const office_photo= office_photo_value
          const old_office_photo=current_data?.office_photo
          const id=current_data?._id

console.log(office_photo,old_office_photo);


          
          const formData = new FormData();
          
          
          formData.append("office_photo",office_photo)
          formData.append("old_office_photo",old_office_photo)
          
          
          
          
            axios.patch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_COLLEGE_URL}office_photo_edit/${id}`,formData,{
              headers: {
                Authorization: token}
            })
            .then((success) => {
             
          console.log(success);
          notify(success.data.msg,success.data.status)
          
              if (success.data.status === 1) {
               
                college_fetch(id)
                setoffice_photo_value(null)
                office_photo_ref.current.value=""
      
          
              }
            })
          
            .catch((error) => {
              console.error('Error:', error);
            });
          
          
          }

       

    return ( 
      

      <div className="min-h-screen py-10 bg-gray-300">
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
        <div className="text-center text-4xl font-bold mb-3">College Edit</div>
        <form onSubmit={edit_handler} encType="multipart/form-data">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college_name">College Name</label>
              <input
                defaultValue={current_data?.college_name}
                id="college_name"
                name="college_name"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="College Name"
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="estdYear">Established Year</label>
              <input
                defaultValue={current_data?.estdYear}
                id="estdYear"
                name="estdYear"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Year"
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="director">Director</label>
              <input
                defaultValue={current_data?.director}
                id="director"
                name="director"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Director"
              />
            </div>
           
          </div>
    
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="education_loan">About Education Loan</label>
            <textarea
              defaultValue={current_data?.education_loan}
              id="education_loan"
              name="education_loan"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Education Loan"
            />
          </div>
           
         

            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">Address</label>
              <textarea
                defaultValue={current_data?.address}
                id="address"
                name="address"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Address"
              ></textarea>
            </div>
          </div>

          <div className='mb-6'>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college_name">About Professor</label>
          <div>
          <ReactQuill value={professor}  onChange={(e)=>setprofessor(e)}/></div> 
        </div>

          <div className='mb-6'>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college_name">About College</label>
          <div>
          <ReactQuill value={quill_value}  onChange={(e)=> setquill_value(e)}/></div> 
        </div>


        <div  className='mb-6'>

<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college_name">Facts with <span className='text-[red]'>($saprated)</span></label>
<div>
<textarea
defaultValue={current_data?.facts.join('$').replace(/,/g, '$')}
  id="facts"
  name="facts"
  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  placeholder="Facts"
/>
  
  </div> 
</div>
    
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
          <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="loan_contact">Education Loan Contact</label>
              <input
                defaultValue={current_data?.loan_contact}
                id="loan_contact"
                name="loan_contact"
                type="tel"
                pattern="[0-9]{10}"
                required
                maxLength="10"
                minLength="10"
                autoComplete="tel"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="+91"
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">Type</label>
              <select
                defaultValue={`${current_data?.type}`}
                id="type"
                name="type"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="" disabled>Select type</option>
                <option value="private">Private</option>
                <option value="public">Public</option>
                <option value="government">Government</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="programmesOffered">Programmes Offered <span className='text-[red]'>($saprated)</span></label>
              <input
                defaultValue={current_data?.programmesOffered.join('$').replace(/,/g, '$')}
                id="programmesOffered"
                name="programmesOffered"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Diploma/Certificate"
              />
            </div>
            
            
            
          </div>
    
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
         

          <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="affiliatedTo">Approved <span className='text-[red]'>($saprated)</span></label>
          <input
          defaultValue={current_data?.affiliatedTo.join('$').replace(/,/g, '$')}
            id="affiliatedTo"
            name="affiliatedTo"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Affiliated To"
          />
        </div>

          <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="registered_instructors"> Registered Instructors</label>
          <input
defaultValue={current_data?.registered_instructors }
            id="registered_instructors"
            name="registered_instructors"
            type="number"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Instructors"
          />
        </div>
        <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactNumber">Contact Number</label>
              <input
                defaultValue={current_data?.contactNumber}
                id="contactNumber"
                name="contactNumber"
                type="tel"
                pattern="[0-9]{10}"
                required
                maxLength="10"
                minLength="10"
                autoComplete="tel"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="+91"
              />
            </div>

          

       </div>
    
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
           
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="officialWebsite">Official Website</label>
              <input
                defaultValue={current_data?.officialWebsite}
                id="officialWebsite"
                name="officialWebsite"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Website"
              />
            </div>
            <div>
              <label htmlFor="state" className="block text-sm font-bold text-gray-700">State</label>
              <Select
                value={selected_state}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    padding: '4px 20px',
                    marginTop: '4px',
                  }),
                }}
                onChange={(e) => { setselected_state(e) }}
                onInputChange={handlestateInputChange}
                menuIsOpen={statemenuIsOpen}
                name="states"
                options={states.map(data => ({ value: data.name, label: data.name }))}
              />
            </div>
            <div>
              <label htmlFor="city" className="block text-sm font-bold text-gray-700">City</label>
              <Select
                value={selected_city}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    padding: '4px 20px',
                    marginTop: '4px',
                  }),
                }}
                onChange={(e) => { setselected_city(e) }}
                onInputChange={handlecityInputChange}
                menuIsOpen={citymenuIsOpen}
                name="cities"
                options={search_cities.map(data => ({ value: data.name, label: data.name }))}
              />
            </div>
          </div>
    
          <div className="flex justify-end mb-4">
            <button
              type="submit"
              className="bg-blue-500 w-full md:w-auto hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save
            </button>
          </div>
        </form>
    
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="logo">Main image</label>
            <input
              ref={logo_ref}
              type="file"
              id="logo"
              name="logo"
              multiple={true}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) => setlogo_value(e.target.files)}
            />
            <button
              className={`hover:bg-blue-950 capitalize bg-blue-700 text-white text-md font-semibold px-3 py-1 rounded-md mt-2 ${logo_value ? "block" : "hidden"}`}
              onClick={logo_handler}
            >
              Update Main Images
            </button>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="campus">Campus Images</label>
            <input
              ref={campus_ref}
              type="file"
              id="campus"
              name="campus"
              multiple
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) => setcampus_images_value(e.target.files)}
            />
            <button
              className={`hover:bg-blue-950 capitalize bg-blue-700 text-white text-md font-semibold px-3 py-1 rounded-md mt-2 ${campus_images_value ? "block" : "hidden"}`}
              onClick={campus_images_handler}
            >
              Update Campus Images
            </button>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pdf">College PDF</label>
            <input
              ref={pdf_ref}
              type="file"
              id="pdf"
              name="pdf"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) => setpdf_value(e.target.files[0])}
            />
            <button
              className={`hover:bg-blue-950 capitalize bg-blue-700 text-white text-md font-semibold px-3 py-1 rounded-md mt-2 ${pdf_value ? "block" : "hidden"}`}
              onClick={pdf_handler}
            >
              Update PDF
            </button>
          </div>


          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="office_photo">Office Photo</label>

            <input
              ref={office_photo_ref}

              type="file"
              id="office_photo"
              name="office_photo"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) => setoffice_photo_value(e.target.files[0])}
            />
            <button
              className={`hover:bg-blue-950 capitalize bg-blue-700 text-white text-md font-semibold px-3 py-1 rounded-md mt-2 ${office_photo_value ? "block" : "hidden"}`}
              onClick={office_photo_handler}
            >
              Update Office Photo
            </button>
          </div>
        </div>
      </div>
    </div>
    
      
      
        );
  
      
}
