import { useState, useEffect, useContext } from "react";
import LoanFec from "./LoanFec/LoanFec";
// import TopBar from "./TopBar/TopBar";
import OurFeatures from "./OurFeatures/OurFeatures";
import OurEvent from "./OurEvent/OurEvent";
import OurBlog from "./OurBlog/OurBlog";
import SearchCourses from "./SearchCourses/SearchCourses";
import Testimonals from "./Testimonals/Testimonals";
// import Footer from "./Footer/Footer";
import Scholarships from "./Scholarships/Scholarships";
import UniversityBanner from "./university-banner";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Courses from "./courses";
import Admission from "./admission/Admission";
// import ConnetQuery from "./contact-query/ConnetQuery";
import HiringPartner from "./hiring-partner/HiringPartner";
import ConnectWithUs from "./connect-withus";
import OurConnection from "./ourconnection";
// import YourComponent from "../extra/aboutGraph";
import Brands from "./brands";
// import InfiAnimation from "../extra/infiAnimation";
import NewsSlider from "../../screens/home/newsSlider";
import UniversityFacilities from "./facilities";
// import UniContact from "./uniContact";
import OfficeAddress from "./officeAddress";
import UniFaq from "./uniFaq";

import { Link, useParams } from "react-router-dom";
import { Context } from "../../../../Context_holder";
import { FeesTable } from "./feesTable/feesTable";
import AboutUniversity from "./aboutUniversity/aboutUniversity";
import UniversityFact from "./universityFact/universityFact";
import ReviewSection from "./review/reviewSection";
import PlacementBarChart from "./placementGraph/placementGraph";
// import SequenceSlider from "../common/sequenceSlider";
// import TestimonialSection from "../common/sequenceSlider";
// import BookComponent from "../common/sequenceSlider";
// import ErrorBoundary from "../../utils/errorBoundary";
import ProfessorsSection from "./professorSection";
// import Apply_popup from "../applyPopup/Apply_popup";
// import { FaCaretRight } from "react-icons/fa";

function UniversityPage() {
  const { college_fetch, currenetcolleges,ScrollComponent,top10College_by_city, top10_college_fetch_by_city ,top10College_by_state,top10_college_fetch_by_state, top10_course_fetch_by_city,top10Courses_by_city,top10_course_fetch_by_state,top10Courses_by_state,setcourse_name,recent_enquiry_by_city,recent_enquiry_fetch_city_wise} = useContext(Context);

  const [uni, setUni] = useState(null);

  const { id } = useParams();

  const [current_data, setcurrent_data] = useState(null);

  useEffect(() => {
    college_fetch(id);
    ScrollComponent()
  }, [id]);

  useEffect(() => {
    setcurrent_data(currenetcolleges);
    top10_college_fetch_by_city(currenetcolleges?.city)
    top10_college_fetch_by_state(currenetcolleges?.state)
    top10_course_fetch_by_city(currenetcolleges?.city)
    top10_course_fetch_by_state(currenetcolleges?.state)
    recent_enquiry_fetch_city_wise(currenetcolleges?.city)
    
 


  }, [currenetcolleges]);

  if (current_data) {
    return (
      <div className="w-full  overflow-hidden text-white " id="page_on_the_top">
        {/* <TopBar/>  */}
        <UniversityBanner id={id} collegeDetails={current_data} />

        <AboutUniversity collegeDetails={current_data} />

        <Courses collegeDetails={current_data} />
        <FeesTable collegeDetails={current_data} />

        <UniversityFact collegeDetails={current_data} />

        <UniversityFacilities collegeDetails={current_data} />
        <Scholarships collegeDetails={current_data} />

        <OurEvent collegeDetails={current_data} />
        <PlacementBarChart id={id} />

        {/* <YourComponent/> */}
        {/* <InfiniteAnimation/> */}
        {/* <ErrorBoundary>
          <OurFeatures />
        </ErrorBoundary> */}

        <Testimonals collegeDetails={current_data} />

        <LoanFec collegeDetails={current_data} />

        {/* <ConnetQuery collegeDetails={current_data} /> */}

        {/* <Container/> */}
        <HiringPartner id={id} collegeDetails={current_data} />

        <OfficeAddress collegeDetails={current_data} />

        <OurConnection collegeDetails={current_data} />

        <Brands />
        <ConnectWithUs />

        <SearchCourses />

        <NewsSlider collegeDetails={current_data} />

        <UniFaq collegeDetails={current_data} />

        {/* <Admission /> */}

        <OurBlog collegeDetails={current_data} />

        <ReviewSection id={id} avgrating={current_data?.avgCollegeRating} />

        <ProfessorsSection faculties={current_data?.faculties}/>

        
     
{/* top 10 college city wise */}




        {/* <UniContact/> */}
        {/* <Footer /> */}
      </div>
    );
  }
}

export default UniversityPage;
