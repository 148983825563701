import React, { useContext, useEffect } from "react";
import Container from "../container/Container";
import InfiAnimation from "../../extra/infiAnimation";
import { Context } from "../../../../../Context_holder";


const HiringPartner = ({ id,collegeDetails}) => {

  const{hiring_partners_fetch,hiring_partners}=useContext(Context)
  useEffect(
    ()=>{
      hiring_partners_fetch(id)
    },[]
  )

  return (
    <div className="w-full flex justify-center">
      <div className="w-[90%]">
      <h4 className="text-start  md:text-[2rem]  text-2xl font-semibold capitalize w-full">
        Top hiring partners of {collegeDetails?.college_name}
      </h4>
      {/* <h1 className='text-center text-[20px]'>Slider</h1> */}
      <InfiAnimation hiring_partners={hiring_partners} collegeDetails={collegeDetails}  className="w-full "/>

      </div>
    
    

    
    </div>
  );
};

export default HiringPartner;
