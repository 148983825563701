

import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import Select from 'react-select';
import axios from 'axios';
import { useParams } from 'react-router-dom';
export default function Scholarship_edit() {

  const{current_scholarship,scholarship_fetch, colleges ,selected_college,setselected_college,token,notify}=useContext(Context)

const {id}= useParams()
const[current_data,setcurrent_data]=useState(null)
  

useEffect(
    ()=>{
        setcurrent_data(current_scholarship)

        setselected_college({label:current_scholarship?.college_id?.college_name,value:current_scholarship?.college_id?._id})
    },
    [current_scholarship]
)


  useEffect(
    ()=>{
  
        scholarship_fetch(id)
 
    
    },[]
  )
 


  const handleSubmit = (e) => {
    e.preventDefault();

    const college_id = selected_college?.value;
    const scholarship_type = e.target.scholarship_type.value;
    const organisation = e.target.organisation.value;
    const application_deadline = e.target.application_deadline.value;
    const no_Of_scholarship = e.target.no_Of_scholarship.value;
    const amount = e.target.amount.value;
    const international_students = e.target.international_students.value;
    const scholarship_link = e.target.scholarship_link.value;
  
    const data = {
      college_id: college_id,
      scholarship_type: scholarship_type,
      organisation: organisation,
      application_deadline: application_deadline,
      no_Of_scholarship: no_Of_scholarship,
      amount: amount,
      international_students: international_students,
      scholarship_link: scholarship_link
    };


 
    

    axios.put(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_SCHOLARSHIP_URL}edit/`+id, data,{
      headers: {
        Authorization: token}
    })
    .then((response) => {
        
     
     notify(response.data.msg,response.data.status)
      if (response.data.status === 1) {
        e.target.reset();
       
        scholarship_fetch(id)
       
      }
    })
    .catch((error) => {
       
      
      
      })
     
  };

  
    return (
        <div className="min-h-screen py-10 bg-gray-300">
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold mb-4">Edit Scholarship</h2>
          <form onSubmit={handleSubmit} className="w-full">
      
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
              <div>
                <label htmlFor="college_id" className="block text-sm font-medium text-gray-700">College ID</label>
                <Select
                  value={selected_college}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      padding: '4px 20px',
                      marginTop: '4px',
                    }),
                  }}
                  onChange={(e) => { setselected_college(e) }}
                  name="college_id"
                  options={colleges?.college?.map(data => ({ value: data._id, label: data.college_name }))}
                />
              </div>
              <div>
                <label htmlFor="" className="block text-sm font-medium text-gray-700">Scholarship Type</label>
                <input
    
    defaultValue={current_data?.scholarship_type }
                  type="text"
                  id="scholarship_type"
                  name="scholarship_type"
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  
                />
              </div>
    
              <div>
                <label htmlFor="" className="block text-sm font-medium text-gray-700">Organisation</label>
                <input
                defaultValue={current_data?.organisation }
                  type="text"
                  id="organisation"
                  name="organisation"
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
    
            
            </div>
      
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
            <div>
                <label htmlFor="" className="block text-sm font-medium text-gray-700">Application Deadline</label>
                <input
    defaultValue={`${new Date(current_data?.application_deadline).getFullYear()}-${String(new Date(current_data?.application_deadline).getMonth() + 1).padStart(2, '0')}-${String(new Date(current_data?.application_deadline).getDate()).padStart(2, '0')}`}

                  type="date"
                  id="application_deadline"
                  name="application_deadline"
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
    
              <div>
                <label htmlFor="" className="block text-sm font-medium text-gray-700">No. Of Scholarship</label>
                <input
                defaultValue={current_data?.no_of_scholarships}
                
                  type="number"
                  id="no_Of_scholarship"
                  name="no_Of_scholarship"
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
    
             
    
              <div>
                <label htmlFor="" className="block text-sm font-medium text-gray-700">Amount</label>
    
                <input
                defaultValue={current_data?.amount }
                  type="number"
                  id="amount"
                  name="amount"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
    
              <div>
      <label className="block text-sm font-medium text-gray-700">International Students</label>
      <div className="mt-1">
        <div className="flex items-center">
          <input
            id="yes"
           
            name="international_students"
            type="radio"
            value={true}
            required
            className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
          />
          <label htmlFor="yes" className="ml-2 block text-sm text-gray-700">
            Yes
          </label>
        </div>
        <div className="flex items-center mt-2">
    
        <input
            id="no"
            name="international_students"
            type="radio"
            value={false}
            required
            className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
          />
    
          <label htmlFor="no" className="ml-2 block text-sm text-gray-700">
            No
          </label>
        </div>
      </div>
    </div>
    
     <div>
                <label htmlFor="" className="block text-sm font-medium text-gray-700">Scholarship Link</label>
    
                <input
                 defaultValue={current_data?.scholarship_link }
                
                  type="text"
                  id="scholarship_link"
                  name="scholarship_link"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
    
              
            </div>
      
            <div className="flex justify-end mb-4">
              <button
                type="submit"
                className="bg-blue-500 w-full md:w-auto hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      
      );

  
}


