import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";
import "./index.css"; // Ensure to include your custom CSS if needed

// Set the root element for the modal
Modal.setAppElement('#root');

const NewsSlider = ({ collegeDetails }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');

  const openModal = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo('');
  };

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full py-5">
      <div className="container mx-auto">
        <div className="md:text-[2rem] text-xl font-bold md:mb-8 mb-4 ml-2">
          Our students' success story
        </div>
        <Slider {...settings}>
          {collegeDetails?.stories?.map((data, index) => (
            <div className="px-2" key={index}>
              <div
                className="bg-[#f0f7ff] py-4 px-4 hover:bg-white hover:shadow-xl flex flex-col items-center rounded-lg border border-gray-200 cursor-pointer"
                onClick={() => openModal(data.video_url)}
              >
                <iframe
                  className="w-full h-[13rem] object-cover rounded-lg mb-2"
                  src={data.video_url}
                  title="Video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <p className="text-center my-2 font-bold text-lg text-black">
                  {data.title} {/* Replace this with a suitable property from your data */}
                </p>
                <p className="text-center my-1 font-thin text-sm">
                  {data.description} {/* Replace this with a suitable property from your data */}
                </p>
              </div>
            </div>
          ))}
        </Slider>

        {/* Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Video Modal"
          className="modal"
          overlayClassName="overlay"
          shouldCloseOnOverlayClick={true} // Close when clicking outside the modal
        >
          <button onClick={closeModal} className="modal-close-button">✕</button>
          <iframe
            className="modal-iframe"
            src={currentVideo}
            title="Video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Modal>
      </div>
    </div>
  );
};

export default NewsSlider;
