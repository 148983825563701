




import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context_holder';
import Select from 'react-select';
import axios from 'axios';
export default function Stream_add() {

  const{token,notify}=useContext(Context)


  const handleSubmit = (e) => {
    e.preventDefault();
  
 
    const stream_name = e.target.stream_name.value;
   
    const image= e.target.image.files[0]
  
    const formData = new FormData();


formData.append('stream_name', stream_name);

formData.append('image', image); 
 

    axios.post(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_STREAM_URL}add`, formData,{
      headers: {
        Authorization: token}
    })
    .then((response) => {
      notify(response.data.msg,response.data.status)
    
    

      if (response.data.status === 1) {

        

        e.target.reset();
       
      }
    })
    .catch((error) => {
       
      
      
      })
     
  };

  return (
    <div className="min-h-screen py-10 bg-gray-300">
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4">Add New Stream</h2>
      <form onSubmit={handleSubmit} className="w-full">
  
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
         
          <div>
            <label htmlFor="stream_name" className="block text-sm font-medium text-gray-700">Stream Name</label>
            <input
              type="text"
              id="stream_name"
              name="stream_name"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div>
            <label htmlFor="" className="block text-sm font-medium text-gray-700">Image</label>
            <input
              type="file"
              id="image"
              name="image"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>

        </div>
  
        <div className="flex justify-end mb-4">
          <button
            type="submit"
            className="bg-blue-500 w-full md:w-auto hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add 
          </button>
        </div>
      </form>
    </div>
  </div>
  
  );
}

