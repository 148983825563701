



import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Context } from '../../../../Context_holder';
import axios from 'axios';

export default function Placed_students_view() {

  const { placed_students_fetch,placed_students,token,notify}= useContext(Context)

const delete_handler=(id,old_logo)=>{


  axios.delete(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_PLACED_STUDENTS_URL+"delete/"+id+"/"+old_logo,{
    headers: {
      Authorization: token}
  })
  .then((success) => {
    notify(success.data.msg,success.data.status)
    

    if (success.data.status === 1) {

        placed_students_fetch()
       
    }
    
  })
  .catch((error) => {
   
    
    }
    
    
    )


}
  

useEffect(
    ()=>{
        placed_students_fetch()
    },
    []
)

if(placed_students){
    return (
        <div className="min-h-screen bg-gray-100 py-6 px-6 flex flex-col justify-center sm:py-3">

    <div className="relative py-3 ">

      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-10">
        <div className="max-w-full mx-auto">
          <h1 className="text-3xl font-bold text-center mb-6">Placed Students List</h1>
          <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">

  <thead>
    <tr>
      
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Student Name</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">College name</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Review</th>
     
      
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th>
    </tr>
  </thead>
  <tbody className="bg-white divide-y divide-gray-200">

    {placed_students?.map((data, index) => (
      <tr key={index}>
       
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-900">{data.name}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{data.college_id?.college_name}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{data.company} </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-500">{data.review} </div>
        </td>
       

      

    


        <td className="px-6 py-8 items-center whitespace-nowrap flex gap-2 ">
          
          <button className='px-3 py-1 font-semibold rounded border shadow-md hover:text-white hover:bg-red-500' onClick={()=>delete_handler(data._id,data.student_image)}>Delete</button>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <button className='px-3 py-1 font-semibold rounded border  hover:shadow-lg'>View</button>
        </td>
      </tr>
    ))}
  </tbody>
  
</table>

          </div>
        </div>
      </div>
    </div>
  </div>
 
    );
}

   
}
