import React, { useContext, useEffect } from "react";
import { AiFillHome, AiFillProfile } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { FaUniversity } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoCall } from "react-icons/io5";

import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaPhoneAlt,
  FaEnvelope,
  FaLocationArrow,
} from "react-icons/fa";
// import image1 from "./s-blogimg-01.png";
// import image2 from "./s-blogimg-02.png";
import NewsLetter from "../../../screens/home/newsLetter";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../../../Context_holder";
import apple from "./app-store-logo.png";
import play from "./play.webp";

import { format } from "date-fns";

function Footer2({ setsearchbar }) {
  const items = ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"];
  const {
    website_blog_fetch,
    website_blog,
    best_colleges_in_cities_array,
    setcourse_name,
    setcollege_city,
    best_colleges_in_courses_array,
    best_colleges_in_pg_courses_array,
    setfilterHeading,
  } = useContext(Context);
  useEffect(() => {
    website_blog_fetch();
  }, []);

  const navigater = useNavigate();

  const best_college_handler = (city) => {
    setcollege_city({ value: city });
    setfilterHeading("Best Colleges in");

    navigater("/allUniversity");
  };

  const ugc_course_handler = (course_name) => {
    setcourse_name(course_name);

    setfilterHeading("Best Colleges for");
    navigater("/allUniversity");
  };

  return (
    <>
      <div className="w-full bg-black  mt:py-[100px] py-[10px] px-3 md:px-0 ">
        <div className="container mx-auto pb-8">
          <div className="flex justify-between flex-wrap ">
            {/* About Us Section Start */}
            <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%] ">
              <h4 className="text-white py-2 border_orange font-bold">
                {" "}
                About Us
              </h4>
              <p className="text-[#b2c0d8]">
                AAOPADHE: Your gateway to top colleges! We specialize in guiding
                students to the best institutions for their chosen streams.
                Empower your educational journey with Aaopadhe's expert advice.
              </p>
              <div className="flex gap-3 py-2">
                <div
                  className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47] w-[36px] h-[36px]
                 rounded-full hover:bg-red-400 py-1"
                >
                  <FaFacebookF className="text-white" />
                </div>
                <div
                  className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47] w-[36px] h-[36px]
                 rounded-full hover:bg-red-400 py-1"
                >
                  <FaInstagram className="text-white" />
                </div>
                <div
                  className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47] w-[36px] h-[36px]
                 rounded-full hover:bg-red-400 py-1"
                >
                  <FaTwitter className="text-white" />
                </div>
              </div>
              <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[100%] mt-2">
                <h4 className="text-white py-2 border_orange font-bold">
                  Contact Us{" "}
                </h4>
                <div className="flex gap-3 py-2">
                  <div className="flex items-center justify-center bg-red-400 w-[38px] h-[38px] rounded-full">
                    <FaPhoneAlt className="text-white" />
                  </div>
                  <div className="">
                    <p>
                      <a
                        href="#"
                        className="text-[#b2c0d8] hover:text-[#b2c0d8] no-underline"
                      >
                        7976527115
                      </a>
                    </p>
                    {/* <p>
                  <a href="#" className="text-[#b2c0d8] hover:text-[#b2c0d8] no-underline">
                    +1 (444) 000-8888
                  </a>   
                </p> */}
                  </div>
                </div>
                <div className="flex gap-3">
                  <div className="flex items-center justify-center bg-red-400 w-[38px] h-[38px] rounded-full">
                    <FaEnvelope className="text-white" />
                  </div>
                  <div className="mt-4">
                    <p>
                      <a
                        href="#"
                        className="text-[#b2c0d8] no-underline hover:text-[#b2c0d8]"
                      >
                        ofcwrk@hotmail.co.uk
                      </a>
                    </p>
                    {/* <p>
                  <a href="#" className="text-[#b2c0d8] no-underline hover:text-[#b2c0d8]">
                    help@example.com
                  </a>
                </p> */}
                  </div>
                </div>

                <div className="flex gap-3 mt-4">
                  <div className="flex items-center justify-center bg-red-400 w-[38px] h-[38px] rounded-full">
                    <FaLocationArrow className="text-white" />
                  </div>
                  <div>
                    <p className="text-[#b2c0d8]">
                      khirni phatak ,
                      <br />
                      Sodala , Jaipur(Rajasthan)
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* EnD About Us Section Start */}

            <div className="bg-black text-white md:px-0 px-2 ">
              <h4 className="text-white py-2 border_orange font-bold">
                important Link{" "}
              </h4>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Section 1 */}
                <div>
                  <h2 className="text-xl font-semibold mt-2">
                    Best College In
                  </h2>
                  <ul className="mt-4 space-y-2">
                    {best_colleges_in_cities_array?.map((city, index) => (
                      <li
                        className=" capitalize cursor-pointer"
                        key={index}
                        onClick={() => {
                          best_college_handler(city);
                        }}
                      >
                        {" "}
                        Best Colleges in {city}
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Section 2 */}
                <div>
                  <h2 className="text-xl font-semibold"> UG Courses</h2>
                  <ul className="mt-4 space-y-2">
                    {best_colleges_in_courses_array?.map((course, index) => (
                      <li
                        key={index}
                        className=" cursor-pointer"
                        onClick={() => {
                          ugc_course_handler(course);
                        }}
                      >
                        Best Colleges for {course}
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Section 3 */}
                <div>
                  <h2 className="text-xl font-semibold">Best Colleges for</h2>
                  <ul className="mt-4 space-y-2">
                    {best_colleges_in_pg_courses_array?.map((course, index) => (
                      <li
                        key={index}
                        className=" cursor-pointer"
                        onClick={() => ugc_course_handler(course)}
                      >
                        Best Colleges for {course}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Contact Us Start */}

            {/* Contact Us EnD*/}
          </div>

          <div className="w-full md:flex flex-col  justify-center   mt-12">
            <div className=" flex-row md:flex  items-center   text-center md:text-left  justify-between md:mb-0 ">
              {/* Latest Post Start */}
              <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%] px-2">
                <p className="text-blue-400  text-start py-">Download Now</p>
                <div className="flex mb-4 ">
                  <a href="https://play.google.com" className="mr-4">
                    <img src={play} alt="Google Play" className="w-32" />
                  </a>
                  <a href="https://www.apple.com/app-store/">
                    <img src={apple} alt="App Store" className="w-32" />
                  </a>
                </div>

                <h4 className="text-white py-2 border_orange font-bold  text-start mb-2 ">
                  Latest Post
                </h4>

                {website_blog?.map((data, index) => (
                  <Link to={"/blog"} className="flex py-2 gap-2" key={index}>
                    <div>
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL}image/website_blog_image/${data.logo}`}
                        alt="footer_img"
                        className=" w-10 h-10"
                      />
                    </div>
                    <div className="ml-2">
                      <p className="mb-1 no-underline text-white">
                        {data.heading}
                      </p>
                      <span className="text-red-400">
                        {format(
                          new Date(data.createdAt).toString(),
                          "MMMM dd, yyyy a"
                        )}
                      </span>
                    </div>
                  </Link>
                ))}
              </div>

              {/* Latest Post EnD */}
              <div className="w-[100%] md:w-[70%]">
                <p className="text-sm text-gray-500 text-center">
                  As the digital education landscape evolves, it presents both
                  opportunities and challenges for students navigating their
                  academic paths. With over 10 million students expected to
                  embrace online education in India by the end of 2024, the
                  demand for reliable information has never been more critical.
                  Many learners are eager to explore diverse educational options
                  but often find themselves overwhelmed by the sheer volume of
                  choices and varying quality of information available. The Need
                  for Clarity and Transparency In this complex environment,
                  students frequently encounter biased reviews, misleading
                  rankings, and a lack of standardized information about
                  universities and courses. College World aims to address these
                  challenges head-on. By offering comprehensive and transparent
                  guidance, we empower students to understand their options
                  better and make choices that align with their career
                  aspirations and personal interests. Our Approach Curated
                  Information: We meticulously gather data on universities,
                  courses, and programs from trusted sources. Our platform
                  features detailed profiles that include key information such
                  as accreditation, course curriculum, faculty qualifications,
                  student reviews, and placement statistics. This enables
                  students to make side-by-side comparisons, ensuring they have
                  all the information they need at their fingertips. Expert
                  Insights: aaopadhe collaborates with educators, industry
                  professionals, and academic advisors to provide expert
                  insights and advice. Our articles, webinars, and Q&A sessions
                  cover a range of topics, from choosing the right course to
                  navigating scholarships and financial aid. Personalized
                  Guidance: Recognizing that every student’s journey is unique,
                  we offer personalized resources tailored to individual needs
                  and goals. Through quizzes, assessments, and one-on-one
                  counseling, we help students identify their strengths and
                  interests, guiding them toward the most suitable educational
                  paths. Community Support: Education is a journey best taken
                  with support. College World fosters a vibrant community where
                  students can connect, share experiences, and seek advice from
                  peers who are navigating similar paths. Our forums and
                  discussion boards facilitate engagement and collaboration,
                  helping students feel less isolated in their educational
                  journeys.
                </p>
              </div>
            </div>
          </div>

          {/* Footer Section */}

          <div className="text-white hidden md:block">
            <ul className="mt-4 flex flex-col md:flex-row md:flex-wrap md:justify-between space-y-4 md:space-y-0 text-[13px]">
              <li>
                <Link to="/about" className="mt-4">
                  about
                </Link>
              </li>
              <li>
                <Link to="/ourcommitment" className="mt-4">
                  Commitment
                </Link>
              </li>
              <li>
                <Link to="/wediffrent">What makes our different?</Link>
              </li>
              <li>
                <Link to="/inviteuniversity">Invite University</Link>
              </li>
              <li className="flex items-center">
                <Link to="/videoCounselingSection">Counseling Book</Link>
              </li>
              <li className="flex items-center">
                <Link to="/ourexpert">Our Experts</Link>
              </li>
              <li>
                <Link to="/ConnectWithStudentsAlumni">
                  Connect With Students
                </Link>
              </li>
              <li>
                <Link to="/fakeuniversity">Fake University</Link>
              </li>
              <li>
                <Link to="/successStories">Success Story</Link>
              </li>
              <li>
                <Link to="/importantVideos">Ask Any Question</Link>
              </li>
              <li>
                <Link to="/blog">Blogs & Posts</Link>
              </li>
              <li>
                <Link to="/importantVideos">Important Videos</Link>
              </li>
            </ul>
          </div>

          <div className="border-t border-gray-700 mt-8 pt-4 mb-8">
            <div className="container mx-auto text-center text-sm text-gray-500">
              <p>2024 AAO PADHE in, all rights reserved and product of  one 8 solutions</p>
              
              <p>
                At Aao padhe, our objective is to offer students precise,
                unbiased, and in-depth information about universities and their
                academic programs. We aim to simplify the decision-making
                process for students by providing an easy-to-navigate platform
                that focuses on transparency, reliability, and quality guidance.
              </p>
              <p>
                Built with ❤️ in India to serve the global student community.
              </p>
            </div>
          </div>
        </div>
      </div>
      <nav className="bg-white fixed  bottom-0 w-[100%] border-t flex justify-around py-2 shadow-lg md:hidden block ">
        <Link to="/" className="text-center">
          <AiFillHome className="h-6 w-6 mx-auto" />
          <span className="text-sm">Home</span>
        </Link>
        <Link to={"allUniversity"} className="text-center">
          <FaUniversity className="h-6 w-6 mx-auto" />
          <span className="text-sm">Universities</span>
        </Link>
        <div
          className="text-center"
          onClick={() => {
            setsearchbar(true);
          }}
        >
          <AiOutlineSearch className="h-6 w-6 mx-auto" />
          <span className="text-sm">Search</span>
        </div>
        <Link to={"/student_profile"} className="text-center">
          <AiFillProfile className="h-6 w-6 mx-auto" />
          <span className="text-sm">Profile</span>
        </Link>
      </nav>
      {/* whatsapp & call icon screen  start*/}
      <div className="fixed bottom-0 text-[30px] right-0 gap-3 md:px-8 px-2 py-6 z-[9999]  md:mb-[2rem] mb-[4rem]">
        <div className="p-3 bg-gray-300 md:mb-3 rounded-full mb-4">
          <a href="tel:+917976527115">
            <IoCall className="text-blue-500" />
          </a>
        </div>
        <div className="p-3 bg-gray-300 md:mb-2  rounded-full">
          <a href="https://wa.me/+917976527115" taret="_blank">
            <IoLogoWhatsapp className="text-green-500" />
          </a>
        </div>
      </div>
      {/* whatsapp & call icon screen  end*/}
    </>
  );
}

export default Footer2;
