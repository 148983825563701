import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import { Context } from '../../../../Context_holder';

export default function Hiring_partners_add() {

    const{college_fetch,colleges,selected_college,setselected_college,token,notify }=useContext(Context)
    



    useEffect(
        ()=>{
      
          college_fetch()
          setselected_college(null)
      
        
        },[]
      )
     

    const submit_handler = (e)=>{

        e.preventDefault();
        
        
    
     const logo= e.target.logo.files[0]
     const college_id= selected_college.value
     const company_name= e.target.company_name.value
     const website= e.target.website.value
      const formData = new FormData();
    
    
      formData.append('logo', logo);
      formData.append('college_id', college_id);
      formData.append('company_name', company_name);
      formData.append('website', website);
     
    
      axios.post(process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_HIRING_PARTNERS_URL+"add",formData,{
        headers: {
          Authorization: token}
      })
      .then((success) => {
        notify(success.data.msg,success.data.status)
    
        if (success.data.status === 1) {
         
    
          e.target.reset();
    
         
          setselected_college(null)
        
    
        }
      })
    
      .catch((error) => {
        console.error('Error:', error);
      });
    
    
    }

    return (
      <div className='min-h-screen py-10 bg-gray-300'>
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">

      
        <div className="text-center text-4xl font-bold mb-3">Hiring Partners For Particuler College</div>

        <form onSubmit={submit_handler} enctype="multipart/form-data">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
            <div>

              <label htmlFor="college_id" className="block text-sm font-bold text-gray-700">
                College ID
              </label>

              <Select
                value={selected_college}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    padding: '4px 20px',
                    marginTop: '4px',
                  }),
                }}
                onChange={(e) => { setselected_college(e) }}
                name='college_id'
                options={colleges?.college?.map(data => ({ value: data._id, label: data.college_name }))}
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company_name">
                Company Name
              </label>
              <input
                id="company_name"
                name="company_name"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Company Name"
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="website">
                Website
              </label>
              <input
                id="website"
                name="website"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Website Link"
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="logo">
                Logo
              </label>
              <input
                type='file'
                id="logo"
                name="logo"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
    
          <div className="flex justify-end mb-4">
            <button
              type="submit"
              className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    

        )
}
