


import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../Context_holder';
import StarRatings from "react-star-ratings";
import { FaUsers, FaSchool, FaStar, FaStarHalfAlt, FaStream, FaBlog, FaNewspaper, FaRegFileAlt } from 'react-icons/fa';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);


export default function Dashboard() {

 
   


  const {users_fetch , allusers, colleges, college_fetch,review_fetch,review,rating ,rating_fetch,top10College,college_fetch_by_ratings,rounded_rating,topCourses,top10course_fetch,stream_fetch,stream,website_blog_fetch,website_blog,website_news_fetch,website_news,websitestory_fetch,websitestory, monthly_users_fetch,college_enquiry_users_fetch,college_enquiry_users,
    monthly_users,course_enquiry_users,course_enquiry_users_fetch,city_enquiry_users_fetch,city_enquiry_users} = useContext(Context);


  
  useEffect(
    ()=>{

        users_fetch()
        college_fetch()
        review_fetch()
        rating_fetch()
        college_fetch_by_ratings()
        top10course_fetch()
        stream_fetch()
        website_blog_fetch()
        website_news_fetch()
        websitestory_fetch();
        monthly_users_fetch()
        college_enquiry_users_fetch()
        course_enquiry_users_fetch()
        city_enquiry_users_fetch()

    },
    []
  )



 
  
  const chartData = {
    labels: monthly_users?.map((item) => item.month),
    datasets: [
      {
        label: 'Monthly Signups',
        data: monthly_users?.map((item) => item.signups),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', 
          '#FF9F40', '#66FF66', '#CC99FF', '#FF6666', '#00CCFF'
        ], // Colors for each bar
        borderColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', 
          '#FF9F40', '#66FF66', '#CC99FF', '#FF6666', '#00CCFF'
        ], // Matching border colors for bars
        borderWidth: 1,
      },
    ],
  };
  

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Signups: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Signups',
        },
        beginAtZero: true,
        grid: {
          borderColor: '#e5e7eb', // Tailwind CSS gray color
          borderWidth: 1,
        },
      },
    },
  };



  const college_enquiry_chartData = {
    labels: college_enquiry_users?.map((item) => item.collegeName),
    datasets: [
      {
        label: 'Top Colleges Enquiry',
        data: college_enquiry_users?.map((item) => item.userCount),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#66FF66', '#CC99FF', '#FF6666', '#00CCFF'
        ],
        borderColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#66FF66', '#CC99FF', '#FF6666', '#00CCFF'
        ],
        borderWidth: 1,
      },
    ],
  };
  


  const college_enquiry_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Enquiry For Colleges: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Colleges',
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Enquiry For Colleges',
        },
        beginAtZero: true,
        grid: {
          borderColor: '#e5e7eb', // Tailwind CSS gray color
          borderWidth: 1,
        },
      },
    },
  };
  




  
  const course_enquiry_chartData = {
    labels: course_enquiry_users?.map((item) => item.course_name),
    datasets: [
      {
        label: 'Top Courses Enquiry',
        data: course_enquiry_users?.map((item) => item.userCount),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#66FF66', '#CC99FF', '#FF6666', '#00CCFF'
        ],
        borderColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#66FF66', '#CC99FF', '#FF6666', '#00CCFF'
        ],
        borderWidth: 1,
      },
    ],
  };
  


  const course_enquiry_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Enquiry For Courses: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Courses',
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Enquiry For Courses',
        },
        beginAtZero: true,
        grid: {
          borderColor: '#e5e7eb', // Tailwind CSS gray color
          borderWidth: 1,
        },
      },
    },
  };
  

 
  const city_enquiry_chartData = {
    labels: city_enquiry_users?.map((item) => item.city),
    datasets: [
      {
        label: 'Top City Wise Enquiry',
        data: city_enquiry_users?.map((item) => item.userCount),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#66FF66', '#CC99FF', '#FF6666', '#00CCFF'
        ],
        borderColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#66FF66', '#CC99FF', '#FF6666', '#00CCFF'
        ],
        borderWidth: 1,
      },
    ],
  };
  


  const city_enquiry_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Enquiry For College City Wise: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Cities',
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Enquiry For College City Wise',
        },
        beginAtZero: true,
        grid: {
          borderColor: '#e5e7eb', // Tailwind CSS gray color
          borderWidth: 1,
        },
      },
    },
  };
  

  return (
    <div className="md:p-10 p-2 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold text-gray-900 ">Dashboard</h1>

    

      <div className="grid grid-cols-1 py-6 md:grid-cols-2 lg:grid-cols-3 gap-6">
  {/* Students Enquiries */}
  <Link to="/admin/enquiries_details" className="bg-gradient-to-r from-blue-300 to-blue-100 p-6 rounded-lg shadow-lg flex items-center justify-between hover:from-blue-400 hover:to-blue-200 transition-all duration-300">
    <div className="flex items-center">
      <FaUsers className="text-blue-700 text-3xl mr-4 transform hover:scale-110 transition-transform duration-300" />
      <div>
        <h2 className="text-2xl font-semibold text-blue-800">Students Enquiries</h2>
        <p className="text-gray-700 text-lg mt-2">{allusers?.users?.length}</p>
      </div>
    </div>
  </Link>

  {/* Total Colleges */}
  <Link to="/admin/college/view" className="bg-gradient-to-r from-yellow-300 to-yellow-100 p-6 rounded-lg shadow-lg flex items-center justify-between hover:from-yellow-400 hover:to-yellow-200 transition-all duration-300">
    <div className="flex items-center">
      <FaSchool className="text-yellow-700 text-3xl mr-4 transform hover:scale-110 transition-transform duration-300" />
      <div>
        <h2 className="text-2xl font-semibold text-yellow-800">Total Colleges</h2>
        <p className="text-gray-700 text-lg mt-2">{colleges?.total_count}</p>
      </div>
    </div>
  </Link>

  {/* Total Reviews */}
  <Link to="/admin/review/view" className="bg-gradient-to-r from-green-300 to-green-100 p-6 rounded-lg shadow-lg flex items-center justify-between hover:from-green-400 hover:to-green-200 transition-all duration-300">
    <div className="flex items-center">
      <FaStar className="text-green-700 text-3xl mr-4 transform hover:scale-110 transition-transform duration-300" />
      <div>
        <h2 className="text-2xl font-semibold text-green-800">Total Reviews</h2>
        <p className="text-gray-700 text-lg mt-2">{review?.length}</p>
      </div>
    </div>
  </Link>

  {/* Total Ratings */}
  <Link to="/admin/rating/view" className="bg-gradient-to-r from-teal-300 to-teal-100 p-6 rounded-lg shadow-lg flex items-center justify-between hover:from-teal-400 hover:to-teal-200 transition-all duration-300">
    <div className="flex items-center">
      <FaStarHalfAlt className="text-teal-700 text-3xl mr-4 transform hover:scale-110 transition-transform duration-300" />
      <div>
        <h2 className="text-2xl font-semibold text-teal-800">Total Ratings</h2>
        <p className="text-gray-700 text-lg mt-2">{rating?.length}</p>
      </div>
    </div>
  </Link>

  {/* Streams */}
  <Link to="/admin/stream/view" className="bg-gradient-to-r from-purple-300 to-purple-100 p-6 rounded-lg shadow-lg flex items-center justify-between hover:from-purple-400 hover:to-purple-200 transition-all duration-300">
    <div className="flex items-center">
      <FaStream className="text-purple-700 text-3xl mr-4 transform hover:scale-110 transition-transform duration-300" />
      <div>
        <h2 className="text-2xl font-semibold text-purple-800">Streams</h2>
        <p className="text-gray-700 text-lg mt-2">{stream?.length}</p>
      </div>
    </div>
  </Link>

  {/* Website Blogs */}
  <Link to="/admin/website_blog/view" className="bg-gradient-to-r from-pink-300 to-pink-100 p-6 rounded-lg shadow-lg flex items-center justify-between hover:from-pink-400 hover:to-pink-200 transition-all duration-300">
    <div className="flex items-center">
      <FaBlog className="text-pink-700 text-3xl mr-4 transform hover:scale-110 transition-transform duration-300" />
      <div>
        <h2 className="text-2xl font-semibold text-pink-800">Website Blogs</h2>
        <p className="text-gray-700 text-lg mt-2">{website_blog?.length}</p>
      </div>
    </div>
  </Link>

  {/* Website News */}
  <Link to="/admin/website_news/view" className="bg-gradient-to-r from-orange-300 to-orange-100 p-6 rounded-lg shadow-lg flex items-center justify-between hover:from-orange-400 hover:to-orange-200 transition-all duration-300">
    <div className="flex items-center">
      <FaNewspaper className="text-orange-700 text-3xl mr-4 transform hover:scale-110 transition-transform duration-300" />
      <div>
        <h2 className="text-2xl font-semibold text-orange-800">Website News</h2>
        <p className="text-gray-700 text-lg mt-2">{website_news?.length}</p>
      </div>
    </div>
  </Link>

  {/* Website Stories */}
  <Link to="/admin/website_story/view" className="bg-gradient-to-r from-gray-300 to-gray-100 p-6 rounded-lg shadow-lg flex items-center justify-between hover:from-gray-400 hover:to-gray-200 transition-all duration-300">
    <div className="flex items-center">
      <FaRegFileAlt className="text-gray-700 text-3xl mr-4 transform hover:scale-110 transition-transform duration-300" />
      <div>
        <h2 className="text-2xl font-semibold text-gray-800">Website Stories</h2>
        <p className="text-gray-700 text-lg mt-2">{websitestory?.length}</p>
      </div>
    </div>
  </Link>
</div>




{/* monthly students signups */}

<div className="mt-10 bg-gradient-to-r from-blue-500 to-purple-500 p-6 rounded-lg shadow-lg">
  <h2 className="text-2xl font-bold text-white mb-4">Monthly Students Join</h2>
  <div className="h-64 bg-gray-100 rounded-lg flex items-center justify-center">
    <Bar data={chartData} options={options} />
  </div>
</div>



<div className="mt-10 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 p-6 rounded-lg shadow-lg">
    <h2 className="text-2xl font-bold text-white mb-4">Top Colleges Enquiry</h2>
    <div className="h-64 bg-gray-100 rounded-lg flex items-center justify-center">
        <Bar data={college_enquiry_chartData} options={college_enquiry_options} />
    </div>
</div>

<div className="mt-10 bg-gradient-to-r from-green-400 via-blue-500 to-purple-500 p-6 rounded-lg shadow-lg">
    <h2 className="text-2xl font-bold text-white mb-4">Top Courses Enquiry</h2>
    <div className="h-64 bg-gray-100 rounded-lg flex items-center justify-center">
        <Bar data={course_enquiry_chartData} options={course_enquiry_options} />
    </div>
</div>

<div className="mt-10 bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 p-6 rounded-lg shadow-lg">
    <h2 className="text-2xl font-bold text-white mb-4">Top City Wise Enquiry</h2>
    <div className="h-64 bg-gray-100 rounded-lg flex items-center justify-center">
        <Bar data={city_enquiry_chartData} options={city_enquiry_options} />
    </div>
</div>




{/* top collegs */}
      <div className="overflow-x-auto py-6 w-full ">
  <div className=" min-w-full overflow-hidden bg-gradient-to-r from-purple-500 to-indigo-600 py-4 px-6 text-xl lg:text-2xl font-bold text-white rounded-t-lg shadow-md">
    Top Colleges
  </div>
  <table className="min-w-full table-auto bg-white shadow-lg rounded-lg ">
    <thead>
      <tr className="bg-indigo-100 text-gray-700 text-left text-xs lg:text-sm uppercase tracking-wider">
      <th className="py-2 lg:py-3 px-2 lg:px-6">No.</th>
        <th className="py-2 lg:py-3 px-2 lg:px-6">College Name</th>
        <th className="py-2 lg:py-3 px-2 lg:px-6">State</th>
        <th className="py-2 lg:py-3 px-2 lg:px-6">City</th>
        <th className="py-2 lg:py-3 px-2 lg:px-6">Rating</th>
      </tr>
    </thead>
    <tbody className=''>
      {top10College?.map((college, index) => (
        <tr 
          key={index} 
          className="border-b last:border-b-0 transition duration-300 ease-in-out hover:bg-indigo-50 hover:shadow-md w-full"
        >
          <td className="py-4 px-2 lg:px-6 text-gray-900 font-medium break-words">#{index+1}</td>
          <td className="py-4 px-2 lg:px-6 text-gray-900 md:font-medium text-sm break-words">{college?.college_name}</td>
          
          <td className="py-4 px-2 lg:px-6 text-gray-700 break-words">{college?.state}</td>
          <td className="py-4 px-2 lg:px-6 text-gray-700 break-words">{college?.city}</td>


<td className="py-4 px-4 lg:px-6 flex items-center text-yellow-500 font-semibold  w-full">
            <div className="  flex-col items-center bg-gray-800 text-white gap-4 md:gap-2 w-[10rem] px-3 py-1 rounded-full ">
              <span className="text-sm lg:text-lg mr-2 ">{rounded_rating(college?.averageRating) ?? 0}</span>
              <StarRatings
             
                rating={rounded_rating(college?.averageRating) ?? 0}
                starRatedColor="gold"
                numberOfStars={5}
                starDimension="20px"
                starSpacing="2px"
                readonly
              />
            </div>
          </td>

        </tr>
      ))}
    </tbody>
  </table>
</div>


{/* top courses */}
{/* style={{
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none' // Hides scrollbar in Firefox
  }} */}

<div className="overflow-x-auto py-6 w-full">
  <div className="bg-gradient-to-r from-blue-500 to-teal-500 py-4 px-6 text-xl lg:text-2xl font-bold text-white rounded-t-lg shadow-lg w-full">
    Top Courses
  </div>
  <table className="min-w-full bg-white shadow-lg rounded-lg overflow-hidden">
    <thead>
      <tr className="bg-gray-100 text-gray-700 text-left text-xs lg:text-sm uppercase tracking-wider">
      <th className="py-3 px-4 lg:px-6">No.</th>
        <th className="py-3 px-4 lg:px-6">Course Name</th>
        <th className="py-3 px-4 lg:px-6">College Name</th>
        <th className="py-3 px-4 lg:px-6">Rating</th>
      </tr>
    </thead>
    <tbody>
      {topCourses?.map((course, index) => (
        <tr 
          key={index} 
          className="border-b last:border-b-0 transition duration-300 ease-in-out hover:bg-blue-50 hover:shadow-md"
        >
          <td className="py-4 px-4 lg:px-6 text-gray-800">#{index+1}</td>
          <td className="py-4 px-4 lg:px-6 text-gray-900 font-medium">{course?.courseName}</td>
          <td className="py-4 px-4 lg:px-6 text-gray-800">{course?.collegeName}</td>
          <td className="py-4 px-4 lg:px-6 flex items-center text-yellow-500 font-semibold">
            <div className="  flex-col w-[10rem] items-center bg-gray-800 text-white gap-2 px-3 py-1 rounded-full">
              <span className="text-md lg:text-lg mr-2">{rounded_rating(course?.averageRating) ?? 0}</span>
              <StarRatings
                rating={rounded_rating(course?.averageRating) ?? 0}
                starRatedColor="gold"
                numberOfStars={5}
                starDimension="20px"
                starSpacing="2px"
                readonly
              />
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>



     
     


    </div>
  );
}
